@import "./../../components/colorPallete.scss";

#no-access {
  font-family: "Raleway", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 50px;

  & #div-bigger {
    display: flex;
    flex-direction: column;
    align-items: center;
    & img {
      width: 150px;
    }
    & .div-smaller {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 400px;
      & p {
        text-align: center;
      }
      & .title {
        color: $darkBlue;
        font-size: 17px;
        margin-bottom: 8px;
      }
      & .description {
        font-size: 13px;
        color: $darkGrey;
        margin-top: 8px;
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  #no-access {
    & #div-bigger {
      & img {
        width: 260px;
      }
      & .div-smaller {
        max-width: 450px;
        & .title {
          font-size: 25px;
        }
        & .description {
          font-size: 17px;
        }
      }
    }
  }
}
