@import '../colorPallete.scss';

.mapa-do-brasil {
	width: 430px;
	margin: auto;
}

#tooltip {
	position: absolute;
	background: white;
	color: #707070;
	line-height: 32px;
	z-index: 1000;
	border: 1px solid $darkGrey3;
	padding: 8px;
  }

.mapa-do-brasil-mapa {
	margin-right: 4rem;
	z-index: 1;
	margin-top: 4%;
}

.mapa-do-brasil-title {
	color: $darkBlue;
	font-size: 17px;
	font-weight: 500;
	text-align: center;
	margin-bottom: 20px;
}

.mapa-do-brasil-legenda {
	color: $darkGrey;
	font-family: 'Raleway', sans-serif;
	display: flex;
	flex-direction: column;
	margin: auto;
	margin-top: -6.5rem;
	margin-bottom: 1.5rem;
	margin-left: 2rem;
	& .mapa-do-brasil-legenda-title {
		font-weight: 500;
	}
	& .mapa-do-brasil-legenda-bloco {
		display: inline-flex;
		align-items: center;
		margin-top: 10px;
		& .legenda-shape {
			margin-right: 10px;
			border: 1px solid $darkGrey3;
			border-radius: 5px;
			width: 22px;
			height: 10px;
			position: relative;
		}
	}
}

.mapa-do-brasil-switch {
	display: flex;
	flex-direction: row;
	color: $darkGrey;
	align-items: center;
	& span.MuiSwitch-root {
		//width: 49px!important;
		//height: 22px!important;
	}
	margin-right: 4.2rem;
	margin-top: -20px;
	margin-bottom: 10px;
}