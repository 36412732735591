@import "../../components/colorPallete.scss";

.cardVisita {
    width: 20%;
    height: 127px;
    background: $lighterGrey 0% 0% no-repeat content-box;
    opacity: 1;
    border-color: white;
    cursor: pointer;
}

.headerVisita {
    width: 306px;
    height: 55px;
    background: #b4b8c041 0% 0%;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}

.MuiGrid-root.cardVisita.MuiGrid-item {
    margin-top: 25px;
    padding-bottom: unset;
}

.subHeaderVisita {
    width: 306px;
    height: 72px;
}

.titleVisita {
    text-align: left;
    font: Bold 21px/25px Raleway;
    color: $darkBlue;
    opacity: 1;
}

.dataVisita {
    text-align: right;
    font: Bold 17px/21px Raleway;
    color: $darkGrey;
}

.subVisita {
    border: 1px solid #ffffff;
    opacity: 1;
    align-self: center;
    padding-top: 10px;
    padding-bottom: 15px;

    &.left {
        border-left: unset;
    }

    &.right {
        border-right: unset;
    }
}

.legendaVisita {
    text-align: center;
    font-size: 13px;
    font-weight: medium;
    color: $darkGrey;
    opacity: 1;
}

.button-visita {
    width: 100% !important;
    font: Bold 16px/21px Raleway;
    color: $darkGrey;
    opacity: 1;
    text-align: center;
    border: 0
}

.trash-visita {
    padding-top: 35px;
}