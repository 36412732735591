@import './../colorPallete.scss';

.img-item {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: 4px;
    position: relative;
    left: 10px;
}

.list-img-items {
    display: flex;
    border: 1px solid $lighterGrey2;
    border-bottom: 0px;
    height: inherit;

    & label {
        color: $darkGrey;
    }
}
  