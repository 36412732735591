@import './../../components/colorPallete.scss';


#kpi {
    margin-bottom: 60px;
    font-family: "Raleway", sans-serif;

    & .imagem {
        width: 28px;
        height: 28px;
    }
    & table tr {
        height: 70px;
        line-height: normal;
    }
    & table tr:hover .comentar {
        opacity: 1;
    }
    & .painel {
        display: flex;
    }
    & .tabs {
        margin: 16px 0px;
    }
    & .grid-right-button {
        & .inputSelectDiv {
            width: 100%;
            margin-right: 12px;
        }
    }
    .title {
        font-size: 20px;
        color: #707070;
        margin-top: 40px;
    }
}

th, td {
    font-family: "Raleway", sans-serif!important;
}
#tableBody {
    & thead {
        & tr:nth-child(1) {
            height: 20px;

            & th {
                color: #077CDC!important;
                border: none;
            }
        }
        & .arrow {
            cursor: pointer;
        }
        & tr:nth-child(2) {
            & th {
                color: $darkBlue!important;
                border: none;
            }
        }
    }
    & tbody tr {
        background-color: #FFF;
        &:hover {
            background-color: #F2F1F6;
            cursor: pointer;
        }
        & td:nth-child(1) {
            padding-left: 10px; 
        }
        & .cell-definition {
            font-weight: 700;
        }
    }
    & .font-weight {
        font-weight: 700;
    }
    & .white-background{
        background-color: white;
        color: #A7A2A2!important;
        border: 1px solid white!important;
    }
    & .thin-border td{
        border-bottom: 1px solid #DFDEDE;
    }
    & .gross-border {
        border-bottom: 10px solid white;
    }
    & .font-darkgray {
        font-weight: 600;
    }   
    & .popover-icon {
        display: contents;
        & button {
            margin-left: 8px;
        }
    }
}

#kpi td, #kpi th {padding: 5px; font-size: 13px}

#kpi th {
    p {
        text-align: left;
        color: #707070;

        span {
            color: #077CDC;
            font-weight: bold;
            display: block;
            width: 100%;
        }
    }
}

.good .cell-definition { border-left: 4px solid #52A152;}
.bad .cell-definition { border-left: 4px solid #E67171;}
.constant .cell-definition { border-left: 4px solid #D1BB2D;}
.default .cell-definition { border-left: 4px solid #A7A2A2;}

tr.good.selected td { background-color: #DFFFDF; }
tr.bad.selected td { background-color: #F9D7D7; }
tr.constant.selected td { background-color: #F5EFCB; }
.back-lightGray, tr.off td { background-color: #FBFBFB; }

.modal-kpi {
    font-size: 13px;
    & label, & p {
        font-size: 13px;
    }
    & .flex-infos {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        
        p {
            width: 50%;
            margin: 0;
            &:last-child {
                text-align: right;
            }
        }
        .sub-category-value {
            width: 50%;
            text-align: right;
            .formField {
                float: right;
            }
        }
    }
    
    & .category {
        color: $darkBlue;
        border-bottom: 1px solid $darkBlue;
    }
    & .sub-category {
        margin: 0 0 8px 0;
    }
    & .category-value {
        font-weight: 700;
    }
    & .info-basic {
        & .formField {
            width: 180px;
        }
    }
    & #radio-buttons {
        padding-top: 12px;
        margin-bottom: 32px;

        & img {
            width: 26px;
        }
        & .radio-label {
            color: $darkBlue;
        }
        & .formField { 
            margin: 0px;
    
            & .radio div {
                display: flex;
                justify-content: space-between;
                width: 110px;
            }
            & .input .radio span {
                color: $darkGrey!important;
            }
        }
    }
    & .formField textarea{
        height: 200px;
    }
}

.indicator-kpi {
    padding-left: 54px !important;
}

.spacing-field {
    margin-top: 1.2vh;
    padding: 0;
}