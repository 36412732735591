@import '../../components/colorPallete.scss';

.turma-termo-encerramento {
	margin-top: -120px;
	visibility: visible;
	font-family: 'Raleway', sans-serif;
	& h3 {
		color: $darkGrey;
		font-size: 16px;
		text-align: justify;
		font-weight: 400;
		font-style: italic;
		border: unset;

		.cliente-nome {
			font-weight: 700;
		}
	}

	& .cliente-nome-title {
		display: flex;
		font-size: 15px;
		font-weight: 600;
		color: $darkGrey;
	}

	& h1 {
		color: $darkBlue;
		font-weight: 400;
		font-size: 25px;
		margin-bottom: 40px;
		margin-top: 40px;
	}
	
}

.modal-pai .modal-filho {
	& .assinatura-canvas {
		border: 1px solid $darkGrey;
		border-radius: 6px;
	}

	& .assinatura-canvas-hidden {
		visibility: hidden;
	}


	& .grid-termo-encerramento {
		display: flex;
		flex-direction: row-reverse;
		align-items: flex-end;
		& button {
			width: unset;
			height: unset;
			position: absolute;
			margin-right: -25px;
		}
	}

	& .label-empresario {
		font-weight: bold;
		font-size: 14px;
		color: $darkGrey;
	}

}
