@import '../colorPallete.scss';

.basic-button {
    min-height: 30px;
    font-family: "Raleway", sans-serif;
    padding: 3px 10px;
    cursor: pointer;
    font-size: 14px;
    outline: none;

    &.rounded {
        border-radius: 8px;
    }

    &.rounded-left {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    &.rounded-right {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.btn-group button {
    float: left;
    margin: 0 !important;
}

.btn-group:after {
    content: "";
    clear: both;
    display: table;
}

.tagButton {
    height: 20px;
    font-family: "Raleway", sans-serif;
    cursor: pointer;

    & img {
        display: none;
    }
}

.icon-button {
    width: 30px;
    height: 30px;
    padding: 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
        max-width: 20px;
        max-height: 20px;
    }

    &.rounded {
        border-radius: 8px;
    }

    &.rounded-left {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    &.rounded-right {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.icon-and-button {
    font-family: "Raleway", sans-serif;
    width: 90%;
    height: 30px;
    padding: 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
        max-width: 20px;
        max-height: 20px;
    }
}


.busca-avancada {
    width: 153px;

    & img {
        margin-left: 5px;
        width: 18px;
        height: 12px;
    }
}

button.darkBlue.icon-button img {
    filter: brightness(2.0);
}

button[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
}

.icon-button:hover, .icon-button:active, .icon-button:focus {
    padding: 0px;
}

@mixin button-fill($color, $border, $font) {
    background-color: $color;
    border: 1px solid $border;
    color: $font;
}

.white {
    background-color: white;
}

@mixin button-image($img) {
    display: flex;
    position: relative;
    content: $img;
    width: 20px;
    height: 20px;
    margin: auto;
}

//Botão prioridade 1
@each $tuple in $buttonPriotity1 {
    .#{nth($tuple, 1)} {
        @include button-fill(nth($tuple, 2), nth($tuple, 2), white);

        &:hover {
            @include button-fill(nth($tuple, 2), nth($tuple, 3), white);
        }

        &:active, &:focus {
            @include button-fill(nth($tuple, 3), nth($tuple, 3), white);
        }
    }
}

//Botão prioridade 2
@each $tuple in $buttonPriotity2 {
    .#{nth($tuple, 1)}-outline {

        @include button-fill(white, nth($tuple, 2), nth($tuple, 2));

        &:hover {
            @include button-fill(rgba(nth($tuple, 2), 0.1), nth($tuple, 2), nth($tuple, 2));
        }


        &:active, &:focus {
            @include button-fill(nth($tuple, 2), nth($tuple, 2), white);
        }
    }
}

// Tags coloridas
@each $tuple in $tagList {
    .#{nth($tuple, 1)} {

        @include button-fill(nth($tuple, 2), nth($tuple, 2), white);

        &:hover {
            @include button-fill(nth($tuple, 2), nth($tuple, 3), white);
        }

        &:active, &:focus {
            @include button-fill(nth($tuple, 3), nth($tuple, 3), white);
        }
    }
}

.darkBlue-outline:active, .darkBlue-outline:focus {
    color: $darkBlue;
    background-color: transparent;
}

.darkGrey-outline:active, .darkGrey-outline:focus {
    color: $darkGrey;
    background-color: transparent;
}

.darkBlue:active, .darkBlue:focus {
    background-color: $darkBlue;
}

.darkGrey:active, .darkGrey:focus {
    background-color: $darkBlue;
}

.darkBlue:hover {
    background-color: $darkBlue2;
}

.darkGreen:active, .darkGreen:focus {
    background-color: $darkGreen;
}

.darkGreen:hover {
    background-color: $darkerGreen;
}

.lightGrey:active, .lightGrey:focus {
    background-color: $lightGrey;
}

.lightGrey:hover {
    background-color: $darkGrey;
}


.yellow:active, .yellow:focus {
    background-color: $lightYellow;
}

.yellow:hover {
    background-color: $yellow;
}

#parent:active .yellow{
    background-color: $lightYellow;
}

#parent:focus .yellow{
    background-color: $lightYellow;
}

#parent:hover .yellow{
    background-color: $yellow;
}
#parent:active .darkBlue-outline {
    color: $darkBlue;
    background-color: transparent;
}

#parent:focus .darkBlue-outline {
    color: $darkBlue;
    background-color: transparent;
}

#parent:active .darkGrey-outline {
    color: $darkGrey;
    background-color: transparent;
}

#parent:focus .darkGrey-outline {
    color: $darkGrey;
    background-color: transparent;
}

#parent:active .darkBlue {
    background-color: $darkBlue;
}

#parent:focus .darkBlue {
    background-color: $darkBlue;
}

#parent:active .darkGrey {
    background-color: $darkBlue;
}

#parent:focus .darkGrey {
    background-color: $darkBlue;
}

#parent:hover .darkBlue {
    background-color: $darkBlue2;
}

#parent:active .darkGreen {
    background-color: $darkGreen;
}

#parent:focus .darkGreen {
    background-color: $darkGreen;
}

#parent:hover .darkGreen {
    background-color: $darkerGreen;
}

#parent:active .lightGrey {
    background-color: $lightGrey;
}

#parent:focus .lightGrey {
    background-color: $lightGrey;
}

#parent:hover .lightGrey {
    background-color: $darkGrey;
}
