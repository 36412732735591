@import '../colorPallete.scss';

.toast {
	width: 100%;
	top: 52px !important;
	//left: 0px !important;
	& .MuiSnackbarContent-root {
		width: 100%;
		border-radius: 0px;
		padding: 10px 30px;
		& .MuiSnackbarContent-message {
		    padding: 0px;
		    width: 100%;
		}
	} 
	& .MuiPaper-elevation6 {
	    box-shadow: none;
	} 

	&.MuiSnackbar-anchorOriginTopLeft {
		left: auto;
	}

}

#icone {
	padding: 3px 0px;
	margin-right: 6px;
}

#message-id {
	font-family: 'Raleway', sans-serif;
	font-size: 17px;
}

.toast.success .MuiSnackbarContent-root {
	background-color: $darkGreen !important;	
}

.toast.error .MuiSnackbarContent-root {
	background-color: $red !important;
}	

.toast.warning .MuiSnackbarContent-root {
	background-color: $orange !important;;
}

.snack-bar-class {
	top: 0 !important;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	position: fixed;
	touch-action: none;
}