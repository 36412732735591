@import './../../components/colorPallete.scss';

.perfil-usuario {

    & .grid-margin {
        padding: 50px;

        & button {
            margin: 0px 170px 0px 0px;
        }
    }

    & .grid-margin-top {
        margin-top: 60px;
    }

    & .tabs {
        width: 96%
    }
}

.list-tick-items {
    display: flex;
    justify-content: space-between;
    border: 1px solid $lighterGrey2;
    border-bottom: 0px;
    height: inherit;

    & label {
        color: $darkGrey;
    }
}

.tick-label {
    font-family: 'Raleway', sans-serif;
    //line-height: 2.5;
    padding: 1rem 2rem;
    color: $lightGrey2 
}

.max-width {
    height: 430px;
}

.list ul {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden !important;
}

.title-with-count {
    display: flow-root;
    color: $darkBlue;
    font-family: 'Raleway', sans-serif;

    & .left {
        float: left;
        font-size: 17px;
    }

    & .right {
        float: right;
        font-size: 15px;
    }
}

.grid-height {
    height: 545px;
    overflow-y: scroll;
    overflow-x: hidden;

    & .MuiGridList-root {
        overflow-y: hidden;
    }
}

#grid-height::-webkit-scrollbar-track {
    background-color: transparent;
}

#grid-height::-webkit-scrollbar {
    background-color: #F3F3F3; 
}

#grid-height::-webkit-scrollbar-thumb {
    background-color: $darkBlue; 
}

.MuiIconButton-colorSecondary:hover {
    background-color: rgba(0, 141, 245, 0.1);
}

header.MuiPaper-root.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorPrimary {
    background-color: white;
    color: $darkGrey;
    box-shadow: none;
    opacity: 1;
    font-family: "Raleway", sans-serif;
}

.content > :nth-child(2) {
    padding: 2rem 2rem!important
}


@media screen and (max-height: 599px) {
    .grid-height {
        height: 300px;
    }

    .perfil-usuario .grid-margin {
        padding: 20px;
    }

    .perfil-usuario .grid-margin-top {
        margin-top: 30px;
    }
}

@media screen and (min-height: 600px) and (max-height: 750px) {
    .grid-height {
        height: 250px;
    }

    .perfil-usuario .grid-margin {
        padding: 15px;
    }

    .perfil-usuario .grid-margin-top {
        margin-top: 30px;
    }
}
