.pesquisa {
    display: flex;
    align-items: flex-start;
}

.margin-item-modal-detalhes {
    margin: 8px 0;
}

.modal-detahe-visita {

    .modal-detahe-visita-header {
        border-bottom: 1px solid #B4B8C0;
        margin-bottom: 24px;
    }

    .space {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.search-visita {
   display: flex;
   align-items: left;

    & .formField {
        max-width: 400px;
    }

    a {
        margin-top: 16px;
        margin-right: 20px;
    }
}