@import '../../components/colorPallete.scss';

.etapa {
	& .MuiGrid-root.MuiGrid-container .inputSelectDiv {
	    width: 100%;
	}

	& .anexos-visitas {
	    border-radius: 6px;
	    opacity: 1;
	    padding: 20px;

	    & .attach {
	    	margin-right: 4px!important;
	    }

	    & .anexos-visitas-box {
	    	border-top: 1px solid $lightGrey;
	    	border-bottom: 1px solid $lightGrey;
	    	background: $lighterGrey;
	    	padding-left: 40px;

	    	& #upload-relatorio {
	    		text-align: center;
	    	}
	    }

		& .anexos-visitas-box-top {
	    	border-top: 1px solid $lightGrey;
	    	background: $lighterGrey;
	    	padding-left: 40px;

	    	& #upload-relatorio {
	    		text-align: center;
	    	}
	    }
		& .anexos-visitas-box-bottom {
	    	border-bottom: 1px solid $lightGrey;
	    	background: $lighterGrey;
	    	padding-left: 40px;

	    	& #upload-relatorio {
	    		text-align: center;
	    	}
	    }
	}

	& .campos-visita {
		& .field-label{

            
            margin-top : 20px;
            & label{
                
                color: $darkBlue !important;
                font-size: 13px !important;
            } 
        }
		& .div-field-btn {
            display: flex;
			margin-bottom: 10px;
            & div {
                width: 100%;
            }
            & button {
                margin-top: 31px;
            }
        }
		& .formField {
			width: 100%;
		}
	}
}

.lightBlue {
	background-color: #00B1E8;
	border: 1px solid #00B1E8;
	color: white !important; }
	.lightBlue:hover {
	  background-color: #00B1E8;
	  border: 1px solid #008AB5;
	  color: white; }
	.lightBlue:active, .lightBlue:focus {
	  background-color: #008AB5;
	  border: 1px solid #008AB5;
	  color: white; }