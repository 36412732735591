.root {
  display: flex;
}

.content {
  padding: 0;
  width: 100%;
  position: relative;
  background-color: white;
}

html {
  font-size: 14px;
  background-color: white;
  body {
    background-color: white;
  }
}

.menu-open {
  & + #main {
    width: calc(100% - 260px);

    .highcharts-container {
      svg {
        width: 100% !important;
      }
    }
  }
}
