@import '../colorPallete.scss';

input::-webkit-calendar-picker-indicator {
  opacity: 0 !important;
  background-color: transparent !important;
  color: transparent !important;
  -webkit-appearance: none !important;
}

.formField {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

    & input {
        height: 30px;
        border: 1px solid $lighterGrey3;
        background-color: $lighterGrey!important;
        padding: 5px;
        font-size: 13px;
        color: $darkGrey;
        font-family: 'Raleway', sans-serif;
        width: 100%;
        text-transform: uppercase;
        &#email, #login {
            text-transform: none;
        }
        &:hover {
            border: 1px solid $darkGrey;
        }
        &:disabled {
            cursor: not-allowed;
            background-color: $lighterGrey2 !important;
        }
    }
    & .input {
       display: flex;
       flex-direction: column;
       width: 100%;
    }
    & .button {
        // padding-left: 24px;
        margin-bottom: 0px;
     }
    &  label {
        font-size: 15px;
        margin-bottom: 10px;
        color: $darkGrey;
    }
    &  span {
        color: $red;
        font-size: 13px;
        margin-top: 5px;

        &.addInfo{
            color: $lightGrey !important;
        }
    }
    span .isInvalid {
        color: $red;    
        
    }
    span .addInfo{
        color: $lightGrey !important;
    }

    input.isInvalid, textarea.isInvalid{
        border: 1px solid $red;
    }
  
  & ::placeholder {
    /* Firefox, Chrome, Opera */
    color: $lightGrey;
    font-style: italic;
  }
  input[type='date']::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    width: 16px;
    height: 15px;
    border-width: thin;
    cursor: pointer;
  }
  input[type='date']::-webkit-calendar-picker-indicator:hover {
    background-color: transparent;
  }
  textarea {
      width: 100%;
      height: 100px;
      overflow: auto;
      resize: none;
      border: 1px solid $lighterGrey3;
      background-color: $lighterGrey!important;
      padding: 5px;
      font-size: 13px;
      color: $darkGrey;
      font-family: 'Raleway', sans-serif;
      &:hover {
          border: 1px solid $darkGrey;
      }
      &.isInvalid:hover {
          border: 1px solid $red;
      }
      &:disabled {
          cursor: not-allowed;
          background-color: $lighterGrey2 !important;
      }
      &.double-height{
          height: 200px;
      }
  }
  

  & .radio {
    &.disabled {
      pointer-events: none;
      & .checkmark:after {
        background: $lightGrey !important;
      }
    }
  }

    .formField-onlyLabel-container  {
        background-color: $lighterGrey;
        width: 323px;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 22px 20px;
        border-radius: 2px;
        span  {
            color: $darkBlue;
            font-size: 13px;
            font-weight: bold;
            font-family: 'Raleway';
        }
    }

    .formField-onlyLabel-container-date  {
        width: 200px !important;
    }
}

@media screen and (min-width: 600px) {
  .paddingForm {
    padding-right: 24px !important;
  }
}

//Retirando setas do input type number
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

//Chips
.chip-label {
  color: $darkGrey;
  margin-bottom: 10px;
  font-size: 15px;
}
.MuiChip-root,
[class^='MuiChip-root-'] {
  border: 1px solid $lighterGrey3 !important;
  background-color: $lighterGrey !important;
  border-radius: 0px !important;
  height: 30px !important;
  font-size: 13px !important;
  color: $darkGrey !important;
  font-family: 'Raleway', sans-serif !important;
  flex-direction: row-reverse !important;
  margin-right: 12px;
  margin-bottom: 10px;
}
span.material-icons.MuiIcon-root.MuiChip-icon.close,
span.material-icons.close {
  color: $red;
  margin: 0px;
  cursor: pointer;
  font-size: 1.25rem;
  margin-right: 5px;
}
span.material-icons.MuiIcon-root.attach {
  margin: 0px;
  cursor: pointer;
  font-size: 1.25rem;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.MuiChip-label,
[class^='MuiChip-label-'] {
  padding-right: 5px !important;
}

//Grid with input and button on right side
.grid-right-button, .grid-left-button {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    & .basic-button {
        margin-bottom: 0px;
    }
    & .formField {
        flex: auto;
       
    }
}
.grid-right-button .formField {
  margin-right: 10px;
}
.grid-left-button .formField {
  margin-left: 10px;
}

/* The container */
.radio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 12px;
}

/* Hide the browser's default radio button */
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid $lightGrey;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio:hover input ~ .checkmark {
  background-color: $lighterGrey;
}

/* When the radio button is checked, add a blue background */
.radio input:checked ~ .checkmark {
  margin: 0px;
  background-color: white;
  border: 1px solid $lightGrey;
}
.radio input ~ .checkmark {
  margin: 0px;
  background-color: white;
  border: 1px solid $lightGrey;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio .checkmark:after {
  top: 2.3px;
  left: 2.3px;
  width: 13.5px;
  height: 13.5px;
  border-radius: 50%;
  background: $darkBlue;
}

.radio .checkmark:after {
  top: 2.3px;
  left: 2.3px;
  width: 13.5px;
  height: 13.5px;
  border-radius: 50%;
  background: $darkBlue;
}

.radio-label {
  font-size: 15px;
  margin-bottom: 10px;
  color: #707070;
}
input:-internal-autofill-selected {
  background-color: $lighterGrey;
}

.label-table {
  font-size: 11px !important;
}
.input-table {
  margin-top: 15px;
}
.font-blue {
  color: $darkBlue;
}
.font-grey {
  color: $darkGrey;
}
.font-red {
  color: $red;
}
.font-light-blue {
  color: $lightBlue3;
}
input[type='date' i] {
  background-position-x: right !important;
  background-size: 20px !important;
  background-origin: content-box !important;
  background-repeat: no-repeat !important;
}

// Checkbox
span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
  font-size: 13px;
  color: $darkGrey;
  font-family: 'Raleway', sans-serif;
}
.modal .MuiCheckbox-colorSecondary.Mui-checked {
  color: $darkBlue;
}
.modal .MuiIconButton-colorSecondary:hover,
.modal .MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: rgba(50, 0, 0, 0.08);
}
b {
  font-weight: 700;
}

//Tabs
.MuiTabs-flexContainer {
  .MuiTab-root {
    //TODAS as tabs devem estar em letras minúsculas, com a primeira letra de cada.
    text-transform: capitalize;
    width: auto;
    min-width: 80px;
    max-width: 500px;
    padding: 4px 16px;
    font-size: 14px;
    //border-bottom: 1px solid $lightGrey;
  }
  & button {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 400;

    & span {
      display: block;
    }
  }
}
.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer {
  border-bottom: 1px solid $lightGrey;
}

.MuiTabs-root {
  //border-bottom: 1px solid $lightGrey;
  & .MuiTabs-scrollButtons {
    border-bottom: 1px solid $lightGrey;
    &.MuiButtonBase-root {
      border-bottom: 0px;
    }
  }

  & .MuiTabs-scrollable {
    overflow-x: hidden;
    margin-bottom: 0px !important;
  }
}

.MuiTab-textColorInherit.Mui-selected {
  border-top: 1px solid $lightGrey;
  border-right: 1px solid $lightGrey;
  border-left: 1px solid $lightGrey;
  color: $darkBlue;
  opacity: 1;
}

.MuiTab-textColorInherit {
  opacity: 1;
}
span.PrivateTabIndicator-root.PrivateTabIndicator-colorSecondary.MuiTabs-indicator {
  background-color: white;
}
span.MuiTabs-indicator {
  background-color: white;
}
