@import '../../components/colorPallete.scss';

.novo-produto-nacional {
    & #descricao, & #CR, & #codigoDN {
        text-transform: uppercase;
    }

    & .auto-complete-many {
        margin-bottom: 5px;
    }

    & .metasDR-nova-tabela {
        margin-bottom: 20px;
    }
}

.meta-departamento-title {
    color: $darkBlue;
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin: 30px 0;
    &.sem-margin {
        margin-bottom: unset;
    }
    &.com-margin-top {
        margin-top: 40px;
    }
}

#button-add-metas-dr {
    margin-top: 31.5px;
}

.botao {
    display: flex;
    justify-content: right;
}

.tab-metas-dr {
    display: flex;
    & .icon-and-button{
        display: flex;
        align-self: center;
    }
}

.review-drmeta {
    font-weight: bold;
    margin-top: 20px !important;
}

.agrupamento-error {
    color: $red;
    font-family: "Raleway", sans-serif;  
}

