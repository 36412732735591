@import "../../components/colorPallete.scss";

#unidades-mentoria-digital {

    & span, text {
        font-family: "Raleway", sans-serif !important;
    }

    .header-campos {
        text-align: center;
        font-family: "Raleway", sans-serif;
        color: $darkGrey;
        font-size: 15px;

        & .font-bigger {
            font-size: 25px;
            font-weight: 700;
            color: $darkBlue;
        }

        & .card-green {
            min-height: 150px;
            background-color: #7dae59;
            color: white !important;
            cursor:pointer;

            & .font-bigger {
                color: white;
            }
        }

        & .card-orange {
            min-height: 150px;
            background-color: $orange;
            color: white !important;

            & .font-bigger {
                color: white;
            }
        }

        & .font-blue {
            font-size: 15px;
        }

        & .vincular-container {
            height: 100%;

            & .enviar-botao {
                margin-bottom: 0px;
            }

            & button {
                margin-right: 105px;
                width: 175px;
            }
        }

        & .formField {
            margin-right: 20px;
        }
    }

    & .MuiSwitch-root {
        width: 47px;
        height: 18.5px;
        padding: 0px;
        display: 'flex';
    }

    & .MuiSwitch-switchBase {
        padding: 3px;
        color: $darkBlue;
    }

    & .MuiSwitch-switchBase.Mui-checked {
        padding: 3px;
        color: $darkBlue;
        transform: translateX(29px);
    }

    & .MuiSwitch-switchBase.Mui-disabled {
        color: $lightGrey3 !important;
    }

    & .MuiSwitch-track {
        border-radius: 13px;
        background-color: white;
        border: 1px solid $lightGrey2;
    }

    & .MuiSwitch-thumb {
        width: 12px;
        height: 12px;
    }

    .switch-container {
        padding-bottom: 20px;
        padding-right: 40px;
    }

    .top-container {
        padding-bottom: 40px;
        padding-top: 10px;
    }

    & .collapse-container {
        & #bh-header {
            background-color: $darkBlue;
            height: unset !important;
            max-height: unset !important;
            line-height: 1.5 !important;

            & .MuiIconButton-root {
                color: white;
            }
        }

        & .MuiCollapse-wrapperInner {
            border-right: 1px solid $lighterGrey3;
            border-left: 1px solid $lighterGrey3;
            border-bottom: 1px solid $lighterGrey3;
        }

        & .MuiExpansionPanelSummary-root.boxSun {
            border-bottom: 0px;
        }

        & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
            font-size: 13px !important;
        }

        & .MuiFormControlLabel-root {
            margin-left: 0px;
            margin-bottom: 0px;
        }

        & .MuiExpansionPanelDetails-root {
            padding: 0px !important;
        }

        & p {
            color: white;
            overflow: visible;
            white-space: normal;
            text-overflow: ellipsis;
            line-height: 1.5 !important;
        }

        & .boxSun:hover {
            background-color: $darkBlue !important;
        }

        & .div-cliente {
            padding: 10px 5px;
            border-bottom: 1px solid $lighterGrey;

            & .MuiCheckbox-colorSecondary.Mui-checked {
                color: $darkBlue;
            }
        }
    }
}


.busca-questionario-title {
    color: var(--unnamed-color-077cdc);
    text-align: left;
    font: normal normal normal 15px/18px Raleway;
    letter-spacing: 0px;
    color: #077CDC;
    opacity: 1;
}

.busca-questionario-options {
    color: var(--unnamed-color-077cdc);
    text-align: left;
    font: normal normal normal 15px/18px Raleway;
    letter-spacing: 0px;
    word-spacing: 10px;
    color: #077CDC;
    opacity: 1;
}

.bold{
    font-weight: 600;
}

.modal-pai {
    & .modal-filho {
        & .modal-mover {
            & .auto-complete-many {
                margin-top: 40px;
                margin-bottom: 30px;
            }
        }

        & .modal-avancada {
            & .auto-complete-many {
                margin-top: 20px;
                margin-bottom: 30px;
            }

            & .tabela {
                margin-bottom: 30px;
            }
        }
    }
}


.MuiGrid-root.botao-relatorio {
    margin-top: 30px;
}


@media (max-width:1500px) {
    #unidades-brasil-mais {
        .header-campos {
            & .formField {
                margin-right: 3px;
            }
        }
    }
}
