@import "../../components/colorPallete.scss";

#dashboard {
  .dashboard-cards {
    margin-bottom: 40px;
    text-align: center;
    font-family: "Raleway", sans-serif;  
    color: $darkGrey;
    font-size: 15px;

    & .font-bigger {
      font-size: 25px;
      font-weight: 700;
      color: $darkBlue;
    }
    & .card-green {
      min-height: 150px;
      background-color: #7dae59;
      color: white!important;
      & .font-bigger {
        color: white;
      }
    }
    & .card-blue {
      min-height: 150px;
      background-color: #077cdc;
      color: white!important;
      & .font-bigger {
        color: white;
      }
    }
    & .font-blue {
      font-size: 19px;
    } 
    & .card {
      min-height: 150px;
      background-color: #f2f1f6;
    }
  }
  & .dashboard-grafico {
    font-family: "Raleway", sans-serif;
    display: flex;
    justify-content: center;

    & .header-buttons{
      align-items: center;
      display: flex;
      & button {
        margin-right: 16px;
      }
    }
    & .header-info {
      padding: 32px 0px;
      text-align: right;
      color: $darkGrey;
    }    
  }
  tspan, text{
    font-family: "Raleway", sans-serif!important;
  }
}
@media screen and (min-width: 1500px) {
}
.modal.modal-atendimento-filtro {
  & .MuiFormControl-root {
    width: 100%!important;
  }
  & .MuiRadio-root{
    color: $darkGrey;
  }
  & .MuiRadio-colorPrimary.Mui-checked {
    color: $darkBlue;
  }
  & .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 div.MuiChip-root span {
    overflow: hidden;
  }
  & .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 div.MuiChip-root {
    max-width: 40.5em;
  }

  & .auto-complete-many {
    & span {
      margin-top: unset;
    }
  }

  & .MuiSelect-select.MuiSelect-selectMenu.MuiInput-input {
    padding: 2px;
    padding-right: 11px;
    min-width: 44px;
    text-align: center;
  }
}
.selects {
    // max-width: 500px;
    display: flex;
    justify-content: space-between;
    & .MuiFormControl-root:nth-child(1), & .MuiFormControl-root:nth-child(4) {
      margin-right: 4px;
    }
}

.point {
  width: 20px;
  background-color: white;
}

text.highcharts-title {
  display: none!important;
}