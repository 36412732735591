@import '../colorPallete.scss';

.tabela {

    th {
        color:$darkBlue!important;
        font-size: 13px!important;
        border-bottom: 1px solid $lightBlue;
        & span {
            color:$darkBlue!important;
            & svg {
                color:$darkBlue!important;
            }
        }
    }
    td {
        color: $darkGrey!important;
        font-size: 13px!important;
    }
    tbody tr:hover {
        background-color: $lighterGrey;
        cursor: pointer;
    }
    & label {
        color: $darkGrey;
        font-size: 11px;
    }
    & .progress-bar label {
        font-size: 12px;
    }
    & .formField, .formField label, .formField span{
        margin: 0px;
    }
    & .imageCell {
        max-width: 50px;
        height: auto;
        text-align: center;
        margin: 0 10px 0 0;
        border-radius: 25px;
        padding: 5px;
        border: 1px solid $lightGrey;        
    }
    & th, & td{
        padding: 10px;
        vertical-align: middle;
    }
    .div-circle-login {
        width: 60px;
        height: 60px;
        box-sizing: content-box;
        padding: 5px;
        & img {
        padding: 0px;
            width: 55px;
            height: 55px;           
            background-size: 55px;
        }
    }
}

span.MuiButtonBase-root.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: $darkBlue;

}
svg.MuiSvgIcon-root.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionAsc, 
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    fill: $darkBlue
}

tbody > tr:hover {
    background-color: $hoverGray;
}

@media screen and (max-width: 599px) {
    .apropriar th:nth-child(1),.apropriar th:nth-child(2), .apropriar th:nth-child(5) {
        display: none;
    }
    .apropriar td:nth-child(1),.apropriar td:nth-child(2), .apropriar td:nth-child(5) {
        display: none;
    }
}

th.MuiTableCell-root.MuiTableCell-head .MuiTableSortLabel-root:hover {
    color: $darkBlue;
    & .MuiTableSortLabel-icon {
        color: $darkBlue;
    }
}
td.MuiTableCell-root.MuiTableCell-footer.MuiTablePagination-root {
    border: none;
}

.MuiCheckbox-root.MuiCheckbox-colorSecondary.Mui-checked {
    color: $darkBlue;
}

.MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
    overflow-x: unset;
}