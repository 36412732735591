@import '../colorPallete.scss';

.modal-pai {
    background: white;
    border: 1px solid $darkBlue;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    max-height: calc(100vh - 100px);
    overflow: auto;
    font-family: "Raleway", sans-serif!important;  

    & span.material-icons.MuiIcon-root {
        color: $lightGrey;
        float: right;
        cursor: pointer;
        margin: 4px;
        & .MuiChip-icon.close {
            color: $red;
        }
    }
    & span.material-icons.MuiIcon-root.MuiChip-icon.close {
        color: $red;
    }
    & .modal-filho {
        padding: 24px 40px !important;
        & .modal {
            font-family: "Raleway", sans-serif;  
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $darkGrey;
            font-size: 15px;
            & h2 {
                font-size: 25px;
                margin: 0px 0  16px 0;
                font-weight: 500;
                color: $darkBlue;
                font-family: "Raleway", sans-serif;
            }
            & .space {
                display: flex;
                margin: 16px 0;
                & .formField {
                    margin: 0px;
                }
            }
        }
    }
    & .success {
        width: 21px;
        height: 64px;
        background-color: $darkGreen;
        position: absolute;
    }
    & .warning {
        width: 21px;
        height: 64px;
        background-color: $red;
        position: absolute;
    }

    & .info-blue {
        width: 21px;
        height: 64px;
        background-color: $darkBlue;
        position: absolute;
    }

    & .formField .input label, & .autoCompleteField label, & .inputSelectDiv label {
        color: $darkBlue;
    }
    & .rodape {
        width: 100%;
        display: flex;
        margin: 16px 0 0 0;
        justify-content: center;
        & button {
            margin: 0 8px;
        }
    }
}

.modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}
@media screen and (max-width: 599px) {
    .modal-pai {
        width: 100%!important;
    }
}