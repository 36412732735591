@import '../colorPallete.scss';
.auto-complete-many {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    // height: 50px;
    width: 100%;
    .MuiInputBase-input.Mui-disabled {
        cursor: not-allowed;
        background-color: $lighterGrey2 !important;
    }

    & .auto-complete-field {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        & div {
            width: 100%;
            position: relative;
            &:before, &:after {
                border: none!important;
            }
            .MuiChip-root {
                width: auto;
            }
        }
    }

    & input {
        height: 18px;
        border: 1px solid #ABB4BE;
        background-color: $lighterGrey;
        padding: 5px;
        padding-right: 30px;
        font-size: 13px;
        color: $darkGrey;
        font-family: "Raleway", sans-serif;  
        text-transform: uppercase;        
    }

    & input:disabled {
        cursor: not-allowed;
        background-color: $lighterGrey2 !important;
    }

    & input:before {
        content: ''!important
    }

    &  input:hover {
        border: 1px solid $darkGrey;
    }
    &  label {
        font-size: 15px;
        margin-bottom: 10px;
        color: $darkGrey;
    }
    &  span {
        font-size: 13px;
        margin-top: 5px;
    }
    .isInvalid {
        border: 1px solid $red;    
    }
    & ::placeholder { /* Firefox, Chrome, Opera */ 
        color: $lightGrey; 
    } 
    & button {
        height: 30px;
        width: 30px;
    }  
    & .erro {
        color: $red;
    }
    & .warning {
        color: $yellow;
    }
}

.MuiInput-underline::before, .MuiInput-underline:hover::before{
    content: none;
}

.auto-complete-options {
    & .square {
        background-color: $lightGrey;
        color: white;
        padding: 3px;
        margin-right: 8px
    }
    & p {
        font-size: 10px;
        color: $darkGrey;
        margin: 0px;
    }
    & label {
        color: $darkGrey2;
        font-size: 13px;
    }
}
.chip-label {
    margin: 0 0 10px 0;
}

.auto-complete-many .MuiChip-root {
    max-width: 100%; 
    display: flex;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
}