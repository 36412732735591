@import "../../components/colorPallete.scss";

$box-width : 275px;
$box-height : 270px;

.box {

    font-family: 'Raleway', sans-serif; 

    &.MuiGrid-container {
        padding-bottom: 10px;
        width: 100%;
        height: $box-height;
        text-align: center;
        font-size: 15px;
        color: $darkGrey;
        background: #ffffff 0% 0% no-repeat padding-box !important;
        box-shadow: 0px 3px 6px #00000029 !important;
        opacity: 1;
    }
  
    .container-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    & .text1 {
        font-weight: 700;
    }
    & .text2 {
        color: $darkBlue;
        font-size: 19px;
    }
    & .text3 {
        font-size: 13px;
    }
    & hr {
        border: 1px solid $lighterGrey3;
        opacity: 1;
        &.barra {
            border: 5px solid $darkBlue;
            margin: 0;
        }
    }

    & img.imagem {
        width: 32px;
        height: 32px;
    }

}

#grid-img img.strategic {
    position: absolute;
    top: calc(50% - 210px)!important;
}

#grid-img img.rampup0, #grid-img img.rampup1, #grid-img img.rampup2 {
    position: absolute;
    top: calc(50% - 230px)!important;
}

#grid-img img.rampup3 {
    position: absolute;
    top: calc(100% - 730px) !important;
}

#grid-img img.stable0, #grid-img img.stable1, #grid-img img.stable3 {
    position: absolute;
    top: calc(50% - 800px)!important;
}

#grid-img img.stable2 {
    position: absolute;
    top: calc(50% - 793px)!important;
}

#grid-img img.stable1 {
    height: 1170px;
}

#grid-img img.stable3 {
    top: calc(50% - 210px) !important;
    height: 1200px
}

hr.barra {
    width: inherit;
    border: 5px solid $darkBlue;
    margin: 0;
    margin-bottom: 5px;
}

h1.box-title {
    color: $darkGrey;
    font-size: 25px;
    font-weight: normal;
    margin: 0;
    font-family: 'Raleway', sans-serif; 
    white-space: nowrap;
}

.box-header {
    font-family: 'Raleway', sans-serif; 
    background-color: $darkBlue;
    color: white;
    font-size: 19px;
    font-weight: 400;
    height: 56px;
    text-align: left;
    align-items: center;
    padding-left: 15px;
    .box-sub-header {
        font-family: 'Raleway', sans-serif; 
        font-size: 17px;
        font-weight: normal;
    }
}

@media (max-width: 1500px) {
    h1.box-title {
        font-size: 22px;
    } 

    .box {
        &.MuiGrid-container {
            font-size: 12px;
        }

        & .text1 {
            font-weight: 700;
            font-size: 14px;
        }
        & .text2 {
            color: $darkBlue;
            font-size: 15px;
        }
        & .text3 {
            font-size: 13px;
        }
    }

    .box-header {
        font-size: 17px;
        height: 56px;
        .box-sub-header { 
            font-size: 12px;
        }
    }

    #grid-img img.strategic {
        width: 8%;
    }

    #grid-img img.rampup0, #grid-img img.rampup1, #grid-img img.rampup2 {
        top: calc(50% - 160px)!important;
        width: 8%;
    }

    #grid-img img.rampup3 {
        width: 8%;
        top: calc(100% - 730px) !important;
    }
}
