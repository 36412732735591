@import '../../components/colorPallete.scss';

.solucao-integradora {
    font-family: 'Raleway', sans-serif;
    & .validar {
        margin: 16px;
    }
    & .solucao-header {
        & .MuiGrid-grid-xs-12 {
            display: flex;
            align-items: center;
        }
        & .inputSelectDiv {
            min-width: 120px;
            max-width: 120px;
            margin-right: 16px;
        } 
        & .button-download {
            display: flex;
            align-items: center
        }       
    }

    & .font-blue {
        color: $darkBlue;
        font-size: 25px;
    }

    & .font-blue-2 {
        color: $darkBlue;
        font-size: 20px;
        font-weight: 700;
    }

    & .font-grey {
        margin-top: 20px;
        color: $darkGrey;
        font-size: 15px
    }

    & .label-error {
        color: $darkGrey;
        margin-left: 5px;
    }

    & .atualizar-carga {
        width: 110px;
        & img {
            margin-left: 5px;
            width: 18px;
            height: 12px;
        }
    }
}

.modal-solucao-integradora {
    & .darkGrey-outline, .darkBlue-outline {
        margin-right: 16px;
    } 

    & .MuiCheckbox-colorSecondary.Mui-checked {
        color: $darkBlue;
    }

    & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
        font-size: 15px;
        padding-right: 8px;
    }

    & .status {

        & .finalizado-sem-erros {
            & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
                color: $darkGreen
            }
        }
        & .finalizado-com-erros {
            & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
                color: $yellow
            }
        }
        & .validando-layout {
            & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
                color: $darkBlue
            }
        }
        & .errro-layout {
            & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
                color: $red
            }
        }
        & .errro-carga {
            & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
                color: $orange
            }
        }
        & .aguardando-validacao {
            & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
                color: $darkGrey
            }
        }
    }



    & .space {
        margin-top: 30px;
        text-align: center;
    }
}

.MuiTableCell-root {
    & .validando-layout:before, 
    & .aguardando-validacao:before, 
    & .errro-layout:before,
    & .errro-carga:before, 
    & .finalizado-sem-erros:before, 
    & .finalizado-com-erros:before, 
    & .validacao:before {
        content: ' \25CF';
        font-size: 16px;
        padding-right: 8px; 
    }
    & .validando-layout:before {
        color: $darkBlue
    }
    & .errro-carga:before {
        color: $orange
    }
    & .finalizado-sem-erros:before {
        color: $darkGreen
    }
    & .errro-layout:before  {
        color: $red;
    }
    & .errro-carga:before  {
        color: $red;
    }
    & .finalizado-com-erros:before {
        color: $yellow;
    }
    & .aguardando-validacao {
        color: $darkGrey;
    }
}

label{
    font-family: "Raleway", sans-serif;
}
