@import '../../components/colorPallete.scss';

.parametro {
	font-family: 'Raleway', sans-serif;
	align-items: center;
	flex-direction: column;

	& .top-pagina {
		padding-bottom: 20px;
	}

	& .header-parametro {
		padding-top: 32px;
		
		& label {
			font-size: 15px;
			color: $darkBlue;
		}
	}

	& label {
		font-size: 25px;
		color: $darkBlue;
	}

	& hr {
		border: 1px solid $darkBlue;
		border-width: 0; 
		height: 1px;
		border-top-width: 1px;
	}

	& span.tipo-unidade-span {
	    font-size: 13px;
	    color: #FFFFFF;
	    background-color: $lightGrey;
	    width: 30px;
	    height: 30px;
	    display: inline-block;
	    text-align: center;
	    padding-top: 6px;
	}

	& div.formField {
		float: right;
		width: 325px;
		padding-right: 25px;
	}

	& div.item-lista {
		padding: 16px 0px!important;
		border-bottom: 1px solid $lighterGrey;
		height: auto!important;

		& label {
			padding-left: 10px;
			font-size: 13px;
			color: $darkGrey;
		}

		& button {
			float: right;
		}
		& .formField {
			margin-bottom: 0px;
		}
	}
}

