@import '../colorPallete.scss';

.stepper{
    margin-top: 20px;
    font-family: "Raleway", sans-serif;
    
    & .step {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-flow: column;
        align-items: center;
        & .MuiGrid-root.MuiGrid-item {
            width: 100%;
        }
    }
    & span.material-icons.MuiIcon-root {
        color: white;
    }
}
.review-info {
    text-transform: uppercase;
    width: 100%;
    
    & .MuiGrid-container {
        margin-top: 16px;
        margin-bottom: 16px;
    }
    & .MuiGrid-item{
        padding-top: 6px;
        padding-bottom: 6px;
        color: $darkGrey;
        font-size: 15px;
        word-wrap: break-word;    
    }
    & .review-title {
        color: $darkGrey;
        font-size: 17px;
    }
}

.stepper, .stepperBody{
    justify-content: center;
}

.stepperBodyTitle {
    padding-bottom: 20px;
}

.stepperBodyTitle, .stepperBodySon {
    display: flex;
    justify-content: center;
}
.stepperBodySon {
    padding: 0px 16px;
}
.MuiStepIcon-text{
    color: white;
}
span.MuiTypography-root.MuiTypography-body2.MuiTypography-displayBlock.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    color: $darkGrey;
    font-family: "Raleway", sans-serif;
}
//Normais
svg.MuiSvgIcon-root.MuiStepIcon-root{
    background-color: $lightGrey!important;
    color: $lightGrey!important;
}

//Ativo
svg.MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active {
    background-color: $darkBlue!important;
    color: $darkBlue!important;
}

//Completado
svg.MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-completed{
    border-radius: 30px;
    background-color: white!important;
    color: $darkBlue!important;
}
.MuiStep-completed span.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    background-color:  $darkBlue!important;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.rodapeStepper {
    margin: 20px 0px!important;
    display: flex;
    justify-content: center;
    padding: 0px 16px;
    & .darkBlue-outline:hover {
        background-color: rgba($darkBlue, 0.1);
        border: 1px solid $darkBlue;
        color: $darkBlue;
    }
}
.stepperBodyTitle p{
    font-size: 25px;
    color: $darkBlue;
    margin: 10px 0px;
}
.review-square {
    width: 30px!important;
    height: 30px!important;
    min-width: 30px!important;
    max-width: 30px!important;
    margin-right: 12px;
    background-color: #B4B8C0;
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    
    label {
        color: white !important;
        margin-bottom: 0 !important;
    }
}
.stepper .review-step {
    display: flex;
    color: $darkGrey;
    margin-bottom: 24px;
    justify-content: center;
    flex-direction: column;
    align-content: center;
}

.review-div {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    min-height: fit-content;
}
.review-div.MuiGrid-grid-md-6 {
    flex-basis: auto;

}

.review-info-atendimento {
    
    & .MuiGrid-item{
        padding-top: 6px;
        padding-bottom: 6px;
        color: $darkGrey;
        font-size: 15px;
        word-wrap: break-word;    
    }
    & .review-title {
        color: $darkGrey;
        font-size: 17px;
    }
    width: 50%;
}