@import '../colorPallete.scss';

$iconSize: 25px;
$sgtSvgSize: 2rem;

.menuButton {
  background-color: $darkBlue !important;
  border-radius: 0 !important;
  margin: 0 !important;
}

.menuButton svg {
  fill: white;
  width: $sgtSvgSize;
  height: $sgtSvgSize;
}

.sgt-sidebar-icon {
  width: $iconSize;
  height: $iconSize;
}

.sgt-sidebar-text span {
  color: $lightGrey;
  font-family: 'Raleway', sans-serif;
}

div .sgt-sidebar-item {
  background-color: $lighterGrey !important;
  border-bottom: 2px solid white;
}

.sgt-sidebar-item:hover {
  background-color: $darkBlue !important;
}

.sgt-sidebar-item:hover span {
  color: white !important;
}

.drawer-item > div {
  background-color: $lighterGrey;
}

a:link,
a:visited {
  color: (internal value);
  text-decoration: none;
  cursor: auto;
}

a:link:active,
a:visited:active {
  color: (internal value);
}

div .sgt-sidebar-item.Mui-selected {
  background-color: $darkBlue !important;
}

div .sgt-sidebar-item.Mui-selected span {
  color: white;
}

div .sgt-sidebar-subitem {
  background-color: $darkBlue;
  color: white;
  font-family: 'Raleway', sans-serif;
  & span {
    font-family: 'Raleway', sans-serif;
  }
}

div .sgt-sidebar-subitem:hover {
  color: white;
  background-color: $lightBlue;
}

div .MuiListItemIcon-root {
  min-width: 39px;
}
.menu-open .icon-list {
  height: calc(100% - 202px);
  overflow: auto;
  overflow-x: hidden;
}
.menu-closed .icon-list {
  height: calc(100% - 200px);
  overflow: hidden;
  
}
//Rodapé do sidebar
#sidebar-bottom, #sidebar-buttons, #sidebar-photo {
  width: inherit;
}

#sidebar-bottom {
  height: 160px;
  background: rgba(0, 0, 0, 0.5);

  & #sidebar-photo {
    bottom: 100px;
    display: flex;
      & .photo-button {
        padding: 5px!important;
      }
    & img {
      transition-duration: 0.2s;
      -webkit-transition: 0.2s;
      border-radius: 50%;
    }
    & .user-info {
      color: white;
      padding: 5px;
    }
    & span {
      white-space: normal;
      font-family: 'Raleway', sans-serif;
    }
    & b {
      font-weight: 700;
    }
  }
  & a:link, & a:visited {
    cursor: pointer
  }
}
.menu-open{
  & ::-webkit-scrollbar{ 
    width: 5px;
    background-color: $lighterGrey
  }
  & ::-webkit-scrollbar-thumb{
    background-color: $lightGrey;
    opacity: 0.54;
  }  
  & #sidebar-photo {
    height: 100px;
    & img {
      width: 84px;
      height: 84px;
      
    }
    & .user-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
     }
  }
  & #sidebar-buttons {
    display: flex; 
    justify-content: space-around;
    bottom: 0px;
    position: absolute;
    border-top: 1px solid $lightGrey;
    
    & .link:nth-child(1){
      border-right: 1px solid $lightGrey;
    }
    & .link {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      
    }
  }
}

.menu-closed {
  & #sidebar-photo {
    height: 57px;

    & img {
      width: 45px;
      height: 45px;
    }
    & .user-info {
     display: none;
     
    }
  }
  & .link button {
    width: 100%;
   }
  & #sidebar-buttons {
    bottom: 0px;
    position: absolute;
  }
}

.link {
  height: 50px;
  display: block;
  bottom: 0px;

  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }

  & button  {
    border-radius: unset;
    position: absolute;
    & span a{
      color: white;
    }
    &:hover {
      background: transparent;
    }
    & img{
      cursor: pointer;
    } 
  }
}