@import './../../components/colorPallete.scss';

#nome {
    text-transform: uppercase;
}

#login {
    text-transform: lowercase;
}

.usuario-novo {
	
	& .linha-chips {
		& span.material-icons.MuiIcon-root {
		    color: $red;
		     cursor: pointer;
		}

		& .MuiChip-icon {
		    margin-right: 5px;
		}
	}
	
}

.MuiSelect-select:focus {
	background-color: rgba(0, 0, 0, 0)!important;
}

.user-button {
	border: 1px solid $lighterGrey3!important;
	background-color: $lighterGrey!important;
	color: $darkGrey !important;
	margin: 1px 1px 1px 1px !important;
}

.inputSelectDiv .MuiInputBase-input {
	padding: 1px!important;
}

.formField .MuiInputBase-input {
	box-sizing: border-box;
}

.formField .MuiFormControl-root {
	padding-top: 8px;
}

