@import "../../components/colorPallete.scss";

#atendimentos-card {
    font-family: "Raleway", sans-serif;
  //  height: 100%;

    .wrapper-cards {
        padding-top: 50px;
    }

    .card-default {
        margin: 12px 9px;
        height: 80px;
        background: $lighterGrey;
        cursor: pointer;

        &.card-finalized {
            .information-side {
                .title {
                    color: white;
                }
                .status {
                    color: white;
                }
            }
            background-color: $darkGreen;
            .appropriate-hours-side {
                background-color: $darkGreen;
                .button-visita {
                    color: white;
                }
            }
        }
        &.card-canceled {
            .information-side {
                .title {
                    color: white;
                }
                .status {
                    color: white;
                }
            }
            cursor: auto;
            background-color:  $orange;
            .appropriate-hours-side {
                background-color: $orange;
                .button-visita {
                    color: white;
                }
            }
        }
        &.card-concluded {
            .information-side {
                .title {
                    color: white;
                }
                .status {
                    color: white;
                }
            }
            background-color:  $darkBlue;
            .appropriate-hours-side {
                background-color: $darkBlue;
                .button-visita {
                    color: white;
                }
            }
        }
        &.card-concluded-digital {
            .information-side {
                .title {
                    color: white;
                }
                .status {
                    color: white;
                }
            }
            background-color:  $darkBlue;
            .appropriate-hours-side {
                background-color: $darkBlue;
                .button-visita {
                    color: white;
                }
            }
        }
        &.card-concluded-digital {
            .information-side {
                .title {
                    color: white;
                }
                .status {
                    color: white;
                }
            }
            background-color:  $darkBlue;
            .appropriate-hours-side {
                background-color: $darkBlue;
                .button-visita {
                    color: white;
                }
            }
        }
        .information-side {
            padding: 8px 22px 0 22px;
            .title {
                line-height: normal;
                color: $darkBlue;
                font-weight: 800;
                font-size: 14px;
                margin-top: 8px;
            }
            .status {
                color: $darkGrey3;
                font-weight: 600;
                font-size: 12px;
            }
            p {
                margin: 0px;
            }
        }

        .appropriate-hours-side {
            height: 100%; 
            display: flex;
            justify-content: center;
            align-items: center;
            background: $lighterGrey4;

            span {
                font-weight: bold;
                font-size: 20px;
            }

            .button-visita {
                cursor: default;
                pointer-events: none;
                background: transparent;
            }
        }
    }

}