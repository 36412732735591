@import '../../components/colorPallete.scss';

.apropriar {
    display: 'flex';
    justify-content: 'space-between'!important;

    & .total-atendimentos{
        text-align: right;
        margin-left: -15px;
        color: $darkBlue;
        font-size: 15px;
        & b {
            font-weight: 700;
        }
    }
}

.MuiGrid-root .MuiGrid-item .MuiGrid-grid-xs-12 {
    b{
        color: $darkBlue;
        font-weight: 100;
    }

    h4{
        color: $darkGrey;
        width: 70%;
        margin-bottom: 25%;
        font-weight: 100;
        font-size: 9pt;
    }
       // Titulo atendimento
       h6 {
        color: $darkGrey;
        font-size: 14pt;
        margin-bottom: 0;
        margin-top: 0;
    }

    h5 {
        color: $darkGrey;
        font-size: 10pt;
    }
}

.informacoes{
    width: 340px;
    height: 18%;

    @media (min-width: 1280px) {
        h4{
            margin-left: 1%;
        }

        b{
            margin-left: 1%;
        }
        .botao{
            margin-left: 1%;
        }
    }

    @media (min-width: 1920px) {
        h4{
            margin-left: 12%;
            margin-right: 12%;
        }

        b{
            margin-left: 12%;
            margin-right: 12%;
        }
        .botao{
            margin-bottom: 4%;
            margin-left: 12%;
            margin-right: 12%;
        }
    }
}

@media (min-width: 600px) {
    .porcentagens {
        width: 100%;
    }    
}
@media (min-width: 960px) {
    .porcentagens {
        width: 33.333333%;
    }    
}
@media (min-width: 1280px) {
    .porcentagens {
        width: 25%;
    }
}
@media (min-width: 1920px) {
    .porcentagens {
        width: 18%;
    }    
}

.apropriar .porcentagens {
    color: white;
    position: fixed;
    border-left: 1px solid $lightGrey;
    width: -moz-available;
    margin-top: -93px;
    font-family: 'Raleway', '-apple-system', 'BlinkMacSystemFont';
    height: 100%;

    & div {
        align-items: center;
        text-align: center;
        display: flex;
        height: 100%;
        line-height: -moz-block-height;
        font-size: 15px;
    }

    & .dark {
        background-color: $darkBlue;
        border-bottom: 1px solid white;
    }

    & .light {
        background-color: $lightBlue;
        border-bottom: 1px solid white;
    }

    & .others {
        background-color: $darkGrey;
    }

    & label {
        width: 100%;
    }

    &.producao {
        margin-top: -28px;
        & .porcentagem {
            margin-top: 0;
            height: calc(100% - 30%)!important;
        }
    }

    &.vazio {
        color: $darkGrey;
        padding: 0px 60px;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 15px;
        background-color: white;
        & label {
            height: 50%;
        }
    }
    & .porcentagem {
        width: 100%;
    }
}

@media screen and (max-width: 960px) {
    .apropriar .porcentagens {
        display: none;
    } 
    .apropriar .informacoes {
        border: none!important;
        bottom: 0px!important;
    }
}

.apropriar .informacoes {
    color: #707070;
    background-color: white;
    border-left: 1px solid $lightGrey;
    font-family: 'Raleway', '-apple-system', 'BlinkMacSystemFont';
    font-size: 13px;
    bottom: 70px;
    position: fixed;

    & label {
        margin: 0px;
    }
    & div {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    & .total {
        margin-top: -14%;
        padding: 10px;
        font-size: 16px;
    }
    & p {
        margin: 10px 0px;
    }
}

@media screen and (min-width: 600px) {
    .apropriar .informacoes {

        & .total {
            border-top: 1px solid $lighterGrey;
            border-bottom: 1px solid $lighterGrey;
        }
        & button {
            margin: 10px 0px;
        }
        & p {
            padding-left: 58px;
            padding-right: 65px;
            text-align: justify;
        }
    }
}

@media screen and (min-width: 960px) {
    .buscar {
        padding-top: 43px!important;
    }
}

@media screen and (max-width: 959px) {
    .apropriar .pesquisa .MuiGrid-item {
        padding: 5px!important;
    }
}

.apropriar .formField .input input {
    text-transform: uppercase;
}

.apropriar .formField span {
    font-size: 11px !important;
}