@import '../../components/colorPallete.scss';

#trilha-container {
	font-family: 'Raleway', sans-serif;  
	padding: 3rem 4rem !important;

	& .etapa {
		padding: 1rem 3rem !important;
	}

	

	& #header {
		color: $darkBlue;
		font-size: 23px;
		border-bottom: 2px solid $lighterGrey2;
		& label {
			float: right;
			font-size: 12px;
			color: $lightBlue;
			font-weight: 600;
			margin-top: 13px;
		}
	}

	& #body {
		margin-bottom: 1rem;
		display: inline;
		& .reticencias-button {
			//position: absolute;
			z-index: 1;
			background-color: #fff;
			height: 380px;
			border-radius: unset;
			color: $darkBlue;
		}
		
		& .steper {
			position: relative;
			overflow-x: scroll;
			height: 380px;
			scrollbar-width: none;
			scroll-behavior: smooth;
		}

		& .steper::-webkit-scrollbar {
			display: none;
		}

		& .custom-step {
			padding-top: 180px;
			position: absolute;
			& img {
    			width: 24px;
			}

			& img.down {
				width: 18px;
			}

			& .MuiStepConnector-horizontal {
				width: 135px;

				& .MuiStepConnector-lineHorizontal {
					border-top-width: 2px;
				}

				&.MuiStepConnector-active .MuiStepConnector-lineHorizontal {
					border-color: $darkBlue;
				}

				&.MuiStepConnector-completed .MuiStepConnector-lineHorizontal {
					border-color: $darkGreen;
				}
			}

			& .MuiStep-horizontal {
				padding-left: unset;
				padding-right: unset;
			}

			& .MuiStepLabel-root.MuiStepLabel-horizontal {
				flex-direction: column;

				& .MuiStepLabel-iconContainer {
					padding-right: unset;

					& .div-data {
						display: flex;
						flex-direction: column;
						align-items: center;
						& label {
							position: absolute;
							margin-top: 35px;
							color: $darkGrey;
							font-size: 12px;
							font-weight: 500;
						}
					}
				}

				& .MuiStepLabel-labelContainer {
					position: absolute;
					width: unset;
				}
			}

			& .phase {
				display: flex;
				flex-direction: column;
				align-items: center;
				
				&.down {
					margin-top: 18px;
					& .div-buttons {
						display: flex;

						& .lightGrey {
    						pointer-events: none;
						}

						& .darkBlue:hover {
    						background-color: $darkBlue;
    						border: 1px solid $darkBlue;
						}

						& .darkGreen:hover {
    						background-color: $darkGreen;
						}

						& .lightBlue:hover, .lightBlue:active {
						    background-color: $lightBlue;
						    border: 1px solid $lightBlue;
						}

						& .disabled {
							pointer-events: none;
						}
					}
				}

				&.up {
					display: flex;
					align-items: flex-start;
					flex-direction: unset;
					margin-top: -100px;
					& label {
						font-weight: 600;
						color: $darkGrey;
						margin-left: 40px;
						position: absolute;
						font-size: 18px;
						white-space: nowrap;
					}
					& img {
						width: 30px;
					}
					& span {
						font-family: 'Raleway', sans-serif;  
						font-size: 17px;
						color: $lightGrey;
						position: absolute;
						margin-top: -40px;
						margin-left: -3px;
					}
				}
			}

			& .line {
				height: 70px;
				border-left: 1px solid $lightGrey;
			}
		}
	}

	& #footer {
		& .div-bolas {
			margin-top: -20px;
			float: right;
			display: flex;
			flex-direction: row;
			font-size: 12px;
			font-weight: 600;
			& label {
				color: $darkGrey;
				//margin-right: 20px;
				margin-left: 5px;
				margin-top: -3px;
			}
		}

		& .circulo-notificacao {
		    height: 12px;
		    width: 12px;
		    border-radius: 50%;
		    margin-left: 20px;
		}

		& .circulo-notificacao.green {
    		background-color: $darkGreen !important;
	    }

		& .circulo-notificacao.blue {
    		background-color: $darkBlue !important;
	    }

	    & .circulo-notificacao.gray {
	    	background-color: $lightGrey !important;
	    }

	    & .div-info-trilha {
	    	display: flex;
	    	background-color: $lighterGrey;
	    	width: 265px;
	    	height: 30px;
	    	padding: 0px 12px;
	    	cursor: pointer;
	    	& img {
	    		width: 20px;
	    		height: 20px;
	    		margin-right: 7px;
	    		margin-top: 5px;
	    	}
	    	& p {
	    		margin-top: 3.5px;
	    		font-size: 14px;
	    		color: $darkGrey;
	    	}
	    }
	}

	& #fechar-trilha {
		align-self: center;
		margin-top: 50px;
	}
}

.modal-pai {
	&.turma-modal {
		.title-status {
			color: $orange;
			padding-left: 10px;
		}
		&.info .modal-filho .content-game .tabela td {
			padding: 10px 35px;
		}
	}
}