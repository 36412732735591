@import '../../components/colorPallete.scss';


.title-container-review-mentoria-digital {  
    display: flex;
    justify-content: center;
    margin-bottom: 32px !important;

    h1 {
        color: $darkBlue;
    }
}

.etapa-table-review-mentoria-digital {
    margin-top: 64px !important;
}

.container-button-review-mentoria-digital {
    margin-top: 40px;
}