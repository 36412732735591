@import "../../components/colorPallete.scss";

.indicador-metas {
  font-family: "Raleway", sans-serif;
  & .grid-metas {
    padding-top: 40px;
    display: grid;

    & .tabs {
      padding-top: 20px;
      width: 100%;
    }
    & .metas-table {
      overflow: auto;
    }

    & #tableBody {
      font-family: "Raleway", sans-serif;
      & thead {
        & tr:nth-child(1) {
          border-bottom: 1px solid $lightBlue;

          & th {
            font-size: 13px;
            & #ano-left {
              cursor: pointer;
              padding-right: 15px;
            }
            & #ano-right {
              cursor: pointer;
              padding-left: 15px;
            }
          }
        }
      }
      & tbody tr {
        background-color: transparent;
        &:hover {
          background-color: $lighterGrey2;
        }
        &:hover td:nth-child(1) {
          background-color: transparent !important;
          cursor: auto;
        }
        & td:nth-child(1),
        td:nth-child(2) {
          font-style: normal;
          color: $darkGrey;
          font-size: 13px;
          font-weight: 600;
          pointer-events: auto;
          width: 200px;
        }
        & td {
          color: $darkGrey;
          background-color: transparent;
          border-bottom: 1px solid $lighterGrey !important;
          font-size: 13px;
        }
      }
    }
  }
}

.checkbox {
  & span {
    color: $darkBlue;
  }
}

.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: transparent !important;
}

.MuiIconButton-colorSecondary:hover {
  background-color: transparent !important;
}

.tipo-unidade-span {
  font-size: 13px;
  color: #ffffff;
  width: 28px;
  height: 22px;
  display: inline-block;
  text-align: center;
  padding-top: 2px;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  margin-right: 10px;
  &.dr {
    background-color: $darkBlue;
  }
  &.uo {
    background-color: $lightBlue;
  }
}

.tipo-unidade-label {
  font-family: "Raleway", sans-serif;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 500;
  &.dr {
    color: $darkBlue;
  }
  &.uo {
    color: $lightBlue;
  }
}

.info-total {
  background-color: $lighterGrey;
  padding: 9px 30px;
  height: 40px;
  color: $darkBlue;
  font-weight: 600;
  font-size: 15px;

  & #infoValor {
    float: right;
  }
}

.on-click-dr {
  cursor: auto !important;
}

#labelMetas {
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  color: $darkGrey;
}

.grid-filtro {
  padding-top: 29px;
}

#tipoMeta {
  font-size: 17px;
  color: $lightBlue;
}

#info {
  font-size: 13px;
  color: $darkGrey;
}

.modal-metas {
  & .modal-pai .formField .input label {
    font-family: "Raleway", sans-serif;
    color: $darkGrey;
    font-size: 13px;
    font-weight: 500;
  }

  & .modal-filho {
    & h2 {
      text-align: center;
      font-size: 22px;
      color: $darkBlue;
      margin: 0px;
      font-weight: unset;
      padding-bottom: 20px;
    }
  }
}
