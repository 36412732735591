@import "../../components/colorPallete.scss";

.labelButtonAdd {
    background-color: $darkBlue;
    color: white !important;
    align-self: center;
    padding: 6px;
    height: 30px;
}

.labelRelatorio {
    color: $darkGrey;
    align-self: center;
    border: 1px solid $lightBlue;
    padding: 6px;
    height: 30px;
}

.turmaNome {
    text-align: left;
    font: Bold 12px/14px Raleway;
    color: $lightGrey;
    opacity: 1;
}

.registroFoto{
    text-align: left;
    font: 12px/14px Raleway;
    font-weight: 600;
    color: $darkBlue !important;
    opacity: 1;
}

.turma-producao {
    font: 15px Raleway;
    color: $darkGrey;
    margin-top: 10px !important;
}

.turmaEtapaCircle {
    height: 49px;
    width: 49px;
    background: $darkGrey;
    border-radius: 50%;
    position: absolute;
    padding-left: 18px;
    padding-top: 12px;
    margin: -15px;
    & span {
        font: 21px/25px Raleway;
        text-align: left;
        color: white;
    }
}

.turmaEtapaCircleDigital {
    height: 49px;
    width: 49px;
    background: $darkBlue;
    border-radius: 50%;
    position: absolute;
    padding-left: 18px;
    padding-top: 12px;
    margin: -15px;
    & span {
        font: 21px/25px Raleway;
        text-align: left;
        color: white;
    }
}

.modal-remove-visita {
    & .MuiGrid-container {
        flex-direction: column;
        align-items: flex-start;
    }
    & label {
        text-align: none;
        font-weight: 700;
    }
    & p {
        font-size: 17px;
        text-align: justify;
        margin: 15px 15px;
    }
}

.etapa {
    font-family: "Raleway", sans-serif;
    
    & .flex-end {
        align-self: flex-end;
    }

    & .headerMentoria {
        text-align: left;
        font: 23px Raleway;
        color: $darkBlue;
        padding-top: 5px;
        text-transform: capitalize;
        & .label-bold-head {
            font-weight: bold;
        }
    }

    & .headerMentoriaDigital {
        text-align: left;
        font: 23px Raleway;
        color: $darkBlue;
        padding-top: 5px;
        & .label-bold-head {
            font-weight: bold;
        }
    }

    #upload-atividade {
        text-align: center;
    }

    #span-upload-atividade {
        padding-left: 15px;
        padding-top: 5px;
        font: 16px Raleway;
        color: $darkGrey;
    }

    & .atividade-dafault-form {
        & .formField {
            margin-top: 10px;
            width: 300px;
            & textarea {
                height: 69px;
            }
        }
    }

    & .bottom-buttons {
        text-align: center;
        margin-top:60px;
    }

    & .atividade-estado-presente {
        font-family: 'Raleway', sans-serif;
        & .grid-label {
            color: $darkBlue;
            font-size: 17px;
            margin-top: 30px;
            margin-bottom: 30px;
            & label {
                margin-left: 20px;
            }
        }

        & .formfield-button {
            & .div-formfield-button {
                display: flex;
                & .formField {
                    width: 100%;
                }
                & button {
                    margin-top: 31px;
                }
            }
            &.error {
                & .span-error {
                    color: $red;
                }
                & input {
                    border: 1px solid $red;
                }
            }
        }

        & .MuiChip-root {
            background-color: white !important;
            & label {
                font-style: italic;
            }
        }
    }

    & .atividade-mfv {
        & .MuiGrid-root.MuiGrid-container .inputSelectDiv {
            width: 100%;
        }
        
        & .botao {
            & button {
                margin-top: 31px;
            }
        }

        & .MuiChip-root {
            background-color: white !important;
            & label {
                font-style: italic;
            }
        }
        
        .label-title {
            font-family: 'Raleway', sans-serif;
            font-size: 15px;
            color: $darkGrey;
        }
    }

    & .atividade-desperdicio {
        font-family: 'Raleway', sans-serif;
        & .grid-body {
            margin-top: 70px;
            & .botao-desperdicio {
                width: 160px;
            }
        }
        & .label-botoes {
            font-size: 15px;
            color: $darkGrey;
            position: absolute;
            margin-top: -30px;
        }
        & .label-botao {
            font-size: 19px;
            color: $darkGrey;
        }
        & .atividade-desperdicio + div {
            margin-top: 10px;
            margin-bottom: -30px;
            z-index: 1;
        }

        & .atividade-desperdicio + .div-border {
            border-bottom: 2px solid $darkGrey;
        }
    }
  
    & .atividade-linha-producao {
        & .div-field-btn {
            display: flex;
            & div {
                width: 100%;
            }
            & button {
                margin-top: 31px;
            }
        }

        & .formField {
            & textarea {
                &:disabled {
                    background-color: $lighterGrey2 !important;
                }
            }
        }

        & .inputSelectDiv {
            & span {
                margin-top: 3px;
            }
        }

        & .linha-producao-box {
            background: $lighterGrey;
            padding-left: 50px;
            width: 68%;
            & label {
                font-family: 'Raleway', sans-serif;
                font-size: 15px;
                color: $darkGrey;
            }
        }
        
        & .MuiRadio-root {
            color: $darkBlue;
        }

        & .radioDisabled {
            .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary.Mui-disabled {
                color: $lightGrey3 !important;
            }
        }

        & label.MuiFormControlLabel-root {
            padding: 0 20px 0 0;
        }

        & .MuiChip-root {
            background-color: white !important;
            width: auto !important;
            font-style: italic !important;
            & .MuiChip-label {
                padding: 1px 4px 10px;
            }
        }
    }

    & .atividade-linha-producao-digital {


        & .label-processo {
            color: $lightBlue;
            font-size: 17px;
            font-weight: 500;
        }

        & .label-processo-medicao {
            color: $lightBlue;
            font-size: 17px;
            font-weight: 600;
            font-style: italic;
        }

        & .grid-indicador {
            background-color: $lighterGrey;
            height: 54px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            font-weight: bold;
            font-style: italic;
            color: $darkBlue;
            padding: 22px 20px;
        }

        & .title-label{

            
            margin-top : 20px;
            & label{
                
                color: $lightBlue !important;
                font-size: 17px !important;
                font-weight: bold;
            } 
        }

        & .title-label-gray{

            
            margin-top : 20px;
            & label{
                
                color: $darkGrey !important;
                font-size: 17px !important;
                font-style: italic;
            } 
        }

        & .warning-label{
            
            margin-top : 20px;
            & label{
                
                color: $red !important;
                font-size: 14px !important;
            }
        }

        & .field-label{

            
            margin-top : 20px;
            & label{
                
                color: $darkBlue !important;
                font-size: 13px !important;
            } 
        }

        & .div-field-btn {
            display: flex;
            & div {
                width: 100%;
            }
            & button {
                margin-top: 31px;
            }
        }

        & .formField {
            & textarea {
                &:disabled {
                    background-color: $lighterGrey2 !important;
                }
            }
        }

        & .inputSelectDiv {
            & span {
                margin-top: 3px;
            }
        }

        & .linha-producao-box {
            background: $lighterGrey;
            padding-left: 50px;
            width: 100%;
            & label {
                font-family: 'Raleway', sans-serif;
                font-size: 15px;
                color: $darkBlue;
            }
        }
        
        & .MuiRadio-root {
            color: $darkBlue;
        }

        & .radioDisabled {
            .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary.Mui-disabled {
                color: $lightGrey3 !important;
            }
        }

        & label.MuiFormControlLabel-root {
            padding: 0 20px 0 0;
        }

        & .MuiChip-root {
            background-color: white !important;
            width: auto !important;
            font-style: italic !important;
            & .MuiChip-label {
                padding: 1px 4px 10px;
            }
        }
    }

    & .atividade-processo-produtivo {
        font-family: 'Raleway', sans-serif;

        & .warning-label{
            
            margin-top : 20px;
            & label{
                
                color: $red !important;
                font-size: 14px !important;
            }
        }

        & .grid-body {
            margin-top: 30px;
            & .label-processo {
                color: $lightBlue;
                font-size: 16px;
                font-style: italic;
                font-weight: 500;
            }

            & .grid-indicador {
                background-color: $lightGrey;
                height: 54px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 16px;
                font-weight: bold;
                font-style: italic;
                color: white;
                padding: 22px 20px;
            }
        }
    }

    & .atividade-plano-de-acao {
        font-family: 'Raleway', sans-serif;
        & .grid-body {
            & .nome-error {
                color: $red;
            }
        }

        & .collapse {
            & .MuiGrid-container {
                & .icon-and-button {
                    width: unset;
                    justify-content: unset;
                    margin-left: 10px;
                    margin-top: 3px;
                }
                & .form-acao {
                    justify-content: center;
                }

                & .MuiExpansionPanelSummary-content {
                    & p {
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    & .label-ferramentas {
        & label {
            font: italic 16px/25px 'Raleway', serif;
            color: $lightBlue;
            font-weight: 500;
        }
    }

    & .add-ferramentas {
        display: flex;
        & div {
            width: 100%;
        }
        & button {
            margin-top: 28px;
        }
        & label {
            font: 15px 'Raleway', serif;
            color: $darkBlue;
        }
    }

    & .atividade-tecnologias {
        & .label-box {
            & label {
                font-family: 'Raleway', sans-serif;
                font-size: 18px;
                color: $darkBlue;
            }
            margin-left: 14%;
            padding-bottom: 4px;
        }
        & .tecnologias-box {
            border-top: 1px solid $darkGrey;
            border-bottom: 1px solid $lightGrey;
            align-content: center;
            padding-left: 5%;
            padding-right: 5%;
            background: transparent;
            width: 75%;

            & label {
                font-family: 'Raleway', sans-serif;
                font-size: 15px;
                color: $darkGrey;
            }
        }
    }

    & .atividade-resultados {
        font-family: "Raleway", sans-serif;
        $fontDiff: Bold Italic 16px/19px Raleway;

        div {
            width: 100%;
            align-content: center;
        }

        & .termo-grid {
            margin: 30px 0 30px;
        }

        & .termo-info {
            padding: 5px;
        }

        & .upload-btn {
            &.assinado {
                color: $darkGreen;
            }
        }

        & .label-assinado {
            padding: 20px;
            font: 12px 'Raleway';
            font-weight: 500;
            color: $darkGreen;
        }

        & .label-card {
            font: italic 16px 'Raleway';
            color: $lightBlue;
            font-weight: 500;
        }
    
        & .result-card {
            margin: 12px 0px 30px;
            height: 54px;
            
            & .info-card {
                padding: 3px 22px 0 15px;
                color: $darkBlue;
                background: $lighterGrey;
                height: 54px;

                & .style-info {
                    color: $darkBlue;
                    & .labels {
                        font-size: 14px;
                    }
                    & .diff {
                        font: $fontDiff;
                    }
                    & .payback {
                        font: $fontDiff;
                    }
                }
            }
    
            & .info-aumento {
                padding-top: 3px;
                text-align: center;
                height: 54px;
                background-color: $darkGrey;

                &.ok {
                    background-color: $darkGreen;
                }

                &.war {
                    background-color: $orange;
                }

                &.full {
                    background-color: $yellow;
                }

                & .style-info {
                    color: white !important;
                    & .labels {
                        font-size: 14px;
                        color: white !important;
                    }
                    & .diff {
                        font: $fontDiff;
                        color: white !important;
                    }
                }
            }
        }
    
    }

    & .div-evidencia {
        width: 300px;
        height: 170px;
    }

    & .div-border {
        border-bottom: 1px solid $lighterGrey2;
        &.top {
            margin-top: 30px;
        }
        &.bottom {
            margin-bottom: 30px;
        }
        &.dark {
            border-bottom: 2px solid $darkGrey;
        }
        &.none{
            margin-top: 30px;
            margin-bottom: 30px;
            border-bottom: none;
        }
    }

    & .add-info {
        margin-top: 32px;
        width: 100%;
    }

    & .add-info-digital {
        margin-top: 32px;
        width: 100%;
        text-align: center;
    }

    & .add-info-negative {
        margin-bottom: -120px;
    }

    & .add-info-positive {
        margin-bottom: 20px;
    }

    & .MuiSwitch-root {
        width: 65px;
        height: 26px;
        padding: 0px;
        display: 'flex';
    }

    & .MuiSwitch-switchBase {
        padding: 5px;
        color: $darkBlue;
    }

    & .MuiSwitch-switchBase.Mui-checked {
        padding: 5px;
        color: $darkBlue;
        transform: translateX(39px);
    }

    & .MuiSwitch-switchBase.Mui-disabled {
        color: $lightGrey3 !important;
    }

    & .MuiSwitch-track {
        border-radius: 13px;
        background-color: white;
        border: 1px solid $lightGrey2;
    }

    & .MuiSwitch-thumb {
        width: 16px;
        height: 16px;
    }

}

