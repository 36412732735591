

.confirmacao-label {
    color: var(--unnamed-color-707070);
    text-align: center;
    font: normal normal normal 17px/22px Raleway;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}

.confirmacao-label-bold {
    font-weight: 600 !important;
    color: var(--unnamed-color-707070);
    text-align: center;
    font: normal normal normal 17px/22px Raleway;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}
