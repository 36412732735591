@import '../../../components/colorPallete.scss';

.apropriacao-grupo {
    display: 'flex';
    justify-content: 'space-between';
    font-family: "Raleway", sans-serif;  

    .progress-line{
        & .progress-bar {
            width: 90%;
            align-items: right;
            font-weight: 600;
            margin-bottom: 10%;
        }
        .progress-bar .total {
            background-color: $lighterGrey;
        }
    }

    & .total{
        text-align: right;
        color: $darkBlue;
        margin-left: 19%;
        font-size: 15px;
        & b {
            margin-left: 1%;
            font-weight: 700;
        }
    }

    & .total-atendimentos {
        text-align: right;
        color: $darkBlue;
        margin-left: 16%;
        font-size: 15px;
        align-items: center;
        & b {
            margin-left: 2%;
            font-weight: 700;
        }
    }

    .pagination-gerenciar-atendimento {
        p{
            color: $darkBlue;
            font-weight: 500;
        }
    }

    .return-gerenciar-atendimento {
        p {
            color: $darkGrey;
            font-weight: 500;
        }
    }

    & .info-atendimento {
        color: $darkGrey;
        
        h1 {
            font-size: 16pt;
            font-weight: bold;  
        }

        .Box-label {
            display: flex;
            align-items: center;
            margin-bottom: -32px;
    
            h5 {
                padding-right: 20px;
            }
        }

        & .infoBox-Atendimento {
            width: 100%;
            display: flex;
            margin-bottom: 1%;
            justify-content: start;

            b {
                margin: 0;
                font-weight: 700;
                font-size: 10pt;
                color: $darkGrey;
            }

            p {
                margin: 0;
                margin-left: 8%;
                font-size: 9pt;
            }

            & .infoBox-Ajuste {
                width: 80%;

                & p {
                margin-left: 2%;
               }

            }
        }
    }
    & .prod-periodo {
        display: flex;
        color: $darkGrey;
        // padding-right: 16px;

        & .MuiGrid-container {
            margin-top: 6%;
            padding-top: 4%;
            padding-bottom: 6%;
            background-color: $lighterGrey;
            width: 100%;
            height: 80%;
        }
        & .MuiGrid-item {
            display: flex;
            justify-content: center;
        }
        & h1 {
            font-size: 35px;
            margin: 0px;
            font-weight: 700;
        }
        & p {
            color: $darkGrey;
            font-size: 11pt;
        }
        & .formField {
            margin: 0
        }
    }
    & .prop-realizada {
        display: flex;
        color: $darkGrey;
        
        & .MuiGrid-container {
            margin-top: 9%;
            padding-top: 4%;
            padding-bottom: 6%;
            background-color: $lighterGrey;
            width: 100%;
            height: 80%;
        }
        & .MuiGrid-item {
            display: flex;
            justify-content: center;
        }
        & p {
            color: $darkGrey;
            margin-top: 6%;
            font-size: 11pt;
        }
        & .divCell {
            width: 150px;
        }
        & .progress-bar {
            width: 70%;
            align-items: center;
            font-weight: 700;
            margin-right: 10%;
        }
        .progress-bar .total {
            background-color: white;
        }
    }
    & .rodape-apropriacao {
        display: flex;
        justify-content: center;
        & .darkBlue-outline {
            margin-right: 16px;
        }
    }
    & .modal-historico {
        width: 700px;
    }
}

@media screen and (max-width: 959px) {
    .apropriacao-grupo .pesquisa .MuiGrid-item {
        padding: 5px!important;
    }
}

.apropriacao-grupo td:nth-child(6) {
    font-weight: 700;   
}

.modal-historico, .modal-producao, .modal-busca-grupo {
    font-family: "Raleway", sans-serif;  
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $darkGrey;
    font-size: 15px;

    & h2 {
        font-size: 25px;
        margin: 0px;
        font-weight: 300
    }
    & .darkGrey-outline, .darkBlue-outline {
        margin-right: 16px;
    }
}
.modal-producao {
    & p {
        color: $darkGrey;
        text-align: center;
        margin-bottom:16px 0px;
    }
    
    & span {    
        color: $lightGrey;  
        font-size: 13px; 
    }
    & img {
        width: 17px;
        margin-right: 8px;
    }
    & div {
        display: flex;
        flex-direction: row;
    }
    & .darkGrey-outline {
        margin-right: 16px;
    }
}
.modal-historico {
    font-size: 15px;
    & h2 {
        margin: 0 0 16px 0;
    }
    & p {
        margin: 4px 0;
    }
    & .MuiGrid-grid-xs-12 {
        margin-bottom: 16px;
    }
    & .colaborador {
        border-bottom: 1px solid $lighterGrey;
        padding: 8px 0;
    }
    & .font-blue {
        margin-right: 16px;
    }
    & .formField {
        margin: 0px;
        .input,label{
            margin: 0px;
        }
    }
    & .inputSelectDiv {
        width: 80px;
        margin: 0px;
        & label {
            margin: 0;
        }
    }
}
.modal-busca-grupo , .prod-periodo {
    & img {
        margin: 4px;
    }
}