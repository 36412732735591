@import "../colorPallete.scss";
.header {
  border-bottom: 1px solid $darkBlue;
  height: 52px;
  padding: 10px;
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  color: $darkBlue;
  display: flex;
  align-items: center;
  background-color: white;
  padding-right: 20px;
  top: 0;

  width: 100%;
  z-index: 90;

  & img {
    max-width: 30px;
    max-height: 30px;
    height: auto;
    text-align: center;
  }
  & .header-title img {
    margin: 0 16px 0 16px;
  }
  #imagem {
    background-color: $darkBlue;
    height: 30px;
    mask: url(#mymask);
    margin: 0 16px 0 16px;
  }
  .inputSelectDiv {
    margin: 0px;
    margin-top: 0px;
    background-color: #00b1e8;
    padding: 3px;
    max-width: 300px;
    height: 52px;

    & .MuiSelect-root {
      padding: 0px 20px 5px 5px !important;
      max-width: 250px;
      background-color: #00b1e8 !important;
      border: none !important;
      color: white !important;
    }
  }
  & .circulo-notificacao {
    height: 12px;
    width: 12px;
    background-color: $yellow;
    border-radius: 50%;
    position: absolute;
    margin-top: 5px;
    margin-left: 20px;
    & span {
      font-size: 10px;
      padding-left: 4px;
      font-weight: 600;
      color: white;
      cursor: pointer;
    }
  }
}

.header-icones {
  width: 40px;
  cursor: pointer;
  & img {
    width: 24px;
    height: 24px;
  }
}

.header div {
  display: flex;
  align-items: center;
}

div .header-parent {
  justify-content: space-between;
}
.icones {
  justify-content: flex-end;
}
