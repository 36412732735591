@import '../../components/colorPallete.scss';

.tooltip-solicitacao {
    position: relative;
    display: inline-block;
}
  
.tooltip-solicitacao .tooltip-info {
    visibility: hidden;
    width: 160px;
    background-color: $darkGrey;
    color: white;
    text-align: center;
    padding: 10px 10px;
    position: absolute;
    z-index: 1;
    bottom: 110%;
    margin-bottom: 1px;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-info.avaliar {
    width: 100px;
    margin-left: -55px;
}

.tooltip-info.anexos, .tooltip-info.indicadores {
    width: 120px;
    margin-left: -72px;
}

.tooltip-solicitacao .tooltip-info::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 30%;
    margin-left: 10px;
    border-width: 5px;
    border-style: solid;
    border-color: $darkGrey transparent transparent;
}

.tooltip-solicitacao:hover .tooltip-info {
    visibility: visible;
    opacity: 1;
}

.pagination-dn {
    display: flex;
    padding: 8px 0px;
    align-items: center;
    & .white {
        border: 1px solid white;
        margin: 0px 4px;
        & img {
            filter: saturate(0);
        }
    }
    & .left {
        -webkit-transform:rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
    & .right {
        -webkit-transform:rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

.MuiTableCell-root {
    & .conforme:before,
    & .em-analise:before, 
    & .nao-conforme:before, 
    & .validacao:before {
        content: ' \25CF';
        font-size: 16px;
        padding-right: 8px; 
    }
    & .conforme:before {
        color: $darkGreen
    }
    & .em-analise:before  {
        color: $yellow;
    }
    & .nao-conforme:before {
        color: $red;
    }
}

.modal-change-status {
    & .MuiGrid-container {
        flex-direction: column;
        align-items: flex-start;
    }
    & label {
        text-align: none;
        margin: 0 15px;
    }
    & p {
        font-size: 17px;
        text-align: justify;
        margin: 15px 15px;
    }
    & .field-note {
        & p {
            font-size: 15px;
            margin: 15px 0;
        }
        margin: 15px 15px;
    }
    & .conforme:before {
        color: $darkGreen
    }
    & .naoconforme:before {
        color: $red
    }
}

span#em-analise:before{
    content: ' \25CF';
    font-size: 16px;
    padding-right: 8px;
    color: $yellow;
}
 span#conforme:before{
    content: ' \25CF';
    font-size: 16px;
    padding-right: 8px;
    color: $darkGreen;
    }
span#nao-conforme:before{
    content: ' \25CF';
    font-size: 16px;
    padding-right: 8px;
    color: $red;
}

.modal-solicitacao {
    & .formField {
        width: 140px
    }
    & span.isInvalid {
        color: $red;
    }
}

.modal-pai.modal-solicitacoes-dn {

    & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label::before {
        content: ' \25CF';
        font-size: 16px;
        padding-right: 8px;
        color: $red;
    }

    & .em-analise span::before {
        color: $yellow!important;
    }

    & .conforme span::before {
        color: $darkGreen!important;
    }

    & .nao-conforme span::before {
        color: $red!important;
    }

    & .inputSelectDiv label {
        font-size: 15px;
    }

    & .auto-complete-many {
        & > label:first-child {
            color: $darkBlue;
        }

        & span {
            margin-top: 0px;
        }
    }

    & .ou-p {
        margin-top: -10px;
        margin-bottom: -1px;
        font-size: 15px;
    }

    & .box-parecer {
        color: $darkBlue;
    }
}