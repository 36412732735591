@import "./../../components/colorPallete.scss";

body {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  color: $darkGrey;
}

.background {
  height: 100vh;
  background: url("fundo-login.jpg") no-repeat;
  background-size: cover;
}

.headerLogin {
  display: flex;
  flex-direction: column;
}

.headerLogin img {
  width: 300px;
}

.esqueciSenha {
  cursor: pointer !important;
  font-weight: 500;
  font-size: 10px;
  font-family: "Raleway", sans-serif;
}

.login {
  background-color: transparent;
  padding: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  min-width: 270px;
  max-width: 280px;

  & .formField {
    margin-bottom: 15px;
  }

  & .formField input {
    height: 30px;
    padding: 0px;
    padding-left: 10px;
    width: auto;
  }

  & .formField label {
    color: $darkBlue;
    font-size: 10px;
    margin-bottom: 3px;
  }
}

.login button {
  width: 100%;
  height: 30px;
  margin-top: 10px;
  font-size: 11px;
}

.div-circle-login {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  margin-bottom: 15px;
}

.div-circle-login img {
  border-radius: 50%;
  width: 110px;
  height: 110px;
  vertical-align: middle;
  border: 0;
}

.header-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 4vw;
  position: absolute;
  padding: 9vh;
}

.footerLogin {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 1vw;
  justify-content: center;
}

.footerLogin label {
  margin-right: 15px;
  font-size: 9px;
  color: $darkGrey;
}

.footerLogin img {
  height: 20px;
}

.footerLogin label {
  text-align: center;
}

@media screen and (max-width: 600px), screen and (max-height: 460px) {
  .footerLogin {
    display: none;
  }

  .header-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: unset;
  }

  .background {
    background: white;
    background-size: cover;
  }
}

@media screen and (min-width: 1400px) and (min-height: 700px) {
  .header-body {
    left: 6vw;
    padding: 14vh;
  }

  .login {
    margin-top: 100px;
  }

  .headerLogin img {
    width: 340px;
  }
}
