@import './../../components/colorPallete.scss';

.dropdown {
    border: 1px solid black;
    max-height: 180px;
    overflow: auto;
    position: absolute;
    background-color: white;
    z-index: 99;
    margin-top: -10px;
    font-family: "Raleway", sans-serif;
    color: $darkGrey;
    border: 1px solid $lighterGrey;

    // width: 100%;
    & .dropdown-item {
        padding: 5px 15px;
        font-size: 15px;
        cursor: pointer;
        & .small {
            font-size: 13px;
        }
        &:hover {
            background-color: $lighterGrey
        }
    }
}