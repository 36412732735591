@import '../colorPallete.scss';

.basic-button {
    min-height: 30px;
    font-family: "Raleway", sans-serif;
    padding: 3px 10px;
    cursor: pointer;
    font-size: 14px;
    outline: none;

    &.full-width{
        width: 100%;
    }

    &.tab{
        height: 48px;
        font-size: 17px;
    }

    &.rounded {
        border-radius: 8px;
    }

    &.rounded-left {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    &.rounded-right {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.button-disabled{
    min-height: 30px;
    font-family: "Raleway", sans-serif;
    padding: 3px 10px;
    font-size: 14px;
    outline: none;
    background: none;
    border: none;
    cursor: not-allowed;
}

.tagButton {
    height: 20px;
    font-family: "Raleway", sans-serif;
    cursor: pointer;
    & img {
        display: none;
    }
}

.trash-button {
    width: 100%;
    height: 50px !important;
    margin-left: -32px;
}

.icon-button {
    width: 30px;
    height: 30px;
    padding: 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
        max-width: 20px;
        max-height: 20px;
    }

    &.rounded {
        border-radius: 8px;
    }

    &.rounded-left {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    &.rounded-right {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.icon-and-button {
    font-family: "Raleway", sans-serif;
    width: 90%;
    height: 10px;
    padding: 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
        max-width: 20px;
        max-height: 20px;
    }
}

.with-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
        margin-left: 5px;
        width: 20px;
        height: 20px;
    }
}

.busca-avancada {
    width: 153px;
    & img {
        margin-left: 5px;
        width: 18px;
        height: 12px;
    }
}

button.darkBlue.icon-button img {
    filter: brightness(2.0);
}

button[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
}
.icon-button:hover, .icon-button:active, .icon-button:focus {
    padding: 0px;
}

@mixin button-fill($color, $border, $font) {
    background-color: $color;
    border: 1px solid $border;
    color: $font;
}
.white {
  background-color: white;
}

.inverse-red {
    background-color: rgb(253, 236, 234);
    color: #f44336;
    padding: 10px;
    transition: all 0.3s ease;
    border: 1px solid #f44336;
    
    &:hover, &:focus {
        background-color: #fccac8;
    }
}

@mixin button-image($img){
    display: flex;
    position: relative;
    content: $img;
    width: 20px;
    height: 20px;
    margin: auto;
}

//Botão prioridade 1
@each $tuple in $buttonPriotity1 {
    .#{nth($tuple, 1)} {
        @include button-fill(nth($tuple, 2), nth($tuple, 2), white);

        &:hover {
            @include button-fill(nth($tuple, 2), nth($tuple, 3), white);
        }

        &:active,  &:focus {
            @include button-fill(nth($tuple, 3), nth($tuple, 3), white);
        }
    }
}

//Botão prioridade 2 
@each $tuple in $buttonPriotity2 {
    .#{nth($tuple, 1)}-outline {

        @include button-fill(white, nth($tuple, 2), nth($tuple, 2));

        &:hover {
            @include button-fill(rgba(nth($tuple, 2), 0.1), nth($tuple, 2), nth($tuple, 2));
        }

        &:active,  &:focus {
            @include button-fill(nth($tuple, 2), nth($tuple, 2), white);
        }
    }
}

// Tags coloridas
@each $tuple in $tagList {
    .#{nth($tuple, 1)} {

        @include button-fill(nth($tuple, 2), nth($tuple, 2), white);

        &:hover {
            @include button-fill(nth($tuple, 2), nth($tuple, 3), white);
        }

        &:active,  &:focus {
            @include button-fill(nth($tuple, 3), nth($tuple, 3), white);
        }
    }
}

.darkBlue-outline:active, .darkBlue-outline:focus {
    color: $darkBlue;
    background-color: transparent;
}

.darkGrey-outline:active, .darkGrey-outline:focus {
    color: $darkGrey;
    background-color: transparent;
}

.orange-outline:active, .orange-outline:focus {
    color: $lightOrange;
    background-color: transparent;
}

.yellow-outline:active, .yellow-outline:focus {
    color: $lightYellow;
    background-color: transparent;
}

.darkBlue:active, .darkBlue:focus {
    background-color: $darkBlue;
}

.darkGrey:active, .darkGrey:focus {
    background-color: $darkBlue;
}

.orange:active, .orange:focus {
    background-color: $lightOrange;
}

.yellow:active, .yellow:focus {
    background-color: $lightYellow;
}

.darkBlue:hover {
    background-color: $darkBlue2;
}

.orange:hover {
    background-color: $orange;
}

.yellow:hover {
    background-color: $yellow;
}

.darkGreen:active, .darkGreen:focus {
    background-color: $darkGreen;
}

.darkGreen:hover {
    background-color: $darkerGreen;
}

.lightGrey:active, .lightGrey:focus {
    background-color: $lightGrey;
}

.lightGrey:hover {
    background-color: $darkGrey;
}
