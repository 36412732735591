.loading-modal {
	display: flex;
	align-items: center;
	justify-content: center;

	& div.MuiBackdrop-root {
		background-color: rgba(0, 0, 0, 0.4);
	}
}

.loading-img {
	border-radius: 50%;
	width: 45px;
	box-shadow: 0 0px 23px rgba(0, 0, 0, .6);
	&:focus {
		outline: none!important;	
	}
}
.loading-fade:focus{
	outline: none!important;	
}