@import "../../components/colorPallete.scss";

.card-atividade {
    width: 200px;
    height: 169px;
    background: $lighterGrey;
    opacity: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.card-atividade-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.MuiGrid-root.card-atividade.MuiGrid-item {
    margin-top: 20px;
    margin-right: 10px;
}

.iconeAtividade {
    text-align: center;
}

.labelAtividade {
    text-align: center;
    font: 14px Raleway;
    color: $darkGrey;
    text-transform: capitalize;
}

.labelAtividadeNoCap {
    text-align: center;
    font: 14px Raleway;
    color: $darkGrey;
}

.statusAtividade {
    font: 12px Raleway;
    color: white;
    text-align: center;
    align-content: center;
    height: 26px;
    margin-top: 10px;
    border-radius: 13px;
    width: 88px !important;
}

.statusAtividade.feito {   
    background: $darkGreen;    
}

.statusAtividade.pendente {
    background: $red;
}

.statusAtividade.continuo {
    background: $lightGrey;
}

.statusAtividade.complementar {
    background: $darkGrey;
    width: 108px !important;
}

.statusAtividade.emprogresso {
    width: 125px !important;
    background: $yellow;
    & img {
        margin-bottom: 2px;
    }
}