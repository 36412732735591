@import "../../components/colorPallete.scss";

.configuracao-brasil-mais {
	& span.agrupamento-error {
		color: $red;
		font-family: "Raleway", sans-serif;  
	}

	& .grid-margin-top {
        margin-top: 60px;

        & #empty-list {
        	margin-top: 0px;
        }
    }

    & .MuiTabs-flexContainer {
	    justify-content: center;
		flex-direction: row;
	}

	& .collapse-unidade {
		margin-top: 30px;

		& .auto-complete-field {
			margin-top: -20px;
		}

		& .MuiCollapse-wrapperInner {
			border-right: 1px solid $lighterGrey3;
			border-left: 1px solid $lighterGrey3;
			border-bottom: 1px solid $lighterGrey3;
		}
	}

	& .collapse-bairro {
		margin-top: 30px;
		margin-bottom: -24px;

		& .auto-complete-field {
			margin-top: -20px;
		}

		& .MuiCollapse-wrapperInner {
			border-right: 1px solid $lighterGrey3;
			border-left: 1px solid $lighterGrey3;
			border-bottom: 1px solid $lighterGrey3;
		}
	}

	& .grupo-unidade-chip {
		display: flex;
    	justify-content: space-between;
    	border-bottom: 1px solid $lighterGrey2;
    	margin-top: 20px;
    	& label {
    		margin-left: 25px;
	        color: $darkGrey;
	        font-size: 13px;
	    }

	    & .icon-and-button {
	    	width: 30px;
			height: 10px;
			margin-right: 20px;

			& img {
				max-width: 18px;
				max-height: 18px;
			}
	    }
	}

	& .unidade-container {
		 & .auto-complete-field {
		 	margin-bottom: 10px;
		 }
	}

	& .grid-salvar-container {
		text-align: center;
		margin-top: 60px;
		& button {
			margin: auto;
			width: 40%;
		}
	}

	& .bairro-error {
		margin-top: 10px;
		margin-bottom: -20px;
	}
}