.font-number {
    font-size: 46px;
    font-weight: 700;
    font-family: 'Raleway', sans-serif;
}
.font-text {
    font-size: 15px;
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
}

.font-sector-tools {
    font-size: 21px;
    font-weight: 700;
    line-height: 21px;
    font-family: 'Raleway', sans-serif;
}

