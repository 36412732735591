@import '../../../components/colorPallete.scss';

#dashboard-brasil-mais {
	font-family: 'Raleway', sans-serif; 

	& .icon-btn-dash{
		background-color: #fff;
		border: 1px solid $darkBlue;
		border-right: 0px;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items:center;
		float: left;
	}

	& .tabs{
		& .sticky-bar{
			position: -webkit-sticky;
			position: sticky;
			top: 0;
		} 

		& .buttons-div{
			margin-bottom: 30px;
			display: flex;
			justify-content: flex-start;
			gap: 10px;
			align-items: center;

			& InputSelect{
				width: 80px;
			}
		}

		& .refresh-div{
			margin-top: 20px;
			margin-bottom: 30px;
			padding-right: 24px;
			align-items:flex-end;
			display:flex;
			flex-direction: column;
			
			& .time-label{
			font-size: 15px;
			margin-top: 10px;
			color: $darkBlue;
			}
		}

		
	}

	& .bread-info {
		margin-bottom: 30px;
		font-size: 15px;
		& span {
			color: $darkGrey;
			& b {
				font-weight: 700;
			}
		}
	}
	
	& .dashboard-brasil-mais-geral, .dashboard-brasil-mais-setor, 
	.dashboard-brasil-mais-turmas, .dashboard-brasil-mais-indicadores, 
	.dashboard-brasil-mais-mentores {
		padding-top: 30px;
	}

	& .border-cinza {
		border: 1px solid $lightGrey;
	}

	& .outer-switch {
		margin-top: -50px;
		margin-right: 50%;
		& .switch {
			margin-top: -25px;
		}
	}

	& .border-azul {
		border: 1px solid $darkBlue;
	}

	// CSS do Switch
	& .MuiSwitch-root {
		width: 47px;
		height: 18.5px;
		padding: 0px;
		display: 'flex';
	}

	& .MuiSwitch-switchBase {
		padding: 3px;
		color: $darkBlue;
	}

	& .MuiSwitch-switchBase.Mui-checked {
		padding: 3px;
		color: $darkBlue;
		transform: translateX(29px);
	}

	& .MuiSwitch-switchBase.Mui-disabled {
		color: $lightGrey3 !important;
	}

	& .MuiSwitch-track {
		border-radius: 13px;
		background-color: white;
		border: 1px solid $lightGrey2;
	}

	& .MuiSwitch-thumb {
		width: 12px;
		height: 12px;
	}

	& .carousel {
		& .font-title-carousel {
			font-family: 'Raleway', sans-serif;
			font-weight: 500;
			font-size: 17px;
			color: $darkBlue;
			margin-bottom: 5px;
		}

        & .line {
			border-top: 1px solid $lightGrey;
			border-bottom: 1px solid $lightGrey;
			padding-top: 10px;
			padding-bottom: 29px;
		}

		& .carousel-width {
		    margin: 0 auto;
		    margin-top: 15px;
		}

		& .carousel-width-new{
			margin-left: 50px;
		}
	}

	& .card {
		color: white;
		text-align: center;
		opacity: 1;	

		& .card-top {
			align-self: flex-start;
			flex: 1;
		}

		& .card-mid {
			align-self: center;
			flex: 1;
		}

		& .card-bot {
			align-self: flex-end;
			flex: 1;
		}

		& .card-green {
			padding-top: 10px;
			background-color: $darkGreen;
		}

		& .card-green-new {
			padding-top: 10px;
			background-color: $darkGreen;
			height: 150px !important;
		}

		& .card-orange {
			padding-top: 10px;
			background-color: $orange;
			height: 200px !important;
			width: 100%;
		}

		& .card-blue {
			padding-top: 10px;
			background-color: $lightBlue;
			height: 200px !important;
			width: 100%;
		}

		& .card-dark-blue {
			padding-top: 10px;
			background-color: $darkBlue;
			height: 200px !important;
			width: 100%;
		}

		& .card-dark-blue-title {
			padding-top: 10px;
			padding-bottom: 10px;
			background-color: $darkBlue;
			height: 48px !important;
			width: 100%;
			& .font-title {
				font-family: 'Raleway', sans-serif; 
				font-weight: 500;
				font-size: 14px;
				color: white;
				line-height: 1.8;
				margin:auto;
			}
		}

		& .card-light-gray-tool{
			padding-top: 10px;
			background-color: $lighterGrey;
			font-size: 13px;
		}

		& .card-dark-gray {
			padding-top: 10px;
			background-color: $darkGrey;
		}
		
		& .card-light-gray {
			padding-top: 10px;
			height: 238px !important;
			background-color: $lighterGrey;
			color: $darkBlue !important;
			font-size: 13px;

			& .font-sector {
				font-family: 'Raleway', sans-serif; 
				font-weight: 500;
				font-size: 13px;
				color: $darkBlue;
				line-height: 1.8;
				margin-top: 28px;
				margin-left: 28px;
				margin-right: 28px;
			}
		}

		&.card-carousel {
			padding-right: 24px;
			& .card-dark-gray {
				padding: 15px;				
			}
		}
	}

	.horizontal-bar-graph {
		text-align: left;
		display: flex;
		align-items: center;
		border-bottom: 1.5px solid $lightGrey; 
		height: 56px;
		min-width: 400px;
		width: 100%;
		margin-bottom: 3px;
		padding-top: 8px;
		margin-left: 14px
	}

	& .dashboard-cards {
		text-align: center;
		color: white;
		
		& .card-light-gray {
			min-height: 199px;
			background-color: $lighterGrey;
		}
		& .card-dark-gray {
		  	min-height: 161px;
		  	background-color: $darkGrey;
		}
		& .card-blue {
		  	min-height: 161px;
		 	background-color: $lightBlue;
		}
		& .card-orange {
		  	min-height: 161px;
		  	background-color: $orange;
		}
		& .card-blue-dr {
			min-height: 255px;
			padding-top: 10px;
			background-color: $lightBlue;
		}	
		& .card-orange-dr {
			min-height: 255px;
			padding-top: 10px;
			background-color: $orange;
		}
	}

	& div:focus {
		outline:none!important;
	}

	& .dashboard-brasil-mais-geral {
		& .grafico-meta {
			background: $lighterGrey
		}

		& .dashboard-empty {

			& #empty-list{
				& #div-bigger{
					margin-right: 33%;
					margin-top: 10%;
				}
			}
			
		}

		& .card-label{
			flex-direction: column;
			align-items: center;
			display: flex;
			text-transform: none;
			
			& label {
				flex: 1;
				color: $darkGrey;
				font-family: 'Raleway', sans-serif; 
				font-size: 16px;					
				margin-bottom: 0;
				
			}

			& .bold{
				font-weight: bold;
			}		

		}

		& .card-label-light{
			flex-direction: column;
			align-items: center;
			display: flex;
			text-transform: none;
			
			& label {
				flex: 1;
				color: white;
				font-family: 'Raleway', sans-serif; 
				font-size: 16px;					
				margin-bottom: 0;
				
			}

			& .bold{
				font-weight: bold;
			}
		}

		& .relatorio-box{
			background: $lighterGrey;
			justify-content: center;
			flex-direction: column;
			& .relatorio-label{
				flex-direction: column;
				align-items: center;
				display: flex;
				margin-top: 40px;
				
				& label {
					flex: 1;
                	color: $darkGrey;
					font-family: 'Raleway', sans-serif; 
                    font-size: 14px;					
					margin-bottom: 0;
					
            	}

				& .bold{
					font-weight: bold;
				}		

			}

			& .relatorio-title{
				flex-direction: column;
				align-items: center;
				display: flex;
				margin-top: 40px;
				margin-bottom: 50px;
				
				& label {
					flex: 1;
                	color: $darkBlue;
					font-family: 'Raleway', sans-serif; 
                    font-size: 17px;					
					margin-bottom: 0;
					font-weight: 600;					
            	}

				& button{
					margin-top: 10px;
				}
			}
		}

		& .box-bottom-grafico-meta {
            padding-bottom: 20px;
            
            
 
            & .label-meta {
                & label {
                    font-family: 'Raleway', sans-serif; 
                    font-size: 12px;
                    font-weight: 700;
                }
            }
        }

		& .box-grafico-atendimentos {
			& .label-atds {
				& label {
					font-family: 'Raleway', sans-serif; 
					font-size: 12px;
					color: $darkGrey;
				}
			}
		}

		& .dashboard-porte-setor {
			display: flex;
			justify-content: center;
			justify-items: center;
			align-items: center;
			height: 100%;

			& .highcharts-title{
				display: inline !important;
			}

			& label {
                color: $darkGrey !important;
            }
		}

		& .dashboard-extra-info {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			width: fit-content;
			margin: auto;

			& label{
				font-family: 'Raleway', sans-serif; 
				font-size: 17px;					
				margin-bottom: 0;
				font-weight: 600;
				color: $darkGrey;
				
				& .blue-value{
				color: $darkBlue;
				
				}
			}

			

		}

		& .grafico-cadastrado-concluido, & .box-grafico-atendimentos {
			& label {
				color: $darkGrey;
			}
		}

		& .grafico-atd-prod {
			& label {
				color: $darkGrey;
			}
		}
	}

	& .dashboard-brasil-mais-setor {

		& label {
			color: $darkGrey
		}
		& .dashboard-cards {
			margin-bottom: 40px;
			text-align: center;
			color: white;
			opacity: 1;
			& .card-blue {
			  min-height: 160px;
			  background-color: $lightBlue;
			  color: white!important;			  
			}

			& .card-blue-dr {
				min-height: 185px;
				background-color: $lightBlue;
				color: white!important;			  
			}

			& label {
				font-family: 'Raleway', sans-serif; 
				font-size: 17px;
				font-weight: 500;
				color: $darkBlue;
				padding-top: 10px;
				text-align: center;
			}
		}

		& .setor-table{

			& .blue-row{
				background-color: $lightBlue!important;
				height: 56px;
				& label {
					color: white!important;
				}				
			}
		}

		& .font-title-carousel-title{

			& label {
				font-family: 'Raleway', sans-serif;
				font-weight: 600;
				font-size: 18px;
				color: $darkBlue;
				margin-bottom: 5px;
				text-transform: capitalize;
				margin:auto;
			}
			
		}

		& .font-title-carousel-subtitle{
			margin-bottom: 15px;
			
			& label {
				font-family: 'Raleway', sans-serif;
				font-weight: 500;
				font-size: 16px;
				color: $lightBlue;
				
				margin:auto;
			}
			
		}

		& .carousel {
			& .font-title-carousel {
				font-family: 'Raleway', sans-serif;
				font-weight: 500;
				font-size: 17px;
				color: $darkBlue;
				margin-bottom: 5px;
			}

	        & .line {
				border-top: 1px solid $lightGrey;
				border-bottom: 1px solid $lightGrey;
				padding-top: 10px;
				padding-bottom: 29.5px;
			}

			& .line-top {
				border-top: 1px solid $lightGrey;
				border-bottom: 1px solid $lightGrey;
				padding-top: 10px;
				padding-bottom: 29.5px;
			}

			& .line-bot {
				border-top: 1px solid $lightGrey;
				border-bottom: 1px solid $lightGrey;
				padding-top: 10px;
				padding-bottom: 29.5px;
			}

			& .carousel-width {
			    margin: 0 auto;
			}
		}

		& .box-grafico-glacial {
            align-content: center;
			background: transparent;
			display: flex;
			& label {
				font-family: 'Raleway', sans-serif; 
				font-size: 17px;
				font-weight: 500;
				color: $darkBlue;
				padding-top: 10px;
				text-align: center;
			}

		}	
	}

	& .dashboard-brasil-mais-turmas {

		& label {
            color: $darkGrey
        }

		& .dashboard-cards {
			text-align: center;
			color: white;
			opacity: 1;

			& .card-label-light{
				flex-direction: column;
				align-items: center;
				display: flex;
				text-transform: none;
				
				& label {
					flex: 1;
					color: white;
					font-family: 'Raleway', sans-serif; 
					font-size: 16px;					
					margin-bottom: 0;
					
				}
	
				& .bold{
					font-weight: bold;
				}
			}

			& .card-blue {
			  min-height: 160px;
			  background-color: $lightBlue;
			  color: white!important;
			}

			&.aba-turma-cards {
				& .card-dark-gray {
					height: 238px;
				}

				& .card-blue {
					height: 238px;
					& .font-number {
						font-size: 35px;
					}
				}
				
			}
		}

		& .dashboard-porte-setor {
			display: flex;
			justify-content: center;
			justify-items: center;
			align-items: center;
			height: 100%;

			& .highcharts-title{
				display: inline !important;
			}

			& label {
                color: $darkGrey !important;
            }

			& .dashboard-extra-info {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				width: fit-content;
				margin: auto;
	
				& label{
					font-family: 'Raleway', sans-serif; 
					font-size: 17px;					
					margin-bottom: 0;
					font-weight: 600;
					color: $darkGrey;
					
					& .blue-value{
					color: $darkBlue;
					
					}
				}
	
				
	
			}
		}

		& .font-title-carousel-title{

			& label {
				font-family: 'Raleway', sans-serif;
				font-weight: 600;
				font-size: 18px;
				color: $darkBlue;
				margin-bottom: 5px;
				text-transform: capitalize;
				margin:auto;
			}
			
		}

		& .font-title-carousel-subtitle{
			margin-bottom: 15px;
			& label {
				font-family: 'Raleway', sans-serif;
				font-weight: 500;
				font-size: 16px;
				color: $lightBlue;				
				margin:auto;
			}
			
		}

		& .card-blue-tools {
			min-height: 90px;
			background-color: $lightBlue;
			color: white!important;
		}

		& .card-blue-tools-value {
			min-height: 130px;
			background-color: $lightBlue;
			color: white!important;
		}

		& .card {
			text-align: center;
			& .card-light-gray {
				color: $darkGrey !important;
				text-transform: none;
				font-size: 15px;
				font-weight: 500;
				& .icon-turma {
					margin: auto;
					width: 60px;
					height: 60px;
				}
			}
		}

		@media screen and (min-width: 961px){		 
		    .flex-item {
				flex: 1;
			}
		}

		& .title-painel-turma {
			& .font-title {
				font-family: 'Raleway', sans-serif;
				font-weight: 500;
				font-size: 17px;
				color: $darkBlue;
				margin-bottom: 10px;
				text-align: center;
			}

			& .underline {
				border-bottom: 1px solid $lightGrey;
				margin-bottom: 20px;
			}
		}

		& .button-painel-turma {
			& .yellow {
				border: 1px solid $yellow;
				color: $yellow;
				background-color: transparent;
			}
			& .yellow.clicked {
				color: white;
				background-color: $yellow;
				
			}
			& .green {
				border: 1px solid $darkGreen;
				color: $darkGreen;
				background-color: transparent;
			}
			& .green.clicked {
				color: white;
				background-color: $darkGreen;
			}
			& .blue {
				border: 1px solid $lightBlue;
				color: $lightBlue;
				background-color: transparent;
			}
			& .blue.clicked {
				color: white;
				background-color: $lightBlue;
			}
			& .basic-button {
				width: 100%;
			}
			span {
				font-size: 30px;
				font-weight: 800;
			}
		}

		& .box-painel-turma {
			border: 1px solid $lightGrey;
			border-left: 6px solid $darkBlue;
			font-family: 'Raleway';
			font-weight: 500;
			& .header-box {
				padding: 15px 0 5px 35px;
				width: 100%;
				display: flex;
				border-bottom: 1px solid $lightGrey;
				& .label-turma {
					font-size: 13px;
				}
				& .label-nome-turma {
					font-size: 17px;
					label {
						color: $lightBlue;
					}
				}
			}
			& .status-box {
				background: $yellow;
				font-size: 13px;
				margin-top: -15px;
				height: 35px;
				display: flex;
				align-items: center;
				justify-content: center;
				& label {
					color: white;
				}
			}
			& .status-box.atendimento {
				background: $darkGreen;
			}
			& .status-box.concluida {
				background: $darkGrey;
			}
			& .body-box {
				padding: 30px 0 20px 0;
				& .circle {
					margin-top: 3px;
					background: $lightBlue;
					width: 37px;
					height: 37px;
					font-size: 17px;
					color: white;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				& .circle.min { background: $red; }
				& .circle.max { background: $yellow; }
			}
			& .foot-box {
				margin: 0 auto;
				padding: 30px 0 20px 0;
				border-top: 1px solid $lightGrey;
				background: $lighterGrey;
				font-size: 17px;
				text-align: center;
				& span {
					font-weight: bold;
				}
				& .no-meta {
					label {
						color: $red;
					}
				}
			}
		}
		& .box-painel-turma.atendimento {
			border-left: 6px solid $darkGreen;
		}
		& .box-painel-turma.concluida {
			border-left: 6px solid $darkGrey;
		}
	}

	& .dashboard-brasil-mais-mentores {
        & .card-mentor {
			height: 148px
		}

		& .font-title {
			font-family: 'Raleway', sans-serif;
			font-weight: 500;
			font-size: 17px;
			color: $darkBlue;
			margin-bottom: 10px;
			text-align: center;
		}

        & .line {
			border-top: 1px solid $lightGrey;
			padding-top: 10px;
			padding-bottom: 29px;
		}

		& .tabela th {
			color: $darkGrey !important;
			font-weight: 600;
		}
	}

	& .dashboard-brasil-mais-indicadores {
		& label {
			color: $darkGrey
		}

		& .font-title-indicators {
			font-family: 'Raleway', sans-serif;
			font-weight: 500;
			font-size: 17px;
			color: $darkBlue;
			margin-bottom: 5px;
			text-align: center;
		}

		& .font-title-indicators-subtitle{

			font-family: 'Raleway', sans-serif;
			font-weight: 500;
			font-size: 16px;
			color: $lightBlue;
			margin-bottom: 15px;
			text-align: center;
			margin:auto;		
		}

		& .line {
			border-top: 1px solid $lightGrey;
			padding-top: 10px;
		}

		& .dashboard-porte-setor {
			display: flex;
			justify-content: center;
			justify-items: center;
			align-items: center;
			height: 100%;

			& .highcharts-title{
				display: inline !important;
			}

			& label {
                color: $darkGrey !important;
            }
		}

		& .dashboard-extra-info {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			width: fit-content;
			margin: auto;

			& label{
				font-family: 'Raleway', sans-serif; 
				font-size: 17px;					
				margin-bottom: 0;
				font-weight: 600;
				color: $darkGrey;
				
				& .blue-value{
				color: $darkBlue;
				
				}
			}
		}

		& .indicator-title{
			flex-direction: column;
			align-items: center;
			display: flex;
			
			& label {
				flex: 1;
				font-size: 16px;					
				margin-bottom: 0;
				color: $darkBlue;
				
			}

		}

		
		

	}
	
	& .dashboard-brasil-mais-avaliacao {

		& label {
			color: $darkGrey
		}

		& .page-label{
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: flex-start;
			margin-top: 5px;
		}

		& .card {
			

			& .card-dark-gray {
				height: 100%;
				padding-top: 10px;
				background-color: $darkGrey;
			}
		}

		& .dashboard-cards {

			margin-bottom: 40px;
			text-align: center;
			color: white;
			opacity: 1;
			& .card-blue {
			  min-height: 160px;
			  background-color: $lightBlue;
			  color: white!important;			  
			}

			& .card-blue-dr {
				min-height: 185px;
				background-color: $lightBlue;
				color: white!important;			  
			}

			& label {
				font-family: 'Raleway', sans-serif; 
				font-size: 17px;
				font-weight: 500;
				color: $darkBlue;
				padding-top: 10px;
				text-align: center;
			}
		}

		& .setor-table{

			& .blue-row{
				background-color: $lightBlue!important;
				height: 56px;
				& label {
					color: white!important;
				}				
			}
		}

		& .font-title-carousel-title{

			& label {
				font-family: 'Raleway', sans-serif;
				font-weight: 600;
				font-size: 18px;
				color: $darkBlue;
				margin-bottom: 5px;
				text-transform: capitalize;
				margin:auto;
			}
			
		}

		& .font-title-carousel-subtitle{
			margin-bottom: 15px;
			& label {
				font-family: 'Raleway', sans-serif;
				font-weight: 500;
				font-size: 16px;
				color: $lightBlue;
				margin:auto;
				
			}
			
		}

		& .card-titles-div{
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			width: fit-content;
			margin: auto 25px ;
		}
		& .white-label{
			label{
				margin-bottom: 2px !important;
				color: #FFFFFF !important;
			}
			
		}

		& .carousel {
			& .font-title-carousel {
				font-family: 'Raleway', sans-serif;
				font-weight: 500;
				font-size: 17px;
				color: $darkBlue;
				margin-bottom: 5px;
			}

	        & .line {
				border-top: 1px solid $lightGrey;
				border-bottom: 1px solid $lightGrey;
				padding-top: 10px;
				padding-bottom: 29.5px;
			}

			& .carousel-width {
			    margin: 0 auto;
			}
		}

		& .box-grafico-glacial {
            align-content: center;
			background: transparent;
			display: flex;
			& label {
				font-family: 'Raleway', sans-serif; 
				font-size: 17px;
				font-weight: 500;
				color: $darkBlue;
				padding-top: 10px;
				text-align: center;
			}

		}	
	}

}

@media screen and (min-width: 601px) and (max-width: 1150px) {
	.carousel-width {
	    width: 600px;
	}

	.carousel-width-new{
		width: 500px;
	}
}

@media screen and (min-width: 1151px) and (max-width: 1200px) {
	.carousel-width {
	    width: 950px;
	}

	.carousel-width-new{
		width: 795px;
	}
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
	.carousel-width {
	    width: 1050px;
	}

	.carousel-width-new{
		width: 875px;
	}
}

@media screen and (min-width: 1301px) and (max-width: 1500px) {
	.carousel-width {
	    width: 1150px;
	}

	.carousel-width-new{
		width: 960px;
	}
}

@media screen and (min-width: 1501px) and (max-width: 1800px) {
	.carousel-width {
	    width: 1250px;
	}

	.carousel-width-new{
		width: 1045px;
	}
}

@media screen and (min-width: 1801px) {
	.carousel-width {
	    width: 1600px;
	}

	.carousel-width-new{
		width: 1350px;
	}
}