@import '../colorPallete.scss';

.results-container {
  padding: 0 100px;
  
  .results-header-container {
    border-radius: 8px;
    color: #fff;
    font-family: 'Raleway';
    font-weight: 600;
    font-size: 18px;
    height: 80px;
    
    .results-header-title {
      background-color: $darkGrey;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      height: 100%;
      border-right-style: solid;
      border-right-width: 4px;
      border-right-color: #fff;
      padding: 24px;
      display: flex;
      align-items: center;
    }
    
    .results-header-indicator {
      height: 100%;
      border-right-style: solid;
      border-right-width: 4px;
      border-right-color: #fff;
      background-color: #00B1E8;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .results-header-result {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: #077CDC;
      height: 80px;


      div {
        display: flex;
        flex-direction: column;

        span {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .results-subitems-container {
    border-radius: 8px;
    font-family: 'Raleway';
    font-weight: 600;
    font-size: 18px;
    margin-top: 16px;
    height: 80px;

    .results-subitems-title {
      padding: 24px 16px;
      text-align: right;
      color: $darkGrey;
      font-weight: 500;
      font-size: 15px;
    }

    

    .results-subitems-indicator {
      height: 100%;
      border-right-style: solid;
      border-right-width: 4px;
      border-right-color: #fff;
      background-color: #F2F1F6;
      color: #077CDC;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      &.results-subitems-indicator-first{
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      span {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .results-subitems-result {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: #077CDC;
      color: #fff;
      height: 80px;


      div {
        display: flex;
        flex-direction: column;

        span {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}