@import '../../components/colorPallete.scss';


.table-content {
  width: 100% !important;
}

.label-title{
  color: #707070;
  font: normal normal medium 14px/18px Raleway;
  display: flex;
  align-items: flex-start;
}

.table-avaliacao-atendimento-linha {
  display: grid !important;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr !important;
  min-height: 70px;
  width: 100%;

  .table-avaliacao-atendimento-cell-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .table-avaliacao-atendimento-cell-header-simple {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .table-avaliacao-atendimento-cell-body {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    label {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  .table-avaliacao-atendimento-question-header {
    width: 473px !important;
    // min-width: 400px;
    display: flex;
    align-items: center;
  } 

  .table-avaliacao-atendimento-question {
    color: $darkBlue;
    width: 473px;
  }
}

.row-pair {
  background-color: #F2F1F6;
}
