@import '../../components/colorPallete.scss';

.nova-turma {
	& .botao {
		& button {
			margin-top: 31px;
		}
	}

	& .etapa {
		& span.isInvalid {
			white-space: nowrap;
		}
	}

	& .turma-nova-tabela {
		margin-bottom: 20px;
	}

	& .tabela {
		& .etapa-chip {
			width: 45px;
		}
	}
}