@import './../../components/colorPallete.scss';

.cliente {
    padding: 20px;
    
    & .icon-button img {
        max-width: 17px;
        max-height: 17px;
    }
    & .search img {
        filter: brightness(2.0);
    }

    & div .smaller-font {
    	font-size: 11px;
    }

    & #busca {
        text-transform: uppercase;
    }
}

.container-busca{
    align-items: center;
    display: flex;
    width: 100%;

    .formfield {
        width: 70%;
        margin-left: 1%;
        margin-top: 0%;
    }
}