@import '../colorPallete.scss';

.collapse {
    .MuiGrid-container {
        justify-content: space-between;
    }
}

.MuiExpansionPanelSummary-expandIcon.Mui-expanded {
    color: $darkBlue;
    padding: 5px !important;
}

.MuiExpansionPanelSummary-expandIcon {
    padding: 5px !important;
}

.MuiExpansionPanelSummary-root {
    color: $darkGrey !important;

    &.boxSun {
        border-bottom: 1px solid $lighterGrey2;
    }

    &.Mui-expanded {
        height: 40px !important;
        max-height: 40px !important;
        border-bottom: 1px solid $darkBlue;
        color: $darkBlue !important;
    }
}

.MuiExpansionPanelSummary-content {
    margin: 0px !important;
    width: 100% !important;
}

.MuiExpansionPanel-rounded:first-child, .MuiExpansionPanel-rounded:last-child {
    border-radius: 0 !important;
}

.MuiExpansionPanel-root {
    &.Mui-expanded {
        margin: 0px !important;
    }

    &.Mui-expanded:before {
        opacity: 1 !important;
    }
}

.details {
    background-color: white
}

.details > div {
    width: 100%;
}

.box {
    background-color: $lighterGrey !important;
    box-shadow: none !important;
}

.boxSun {
    height: 40px !important;
    max-height: 40px !important;
    min-height: 40px !important;

    &:hover {
        background-color: $lighterGrey2 !important;
    }
}

.root {
    width: 100%;
    font-size: '15px';
}

.MuiTypography-body1 {
    font-size: 15px !important;
    line-height: 1.0 !important;
}

// MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiExpansionPanelSummary-expandIcon Mui-expanded
