
.container{
    display: flex;
    width: 100%;
    align-items: center;

    .formfield {
        width: 70%;
        margin-left: 1%;
    }
}