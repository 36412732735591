@import "../../components/colorPallete.scss";

.collapse-cicloA3-body-container{

    color: $darkGrey; 
    text-align: left;

    .collapse-note-title{
        left: 658px;
        font-size: 16px;
        margin: 23px;
    }

    .collapse-note-message{
        color: $red;
        font-size: 14px;
        margin: 2%
    }

    .grid-buttons{
        display: flex;
    }

    .grid-collapse-and-button{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .grid-buttons-divide{
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .basic-button-cicloPDCA{
            top: 370px;
            left: 658px;
            width: 95%;
            height: 41px;
            border-radius: 8px;
            font: normal normal 600 16px Raleway;
            margin: 17px 0 0 17px;
            cursor: pointer;

            &:disabled{
                cursor: not-allowed;
            }
            
        }

        .basic-button-cicloPDCA-large{
            top: 370px;
            left: 658px;
            width: 95%;
            height: 71px;
            border-radius: 8px;
            font: normal normal 600 16px Raleway;
            margin: 17px 0 0 17px;
            cursor: pointer;

            &:disabled{
                cursor: not-allowed;
            }
        }
    }
  
    .grid-button-conclude{
        display: flex;
        justify-content: center;
        align-items: center;

        .basic-button-cicloPDCA-conclude{
            top: 673px;
            left: 658px;
            width: 98%;
            height: 41px;
            border-radius: 8px;
            font: normal normal 600 16px Raleway;
            margin: 17px 0 0 17px;
        }
    }

    .render-content-grid-button{
        display: flex;
        justify-content: space-between;
        
        
    }
    
}

.atividade-cicloPDCA{
    display: flex;
    justify-content: 'center';
    
    .label-selected-item{
        color: $darkBlue;
        font: normal normal bold 14px/16px Raleway;
        letter-spacing: 0px;
        text-transform: uppercase;
    }

    .field-label{
        & label{
            color: $darkBlue !important;
            font-size: 14px !important;
        } 
    }
    
}


.grid-forms-countermeasure{

    display: flex;
    width: 100%;
    justify-content: space-between;

    .grid-forms-divide{
        display: flex;
        margin-top: 2%;
    }
}

.grid-forms-responsible-deadline{

    display: flex;
    width: 100%;
    justify-content: flex-start;

    .grid-forms-divide{
        display: flex;
        align-content: left;
    }

    .action-status{
        margin-top: 1%;
        font-weight: 200;
        
        .radio-group {
            width: 400px;
            margin-left: -7%;
            font-weight: 200;
            
            .radio{
                margin-right: 20px;
                font-weight: 200;
            }
         
        }
    }
}


.label-btn-add-collapse{
    background-color: $darkBlue;
    color: white !important;
    align-self: center;
    padding: 6px;
    height: 30px;
    justify-content: flex-start;
}

.collapse-title-temaA3{
    width: 78.8%;
    padding-left: 7%;

    .edit-A3-theme{
        width: 100%;
        height: 20px;
        background-color:$lighterGrey;
        color: $darkBlue;
        border: unset;
        border-bottom: 0.5px solid;


    }
}

.collapse-countermeasure-and-action-plain{
    width: 109%;
    
}


