@import '../colorPallete.scss';

.graphCard {
    padding: 15px;
    text-align: center;
    background-color: $lighterGrey;
    opacity: 1;
}

.font-title {
    font-size: 15px;
    color: $darkBlue;
    font-weight: 500;
    line-height: 13px;
    font-family: 'Raleway', sans-serif;
}

.font-subtitle {
    font-size: 11px;
    color: $darkGrey;
    line-height: 13px;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
}

.font-value {
    font-size: 12px;
    color: $darkGrey;
    line-height: 14px;
}

#graph-column {
    min-height: 199px !important;
    padding-top: 35px;
    & .highcharts-data-table table {
        font-family: 'Raleway', sans-serif;
        font-weight: 500;
        border-collapse: collapse;
        text-align: center;
        height: 100% !important;
        max-width: 500px;
    }

    & .highcharts-drilldown-data-label text{
        text-decoration:none !important;
    }
}

.graph-column-geral-height {
    & .graphCard {
        min-height: 255px;
    }
    & #graph-column {
        min-height: auto!important;
        padding-top: unset;
    }
}



