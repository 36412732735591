@import '../../components/colorPallete.scss';


.gerenciar-atendimentos {
    font-family: "Raleway", sans-serif;
    color: $darkGrey;
    font-size: 15px;
    
    & b {
        font-weight: 700;
    }
    & h1 {
        font-size: 25px;
        color: $darkBlue;
    }
    & h2 {
        font-size: 25px;
        color: $darkBlue;
        font-weight: 300;
        margin: 0 0 8px 0;
    }
    & h5 {
        font-size: 16px;
        color: $darkBlue;
        font-weight: 300;
        margin: 0 0 8px 0;
    }
    & .greyDiv {
        background-color: $lighterGrey;
        padding: 16px;
        height: 100%;
        word-wrap: break-word;
        & .blue-label {
            color: $darkBlue;
            font-size: 12px;
            font-weight: 500;
        }
        & .grid-chip-file:hover {
            cursor: pointer;
        }

    }
    & .darkBlue.basic-button {
        margin-right: 16px;
    }
    & .grid-left-button {
        & .formField {
            max-width: 400px;
        }
        & .total {
            margin-left: 24px;
            font-size: 15px;
        }
        & .busca-avancada {
            width: 100px;
        }
    }
    & .pesquisa {
        & .total{
            text-align: right;
            color: $darkBlue;
            font-size: 15px;
            margin-left: 16px;
            & b {
                font-weight: 700;
            }
        }
        & .formField {
            max-width: 450px;
            min-width: 300px;
        }
    }
    & .grid-right-button .white {
        border: 1px solid $lightBlue;
        margin-bottom: 10px;
    }
    & .pagination {
        display: flex;
        padding: 8px 0px;
        align-items: center;
        float: right;
        color: $darkGrey;
        font-size: 11px;
        font-family: "Raleway", sans-serif;
        & .white {
            border: 1px solid white;
            margin: 0px 4px;
            & img {
                filter: saturate(0);
            }
        }
        & .left {
            -webkit-transform:rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            transform: rotate(-90deg);
        }
        & .right {
            -webkit-transform:rotate(90deg);
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            transform: rotate(90deg);
        }
        & p {
            color: $darkGrey;
            padding-right: 15px;
        }

    }

    .atendimento {
        display: flex;
        justify-content: center;
        width: 85%;
        margin: 0 20px;

        & .resumo {
            & .MuiGrid-item {
                padding: 4px;
            }
            & .box-change-status {
                & .MuiGrid-item  {
                    padding: 8px;
                }

                & .darkBlue.basic-button {
                    float: right;
                    margin: 0;
                }

                #box-parecer-dn {
                    border: solid 2px transparent;
                    transition: all 0.4s ease-in;
                }
            }
            & h3 {
                font-size: 18px;
                color: $darkBlue;
                font-weight: bold;
                border-bottom: 1px solid $darkBlue;
                padding: 0px 0px 8px 0px;
            }
            & .container-info {
                margin-bottom: 6px;
            }
            & .label-info {
                font-size: 13px;
                padding-right: 8px;
            }

            & .dados-complementares-item {
                margin-top: 50px;
                display: inline-flex;
                & .label-obrigatorio {
                    margin-top: 28px;
                    margin-left: 15px;
                    font-size: 13px;
                    color: $darkBlue;
                }
                & .darkBlue.basic-button {
                    float: right;
                    margin: 0px;
                }
            }            
        }
        & .acompanhamento {
            font-size: 13px;
            & .darkBlue.basic-button {
                float: right;
                margin: 0px;
            }
            & .lightBlue.basic-button{
                float: right;
                margin-right: 15px;
                &.button-brasil-mais-lean {
                    margin-right: unset;
                }
            }
            & a {
                color: $darkGrey;
                margin-right: 8px;
            }
            & p {
                margin: 4px 0px;
            }
        }
    }
}

.participante-list-text span{
    font-family: "Raleway", sans-serif;
    color: $darkGrey;
    font-size: 14px; 
}

.modal-atendimento-filtro {
    & .darkGrey-outline, .darkBlue-outline {
        margin-right: 16px;
    } 
    & .auto-complete-many>label {
        color: $darkBlue
    }

    &.auto-complete-many span {
        margin-top: unset;
    }
    
    & .status {
        & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
            content: ' \25CF';
            font-size: 16px;
            padding-right: 8px;
        }
        & .aceito, & .emexecucao {
            & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
                color: $darkGreen
            }
        }
        & .pronto, & .negociacao {
            & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
                color: $yellow
            }
        }
        & .concluido {
            & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
                color: $darkBlue
            }
        }
        & .recusado {
            & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
                color: $red
            }
        }
        & .cancelado {
            & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
                color: $orange
            }
        }
        & .arquivado {
            & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
                color: $darkGrey
            }
        }
        & .emelaboracao {
            display: none
        }
    }
}



.modal-historico-producao {
    & .colaborador {
        padding: 8px 0px;
        border-bottom: 1px solid $lighterGrey;
        align-items: center;
    }
    & .horas {
        text-align: right;
        font-weight: 700;
    }
    & .inputSelectDiv {
        width: 100px;
        margin: 0px 4px 8px 4px;
    }
}


.modal-detahe-visita-header {
    border-bottom: 1px solid #B4B8C0 !important;
    margin-bottom: 8px;
}

.margin-item-modal-detalhes {
    margin: 8px 0;
}



.modal-pesquisa {
    & b {
        font-weight: 700;
    }
    & .formField {
        margin: 0;
        & label {
            font-size: 13px;
        }
    }
    & .question {
        display: flex;
        font-size: 13px;
        color: $darkBlue;
        & label {
            margin-right: 8px;
        }
    }
    & .labels {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
    }
    & .input .formField {
        width: 50px;
        
    }
    & .pesquisa-body {
        margin-bottom: 32px;
        & .line {
            height: 50px;
            & .sub-question {
                font-size: 13px;
                display: flex;
                align-items: center;
                padding-left: 24px;
                & img {
                    max-width: 15px;
                    max-height: 15px;
                    margin-right: 8px;
                }
            }
            & .radios {
                display: flex;
                justify-content: space-around;
                align-items: baseline;
            }
        }
    }
    & .importancia {
        & span {
            text-align: center;
        }
        display: flex;
    }
    & .comment {
        margin-bottom: 30px;
    }
}
.modal-entrega {
    & .darkBlue.icon-button {
        margin-bottom: 10px;
    }
}

//Botão Adicionar Anexos
.control-label {
    display: block;
    width: 130px;
    height: 30px;
    margin: 8px 0px;
}
//Botão Adicionar Comprovante
.control-label-comprovante {
    display: block;
    width: 175px;
    height: 30px;
    margin: 8px 0px;
}
.file-input {
    display: none;
}

//Change Status
.change-status { 

    & .solicitacaoStatus {
        color: $darkGrey;
    }
    
    & .conforme:before, & .conformeIA:before, & .conformeIPEA:before, 
    & .conformeIE:before, & .conformeLEAN:before, & .conformeIA180:before, & .conformeDIGITAL:before {
        color: $darkGreen
    }
    & .naoconforme:before, & .naoconformeIA:before, & .naoconformeIPEA:before, 
    & .naoconformeIE:before, & .naoconformeLEAN:before, & .naoconformeIA180:before, & .naoconformeDIGITAL:before{
        color: $red;
    }
    & .analise:before, & .analiseIA:before, & .analiseIPEA:before, 
    & .analiseIE:before, & .analiseLEAN:before, & .analiseIA180:before, & .analiseDIGITAL:before{
        color: $yellow;
    }
    .solicitacaoStatus:before {
        content: ' \25CF';
        font-size: 16px;
        padding-right: 8px;          
    }
}
.input-select-options.change, .modal-change-status{
    & label:before {
        content: ' \25CF';
        font-size: 16px;
        padding-right: 8px; 
    }
    & .concluido:before {
        color: $darkBlue
    }
    & .cancelado:before {
        color: $orange
    }
    & .arquivado:before {
        color: $darkGrey
    }
    & .recusado:before  {
        color: $red;
    }
    & .aceito:before, & .emexecucao:before {
        color: $darkGreen
    }
    & .recusado:before  {
        color: $red;
    }
    & .pronto:before, & .negociacao:before {
        color: $yellow;
    }

    & .justificativa {
        & label:before {
            content: none;
        }

        & .inputSelectDiv {
            & label {
                padding-top: 20px;
                font-weight: 400;
                color: $darkGrey;
            }
        }
    }
} 
.MuiTableCell-root {
    & .concluido:before, 
    & .cancelado:before, 
    & .arquivado:before, 
    & .recusado:before, 
    & .aceito:before, 
    & .emexecucao:before, 
    & .recusado:before, 
    & .pronto:before, 
    & .negociacao:before {
        content: ' \25CF';
        font-size: 16px;
        padding-right: 8px; 
    }

    & .concluido:before {
        color: $darkBlue
    }
    & .cancelado:before {
        color: $orange
    }
    & .arquivado:before {
        color: $darkGrey
    }
    & .recusado:before  {
        color: $red;
    }
    & .aceito:before, & .emexecucao:before {
        color: $darkGreen
    }
    & .recusado:before  {
        color: $red;
    }
    & .pronto:before, & .negociacao:before {
        color: $yellow;
    }
}
.modal-change-status {
    & .MuiGrid-container {
        flex-direction: column;
        align-items: center;
    }
    & label {
        font-weight: 700;
        text-align: center;
    }
    & .font-red {
        text-align: center;
    }
    & p {
        font-size: 17px;
        text-align: center;
        margin: 20px 0px;
    }
}
.flex-column {
    display: flex;
    flex-direction: column;
}
.tabela-proposta {
    min-height: 200px;
}

.tipoUnidadeParametro {
    font-size: 10px;
    color: #fff;
    background-color: #b2b9bf;
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
    margin-right: 8px;
}

.modal-indicadores.modal-pai .formField .input label, .modal-pai .autoCompleteField label, .modal-pai .inputSelectDiv label {
    font-size: 14px;
}

.observacao-dn {
    border: 1px solid $lighterGrey3;
    & h2 {
        font-size: 13px;
        font-weight: bold;
    }
}


.registro-indicadores, .registar-anexo {
    align-items: center;
    width: 100%;
    & .arrow-indicadores { margin-bottom: 2px; }

    & .lbl-arrow {
        font-size: 13px;
        justify-content: center;
        .left { color: $lighterGrey3; }
        .right { color: $darkGrey; }
        font-weight: 600;
    }
    & .nome-anexo-indicadores {
        color: $darkGrey; 
        font-weight: 600;
    }

    & .upload {
        transform: rotate(180deg);
    }
    & .darkBlue .basic-button {
        color: $darkBlue; 
        float: right;
        margin: 0px;
        text-align: center;  
    }
}

.tooltip-indicadores {
    position: relative;
    display: inline-block;
}
  
.tooltip-indicadores .formula {
    visibility: hidden;
    background-color: white;
    width: 200px;
    color: $darkGrey;
    box-shadow: 3px 3px 10px $lightGrey2;
    padding: 5px 5px;
    position: absolute;
    margin-top: -4px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;

    & .icon-label {
        margin-left: 5px;
        color: $lighterGrey3;
        font-weight: 600;
    }
}

.tooltip-indicadores:hover .formula {
    visibility: visible;
    opacity: 1;
}

.chip-anexo-referencial {
    margin: 0 auto;
    label {
        display: flex;
        align-items: center;
        padding-right: 10px;
    }
}

.avaliar-anexo-referencial, .indicadores-avaliados {
    label {
        font-size: 13px;
        padding: 7px 15px;
    }
}

.upload-anexo-referencial {
    & .basic-button {
        padding: 7px 30px;
    }
    label {
        color: white;
        font-size: 14px;
    }
}


.line-historico-producao {
    padding-bottom: 4px;
    border-bottom: 1px solid $lighterGrey2;
}


.erro-modal-historico-producao {
    margin-top: 12px !important;
    margin-left: 4px !important;
    small {
        color: $red;
    }
}
