@import '../../components/colorPallete.scss';


.tabs-analysis {
  padding: 32px 260px 0 160px; 

  .tabs-analysis-item {
    margin-bottom: 32px;
  }
}

.tabs-results {

  .divider {
    width: 100%; 
    height: 1px; 
    background-color: #B4B8C0; 
    margin: 16px 0 32px 0;
  }

  .tabs-results-payback-container {

    .tabs-results-payback-title {
      color: #00B1E8;
      font-size: 16px;
    }

    .tabs-results-payback-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      background-color: #F2F1F6;

      padding: 16px;
      border-radius: 2px;

      margin-top: 8px;

      .tabs-results-payback-months {
        color: #077CDC;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}