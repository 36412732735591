@import '../../components/colorPallete.scss';

.cliente {
	& div .box {
		width: 254px;
		height: 130px;
		border: 1px solid $lighterGrey3;
		margin-right: 10px;
		display: table;
		opacity: 1;
		background: $lighterGrey;
		color: $darkGrey;
		padding: 12px;
		margin-bottom: 10px;

		& label {
			font-weight: bold;
		}

		& hr {
			border: 1px solid $lighterGrey3;
			opacity: 1;
		}
	}

	& div .box-grid {
		display: flex;
		margin-top: 10px;
		flex-wrap: wrap;
		margin-bottom: 10px;
	}

	& div .box-grid-review {
		font-size: 13px;
	}

	& div.box-grid-review > div.box {
		background: white;

		& label > span.material-icons.MuiIcon-root#endereco, label > span.material-icons.MuiIcon-root#contato {
			display:none;
		}
	}

	

	& .stepper span.material-icons.MuiIcon-root#endereco, .stepper span.material-icons.MuiIcon-root#contato {
		color: $red;
		float: right;
		cursor: pointer;
	}

	& .MuiIcon-root {
    	font-size: 1.0rem;
    }

    & .tipo-estrangeiro {
    	color: $darkGrey;
    	text-align: center; 
    	font-size: 15px;
    }

    & .input-many-cliente span {
    	margin-top: unset;
    }

    & .grid-porte {
    	& .formField {
    		margin-bottom: unset;
    	}
    }
    & .span-porte {
    	color: $darkGrey;
		font-size: 13px;
		margin-top: 5px;
		font-weight: 600;
		display: flex;
		margin-bottom: 10px;
    }
}