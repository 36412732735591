@import './../../components/colorPallete.scss';

.novo-atendimento {
	& .recursos-container .darkBlue {
		pointer-events: none;
		width: 100%;
    }
    .boxSun {
        color: $darkGrey;
        & .Mui-expanded {
            color: $darkGrey;
        }
    }
    
}

.pagination-gerenciar-atendimento {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 11pt;
    color: $darkGrey;
    margin-bottom: 50px;

    .return-gerenciar-atendimento {
        display: flex;
        align-items: center;
        width: 106%;
        cursor: pointer;
    }
    & p {
        color: $darkGrey;
        margin-right: 2%;
        padding-right: 10px;
    }
}

.modal-pai.modal-atendimento-cliente .modal-filho {
    padding: 0;
}

.producaoAlign {
    margin-top: 15px;
}

span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label{
    color: $darkGrey;
    font-family: "Raleway", sans-serif;
}


.label-field {
    font-size: 15px;
    color: $darkGrey;
    font-family: "Raleway", sans-serif;
}

.input-entidade {
    margin-bottom: 5%;
}

.align-finance-economic {
    display: flex;
    justify-content: space-between;
    padding-top: 2px;
    padding-bottom: 2px;
    & label {
        margin-right: 20%;
    }
}

.padding-radio {
    padding-bottom: 2%;
}

.radio-group-fonte {
    justify-content: space-between;
}

.radio-group-parceria {
    align-items: center;

    .radio {
        margin-left: -40px;
        margin-right: 100px;
    }
}

.linha-atendimento {
    margin-top: 1%;
    margin-bottom: 1%;
    .radio-group {
        width: 720px;
        margin-left: -20px;
        .radio {
            margin-right: 150px;
        }
        .radio-Especial {
            margin-left: 15px;
        }
    }
}

.radio-group-change-status {
    .radio {
        width: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
            height: 20px !important;
            width: 15px !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.total-de-recursos {
    display: flex;
    justify-content: space-between;
    padding-right: 60px;
    align-items: center;
    
    &:first-child {
        border-bottom: 1px solid $lightBlue;
    }
    
    p {
        color: $darkBlue;
        font-size: 11pt;
        font-weight: 500;
    }


    h5 {
        color: $darkGrey2;
        margin-left: 12px;
        margin-right: 40px;
        font-size: 14px;
    }

    
}

.review-de-recursos {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;

    b {
        font-size: 12px;
        color: $darkGrey2;
        font-weight: bold;
        margin-left: 2px;
    }

    .display-fonte {
        padding-bottom: 10px;

        .count-fonte {
            display: flex;
            width: 300px;
            align-items: center;
            color: $darkGrey2;
        
            p {
                font-size: 13px;
                color: $darkGrey2;
            }
        
            .count {
                font-size: 13px;
                padding-right: 5px;
            }
        }

        .count-fonte-review {
            display: flex;
            width: 500px;
            align-items: center;
            color: $darkGrey2;
            margin-bottom: -10px;
        
            p {
                font-size: 8pt;
                color: $darkGrey2;
            }

            .count {
                font-size: 8pt;
            }
        }

        &.horas {
            display: flex;
            width: 100%;
            align-items: center;
            h4 {
                width: 50%;
                font-weight: normal;
                &:last-child {
                    text-align: center;
                }
            }
        }
    }

    .display-total {   
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 200px;
        margin: 0;

        .total {
            width: 100px;
            margin-right: 25px;
            color: $darkGrey2;
        }

        .icon-atend {
            cursor: pointer;
            margin-top: 5px;
            width: 15px;
            height: 15px;
            margin-left: 20px;
        }
    }

    .display-total-review {
        display: flex;
        align-items: center;
        margin-right: 63px;

        .total-review {
            width: 150px;
            color: $darkGrey2;
            font-size: 12pt;
        }
    }
}

.review-border {
    border-bottom: 1px solid $darkGrey3;
}

.modal-fonte {
    width: 800px;
    height: 800px;

    .container-modal-fonte {
        width: 90%;
        padding: 0 25px 0 25px;
        margin-left: 30px;
        margin-right: 30px;
    }

    & h2 {
        font-size: 18pt;
        font-weight: 500;
        text-align: center;
        color: $darkBlue;
        margin-bottom: 50px;
    }

}

.modal-confirmacao {
    width: 600px;
    height: 400px;

    .container-modal-confirmacao{
        width: 90%;
        margin: 0 auto;

        label {
            color: $darkGrey !important;
        }

        .status-fields {
            margin-top: 20px;
        }
    }

    & h2 {
        font-size: 18pt;
        font-weight: 500;
        text-align: center;
        color: $darkBlue;
        margin-bottom: 50px;
    }
}

.modal-parceria {
    width: 800px;
    height: 600px;

    .container-modal-parceria {
        width: 90%;
        padding: 0 25px 0 25px;
        margin-left: 30px;
        margin-right: 30px;
    }

    & h2 {
        font-size: 18pt;
        font-weight: 500;
        text-align: center;
        color: $darkBlue;
        margin-bottom: 50px;
    }
}

.container-parceria {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 30px;

    .review-producao {
        margin-left: -20px;
        width: 310px;
        b {
            margin-bottom: 0;
            font-weight: 700;
            color: $darkGrey;
            font-size: 9pt;
        }
        p {
            font-size: 11pt;
        }
    }
}

.producao-review-atendimento-novo {
    margin: 16px 0 0 46px;

    p {
        margin-right: 8px;
    }
}

.container-review {
    margin-left: 40px;
    width: 100%;
    margin-bottom: 20px;

    b {
        font-weight: 600;
    }

    .layout-review {
        align-items: center;
        display: flex;

        h2 {
            width: 400px;
            font-size: 9pt;
            margin-right: 90px;
        }
        
        h5 {
            width: 400px;
            font-size: 9pt;
            margin-right: 180px;
        }

        h4 {
            margin-right: 35px;
            font-weight: 400;
            font-size: 12pt;
        }

        b {
            margin-left: 10px;
            font-size: 10pt;
            padding: 0;
            width: 200px;
            color: $darkGrey2;
        }

        p {
            color: $darkGrey2;
            font-size: 10pt;
        }
    }

    .bloco-etapa {
        background-color: $darkBlue;

        b {
            color: white;
        }
    }
}

.box-responsavel-e-opcional {
    display: flex;
    margin-top: 20px;
    margin-bottom: 50px;
    justify-content: space-between;
}

.recursos-list {
	padding-top: 25px;
}
.cliente-tip {
    font-weight: 700;
    font-size: 15px;
    color: $darkGrey;
}

.review-info {
    background-color: $lighterGrey;
    color: white;
    border: 1px solid $darkGrey3;
    padding-bottom: 5px;
    padding-left: 15px;

    & section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        & span {
            margin-right: 5px;
        }

        & h6 {
            font-size: 9pt;
            margin-top: 5px;
            margin-bottom: 0;
        }
    }


    & p {
        margin: 0;
        font-size: 8pt;
        color: $darkGrey2;
    }
}

.auto-complete-many input,
.inputSelectDiv .selectOptions {
    text-transform: initial;
    font-style: italic;
}

.input-indicadores .auto-complete-many input,
.input-indicadores .inputSelectDiv .selectOptions {
    text-transform: initial;
    font-style: italic;
    min-width: 400px !important;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: #077CDC !important;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0 !important;
    border-radius: 0;
    background-color: #F2F1F6;
}

.MuiGrid-item label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #707070;
}

.MuiAutocomplete-tag,
.MuiAutocomplete-clearIndicator,
.MuiAutocomplete-endAdornment .MuiIconButton-label {
    display: none !important;
}

.areaPesquisa {
    margin-top: 10px !important;
}

.areaPesquisa label {
    display: block !important;
}

.areaPesquisa .MuiInputBase-input {
    background: url('../../assets/icons/searchgray.svg') center no-repeat !important;
    background-size: 20px !important;
    background-position-x: 99% !important;
}

.areaPesquisaSelectedTags {
    margin: 10px 0;
    display: flex;
    .tags {
        color: $darkGrey;
        font-size: 14px;
    }
}

.areaPesquisaSelectedTags p {
    padding: 7px 12px;
    border: 1px solid #ABB4BE;
    background-color: #F2F1F6;
    margin-right: 15px;
}

.areaPesquisaSelectedTags p:last-child {
    margin-right: 0;
}

.areaPesquisaSelectedTags p span {
    cursor: pointer;
    margin-left: 10px;
    font-size: 12px;
}

.MuiAutocomplete-listbox:not(li) {
    // color: #077CDC !important;
}

.input-select-options.change, .modal-change-status{
    & label:before {
        content: ' \25CF';
        font-size: 16px;
        padding-right: 8px; 
    }
    & .concluido:before {
        color: $darkBlue
    }
    & .cancelado:before {
        color: $orange
    }
    & .arquivado:before {
        color: $darkGrey
    }
    & .recusado:before  {
        color: $red;
    }
    & .aceito:before, & .emexecucao:before {
        color: $darkGreen
    }
    & .recusado:before  {
        color: $red;
    }
    & .pronto:before, & .negociacao:before {
        color: $yellow;
    }

    & .justificativa {
        & label:before {
            content: none;
        }

        & .inputSelectDiv {
            & label {
                padding-top: 20px;
                font-weight: 400;
                color: $darkGrey;
            }
        }
    }

    & label.no-style:before {
        content: none;
        padding-right: 0; 
        
    }
    
    & label.no-style {
        width: 160px !important;
        max-width: 150px !important;
        padding: 8px 8px;
        margin-right: 0;
        margin-top: 16px;
        margin-left: 0px;
        margin-bottom: 16px;
        color: #fff !important;
    }
} 

.button-wrapper {
    position: relative;
    width: 180px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .button-wrapper span.label {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: $darkBlue;
    cursor: pointer;
    color: #fff;
    padding: 10px 0;
    text-transform:uppercase;
    font-size:12px;

    svg {
        margin-left: 5px;
        margin-bottom: -4px;
    }
  }
  
  #upload {
      display: inline-block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 50px;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
  }
  .file-name {
      color: $darkBlue ;
  }


.producao-header-grid {
    color: $darkBlue;
    border-bottom: 1px solid $lightBlue;
    margin-bottom: 16px !important;
}

.container-propostas-anexadas {
    width: 500px;

    .spacing {
        margin-top: 20px;
    }

    .boxPropostaAnexadas {
        width: 50%;
        height: 30px;
        display: flex;
        align-items: center;
        // margin-top: -8px;
    }
}
