@import "../../components/colorPallete.scss";

.checkbox-status {
    & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
        content: ' \25CF';
        font-size: 12px;
        margin-right: 4px;
    }
    & div:nth-child(1) {
        & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
            color: $darkGreen!important
        }
    }
    & div:nth-child(2) {
        & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
            color: $darkBlue!important
        }
    }
    & div:nth-child(3) {
        & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
            color: $orange!important
        }
    }
}
.checkbox-divergencia {
    & div:nth-child(1) {
        & span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label:before {
            color: $red!important;
            content: ' \25CF';
            font-size: 12px;
            margin-right: 4px;
        }
    }
}

#divergencia {
    & .circle:hover .tooltip {
        opacity: 1 !important;
    }
    & .bolinhas {
        display: flex;
        margin-left: 41px;
        justify-content: space-around !important;
        padding: 0 18px !important;
    }
    & .categoria {
        display: table-cell;
        font-family: 'Raleway', sans-serif;
    }
    & .icone {
        vertical-align: top;
        margin-top: 17px;
        margin-left: 3px;
        position: relative;
        font-style: normal;
        color: $red;
    }
    & .icone > * {
        color: black;
      }
  }
  
  i.circle.after.icone:before {
    content: " \25CF";
    font-size: 10px;
  }
  
  .square {
    width: 13px;
    height: 13px;
    background-color: #077cdc;
    margin: 10px;
  }
  
  .rectangle {
    width: 4px;
    height: 15px;
    background-color: $darkBlue;
    margin: 0 10px 0 2px;
  }
  .divergencia-lightblue {
        .rectangle {
            background-color: $lightBlue3;
        }
    }
  
  #divergencia .tooltip {
    width: auto;
    height: auto;
    background-color: white;
    position: absolute;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    max-width: 300px;
    opacity: 0;
    pointer-events: none;
    top: 15px;
    left: -4px;
    text-align: center;
    padding: 5px;
    min-width: max-content;
  }
  
  .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
    filter: alpha(opacity=0);
    opacity: 0;
  }
  
  #divergencia > div > div,
  #divergencia .highcharts-container {
    overflow: visible !important;
  }
  
  #divergencia #chart1 .highcharts-container {
    z-index: 10 !important;
  }
  #divergencia #chart2 .highcharts-container {
    z-index: 9 !important;
  }
  #divergencia #chart3 .highcharts-container {
    z-index: 8 !important;
  }
  #divergencia #chart4 .highcharts-container {
    z-index: 7 !important;
  }
  #divergencia #chart5 .highcharts-container {
    z-index: 6 !important;
  }
  #divergencia #chart6 .highcharts-container {
    z-index: 5 !important;
  }
  span b {
    font-style: bold;
  }
  #chart1, #chart2 , #chart3, #chart4, #chart5, #chart6{
      & div:nth-child(1){
          overflow: visible!important;
      }
  }
  .legenda {
    background-color: white;
    padding-left: 20px;
    align-self: flex-end;
    // margin-bottom: 24px !important;
    padding-bottom: 24px;
    z-index: 85;
    height: 100%;
    display: flex;
    align-items: flex-end;
    & i.circle.after.icone:before {
        font-size: 13px;
    }
  }
  
  .dashboard-header {
    height: 2px;
    background: $lightBlue;
    border: unset;
    opacity: 1;
  }
  
  .dashboard-header-label {
    text-align: left;
    font: Bold 19px/22px Raleway;
    letter-spacing: 0;
    color: $darkBlue;
    opacity: 1;
    & span {
        cursor: pointer;
        &:hover{
            color: $lightBlue3;
        }
    }
  }
  
  .divergencia-uo-container .font-blue {
    font-size: 13px;
  }
  .atendimentos-divergencia .font-blue {
    font-size: 15px;
  }
  
  .legenda-divergencia {
    background-color: #f2f1f6;
    align-items: center;
    font-size: 15px;
    font-family: 'Raleway', sans-serif;
    color: $darkGrey;
    & div {
      display: flex;
      align-items: center;
      & i {
        color: $red;
        padding-bottom: 2px;
        margin-right: 9px;
      }
    }
  }  
  
  .legenda .legenda-divergencia {
    background-color: unset;
    font-size: 15px;
    color: #707070;
    font-weight: 600;
  }
  
  .legenda .legenda-divergencia span {
    font-weight: normal;
  }
  
  #divergencia .legenda .legenda-divergencia i.circle.after.icone {
    margin-top: 0px;
  }
    .tabela-atendimentos-divergencia {
        & .ACEITO:before, & .CONCLUÍDO:before, & .CANCELADO:before {
            content: " \25CF";
            font-size: 10px;
            margin-right: 8px;
            vertical-align: text-top;
        }
        & .ACEITO:before {
            color: $darkGreen!important
        }
        & .CONCLUÍDO:before {
            color: $darkBlue!important
        }
        & .CANCELADO:before {
            color: $orange!important
        }
    }
  
  .tabela-atendimentos-divergencia table.tabela .MuiTableRow-head th:last-child,
  .tabela-atendimentos-divergencia table.tabela tbody .MuiTableRow-root td:last-child {
    text-align: center;
  }
  
  .MuiGrid-root.MuiGrid-container #divergencia {
    padding-bottom: 80px !important;
  }
  g.highcharts-axis-labels.highcharts-yaxis-labels {
    font-family: 'Raleway', sans-serif;
  }