@import "../../components/colorPallete.scss";

.container-expand {
  .container-expand-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}

.expand-icon-etapa-blue {
  height: 49px;
  width: 49px;
  background: $darkBlue;
  border-radius: 50%;
  position: absolute;
  padding-left: 18px;
  padding-top: 12px;
  & span {
      font: 21px/25px Raleway;
      text-align: left;
      color: white;
  }
}

.expand-header {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 100px !important;

}

.expand-icon-fase {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px !important;
}

.expand-icon-info {
  label {
    color: #B4B8C0;
    font-size: 12px;
  }

  h1 {
    margin: 0;
    font: 25px Raleway;
    color: #077CDC;
  }
}

.expand-carousel-container {
  margin-top: 32px;
  margin-bottom: 32px;
  justify-content: center;
}

.modal-filho {
  padding: 32px 0 !important;
}

.expand-modal-content {
  background-color: #F2F1F6;
}

.expand-modal-description {
  padding: 8px 16px;

  p {
    font: 13px Raleway;
    color: #707070;
    text-align: left;
  }
}

.expand-modal-image {
  width: 763px;
  height: 500px;
  object-fit: contain;
}