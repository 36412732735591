@import './../../components/colorPallete.scss';

.gerenciar-colaborador {
    padding: 20px;
    
    & .icon-button img {
        max-width: 17px;
        max-height: 17px;
    }
    & .search img {
        filter: brightness(2.0);
    }
}
.gerenciar-colaborador th:nth-child(2), .gerenciar-colaborador td:nth-child(2){
    min-width: 200px!important;
}
.gerenciar-colaborador td:nth-child(9) {
    display: none;
}

.container-busca{
    display: flex;
    width: 100%;
    align-items: center;

    .formfield {
        width: 70%;
        margin-left: 1%;
        margin-top: -1.2%;
    }
}