@import "./../../components/colorPallete.scss";

.mainContentPage {
  height: 94.6vh;
  font-family: "Raleway", sans-serif;

  & .contentSearch {
    display: flex;
    justify-content: left;
    width: 70vw;
    height: 4vh;
    align-items: center;
  }

  & .contentSearchFree {
    width: 60vw;
  }

  & .contentButtons {
    display: flex;
    margin-left: 2%;
    width: 28vw;
    align-items: center;
    justify-content: left;
  }

  & .contentInformations {
    display: flex;
    margin-top: 1%;

    & .contentGridInformationDetail {
      margin-right: 2%;
      width: 46vw;

      & .unityTitle {
        margin-top: 3%;
        font-size: 18pt;
        color: $darkGrey;
      }

      & .labelTitle {
        color: $darkBlue;
        font-size: 16pt;
        font-weight: 800;
        margin: 0;
        margin-top: 2%;
      }

      & .legendTitle {
        color: $darkBlue3;
        font-size: 12pt;
        font-weight: 100;
        margin: 0;
        margin-top: 2%;
        margin-bottom: 1%;
      }

      & .fieldAlignmentUnities {
        display: flex;
        justify-content: space-between;

        & .labelUnities {
          color: $darkGrey;
          font-size: 10pt;
          font-weight: 600;
        }

        & .infoUnities {
          color: $darkGrey;
          font-size: 10pt;
          font-weight: 200;
        }

        & .unityNameIsis {
          margin-top: 1%;
          margin-bottom: 1%;

          & .nameISI {
            color: $darkGrey;
            margin: 0;
            text-align: right;
            font-size: 11pt;
            font-weight: 200;
          }
        }
      }
    }

    & .contentGridAttendanceDetail {
      margin-left: 1%;

      & .contentBlockGeneral {
        background-color: $lighterGrey;
        padding: 0.1vh 1.4vw 0.1vh 1.4vw;
      }

      & .labelTitle {
        color: $darkBlue;
        font-size: 18pt;
        font-weight: 800;
        margin: 0;
        margin-top: 4%;
        margin-bottom: 4%;
      }

      & .contentGeneralAttendance {
        border-radius: 4px;
        margin: 0;
        padding: 0;
        margin-top: 1%;
        margin-bottom: 2%;

        & .labelContent {
          color: $darkBlue;
        }

        & .fieldAlignmentStatusAttendance {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 3.2vh;
          width: 43.6vw;

          & .statusAttendance {
            color: $darkGrey;
            font-size: 10pt;
            font-weight: 600;
          }

          & .counterStatusAttendance {
            color: $darkGrey;
            font-size: 12pt;
            font-weight: 200;
          }
        }
      }

      & .contentGeneralProduction {
        background-color: $lighterGrey;
        border-radius: 4px;
        margin: 0;
        padding: 0;
        padding-bottom: 2%;
        margin-top: 2%;

        & .labelContent {
          color: $darkBlue;
        }

        & .fieldAlignmentStatusAttendance {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 3.2vh;
          width: 43.6vw;

          & .statusAttendance {
            color: $darkGrey;
            font-size: 10pt;
            font-weight: 600;
          }

          & .counterStatusAttendance {
            color: $darkGrey;
            font-size: 12pt;
            font-weight: 200;
          }
        }
      }

      & .contentGeneralRevenue {
        background-color: $lighterGrey;
        border-radius: 4px;
        margin: 0;
        margin-top: 2%;
        margin-bottom: 2%;
        padding: 0;
        padding-bottom: 2%;


        & .labelContent {
          color: $darkBlue;
        }

        & .fieldAlignmentStatusAttendance {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 3.2vh;
          width: 43.6vw;

          & .statusAttendance {
            color: $darkGrey;
            font-size: 10pt;
            font-weight: 600;
          }

          & .counterStatusAttendance {
            color: $darkGrey;
            font-size: 12pt;
            font-weight: 200;
          }
        }
      }
    }
  }
}

.modal {
  & .containerFields {
    margin: 0;
    padding: 0;
  }

  & .yearField {
    width: 8vh;
  }

  & .contentFieldModal {
    width: 30rem;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  & .SelectedTags {
    p {
      color: $darkGrey;
      font-size: 9pt;
    }

    & .floatTags {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $darkGrey;
      background-color: $lighterGrey;
      padding: 2px 12px 2px 14px;
      font-weight: 500;
      width: 50%;
      margin-top: 2%;
      margin-bottom: 2%;
      border: 1px solid $darkGrey3;

      p {
        font-size: 7pt;
      }

      span {
        cursor: pointer;
      }
    }
  }

  & #attendanceModal {
    margin-top: 20px;
    & thead {
      & tr {
        border-bottom: 2px solid $lightBlue;
        & .dataFormat {
          text-align: center;
        }
        & th {
          color: $darkBlue !important;
        }
      }
    }
    & tbody tr {
      background-color: #fff;
      &:hover {
        background-color: $lighterGrey;
      }
      & .data {
        width: 10vw;
        text-align: center;
      }
      td {
        span {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 10px;
        }
        &.status-red {
          width: 12vw;

          span {
            background-color: $red;
          }
        }
        &.status-green {
          width: 12vw;
          span {
            background-color: $darkGreen;
          }
        }
        &.status-blue {
          width: 12vw;
          span {
            background-color: $darkBlue;
          }
        }
        &.status-yellow {
          width: 12vw;
          span {
            background-color: $yellow;
          }
        }
        &.status-orange {
          width: 12vw;
          span {
            background-color: $orange;
          }
        }
        &.status-gray {
          width: 12vw;
          span {
            background-color: $darkGrey;
          }
        }
      }
    }
  }

  & .paginationContent {
    display: flex;
    justify-content: right;
  }

  & .labelModalSearchAdvanced {
    color: $darkBlue;
    font-size: 12pt;
    font-weight: 100;
    margin-top: 5%;
    margin-bottom: 4px;
  }

  & .buttonGroup {
    width: 14vw;
    display: flex;
    margin: 0 auto;
    margin-top: 20%;
    justify-content: space-between;

    & .buttonOptions {
      margin: 0 auto;
    }
  }
}
