@import '../../components/colorPallete.scss';

#meus-dados {
    justify-content: center;
    font-family: "Raleway", sans-serif;

    & .div-basic {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        & .div-profile {
            position: relative;
            width: 180px;
            height: 180px;
            box-sizing: content-box;
            justify-content: center;
            align-items: center;
            
            & .usuario-photo {
                border-radius: 50%;
                padding: 0px;
                max-width: 180px;
                max-height: 180px;    
                min-width: 180px;
                min-height: 180px;          
                background-size: 180px;
                vertical-align: middle;
            }
            
            & .control-label {
                border-radius: 50%;
                width: 45px;
                height: 45px;
                border: 3px solid white;
                position: absolute;
                right: 0;
                bottom: 10px;
                padding: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                
                & img {
                    filter: brightness(50);
                    width: 17px;
                    height: 17px;;
                }
            }
        }
        
    }
    & .darkBlue-outline {
        margin-right: 24px;
    }
    & .title {
        font-size: 17px;
        color: $darkBlue;
        border-bottom: 1px solid $darkBlue;
    }
    & .info-title, .info {
        font-size: 15px;
        color: $darkGrey;
    }
    & .info-title {
        margin: 0;
        font-weight: 700;
    }
    & .info {
        margin: 8px 0 16px 0;
        overflow: hidden;
    }
    & span.MuiChip-label {
        padding-right: 12px!important;
    }
    & .auto-complete-field{
        max-width: 600px;
    }

    & .senha {
        -webkit-text-security: disc;
        font-size: 17px;
        color: $darkGrey;
    }
    
    & .name {
        font-size: 25px;
        color: $darkBlue;
    }
    & .rodape {
        text-align: center;
        margin-top: 32px;
    }
    & .div-name {
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        & button {
            position: absolute;
            right: 0;
        }
        @media screen and (min-width: 700px) {
            .input {
                min-width: 450px;
            }
            & #nome {
                text-align: center;
            }
        }
        & #nome {
            padding: 8px 24px;
            width: 100%;
            font-size: 25px;
            color: $darkBlue;
            background-color: white!important;
            border: none;
            padding-bottom: 8px;
            border-bottom: 1px solid $lightGrey;
            &:focus {
                outline: none;
            }
        }
    }
}