

.divisor {
  height: 1px;
  width: 100%;
  background-color: #B4B8C0;
  opacity: 0.56;
  margin-top: 56px;
  position: relative;
}

.termo-grid { 
  display: flex;
  align-items: center !important;
}

.div-border-bottom {
  height: 1px;
  width: 100%;
  background-color: #B4B8C0;
  opacity: 0.56;
  margin-bottom: 56px;
  margin-top: 48px;

}

.results-block-title {
  margin-bottom: 16px !important;
  padding-left: 15.9%;

  span {
    font-size: 18px;
    color: #077CDC;
    font-weight: bold;
  }
}

.results-item-container {
  display: flex !important;
  align-items: center;
  justify-content: center;
  
  .results-meta-container {
    color: #E9B03D;
    border-width: 3px;
    border-color: #E9B03D;
    border-radius: 4px;
    border-style: solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px !important;
    height: 60px;
    margin-right: 16px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
    }

    span:first-child {
      font-weight: 600;
      font-size: 14px;
    }

    span:nth-child(2) {
      font-weight: 700;
      font-size: 16px;
      font-style: italic;
    }
  }

  .results-container {
    padding: 0;
  }
}

.results-item-container-with-subitems {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-top: 72px;

  .results-meta-container {
    width: 96px !important;
  }

  .results-container {
    padding: 0;
  }
}

.general-result-payback-container {
  margin-top: 64px;
  padding: 0 8.5%;
  height: 80px;
  display: flex !important;
  justify-content: flex-end !important;

  .general-result-payback-content {
    display: flex;
    align-items: center;
    margin-right: 8px;

    .general-result-payback {
      background-color: #707070;
      color: white;
      font-size: 18px;
      font-weight: 600;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      padding: 8px;
      margin-right: 4px;
      width: 200px;

      p {
        width: 100px;
        margin: 0;
        text-align: center;
      }

    }

    .general-result-payback-months {
      background-color: #00B1E8;
      width: 350px;
      color: white;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      font-size: 16px;
      font-weight: bold;
      padding: 8px;
      padding-right: 16px;
    }
  }
}
