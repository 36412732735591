@import '../colorPallete.scss';


 .progress-bar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    & .total {
        background-color: $lighterGrey;
        width: 100%;
        height: 30px;
        border-bottom: 2px solid $lightGrey;
    }
    & .redPercent, .greenPercent, .yellowPercent, .bluePercent {
        height: 30px;
        padding: 4px;
        color: white;
        font-size: 12px;
    }
    & .greenPercent {
        background-color: $lightGreen;
        border-bottom: 2px solid $darkGreen;
    }
    & .redPercent {
        background-color: $orange;
        border-bottom: 2px solid $red;
    }
    & .yellowPercent {
        background-color: $yellow;
        border-bottom: 2px solid $orange;
    }
    & .bluePercent {
        background-color: $lightBlue;
        border-bottom: 2px solid $darkBlue;
    }
 }