@import "../../components/colorPallete.scss";

#notificacao {
  font-family: "Raleway", sans-serif;
  & .atendimento-notificacao {
    display: flex;
    background-color: #f2f1f6;
    margin: 0.5em;
  }
  & .descricao-notificacao {
    margin: 2.5em;
  }
  & .botoes-notificacao {
    display: flex;
    align-items: center;
  }
  & .circulo {
    height: 9px;
    width: 9px;
    background-color: $darkBlue;
    border-radius: 50%;
    margin: 5px;
  }
  & .circulo-red {
    height: 9px;
    width: 9px;
    background-color: $red;
    border-radius: 50%;
    margin: 5px;
  }
  
  & .circulo-notificacao {
    height: 15px;
    width: 15px;
    background-color: $yellow;
    border-radius: 50%;
    margin: 5px;
    position: absolute;
    margin-top: 15px;
    margin-left: -7px;
  }
  
  & .data {
    color: $lightGrey;
    font-size: 13px;
  }
  
  & .normal-text {
    color: $darkGrey;
    font-size: 13px;
  }
  
  & .titulo {
    color: $darkBlue;
    font-size: 15px;
    font-weight: 600;
  }
}

.modal-recusa-atendimento.modal-pai {
  & .modal-filho {
    text-align: center;
    & label {
      font-size: 17px;
      color: $darkGrey;

      &.label-bold {
        font-weight: 600;
      }
    }
    & .div-botoes {
      display: flex;
      & button {
        margin: auto;
      }
    }
  }
}
