@import '../colorPallete.scss';

.popover-icon {
    & .MuiButton-root{
        width: auto;
        min-width: 10px;
    }
    & .MuiButton-contained {
        background: transparent;
        box-shadow: none;
        padding: 0px;
    }
    & .MuiButton-label {
        width: auto;
    }
    & .MuiTouchRipple-root {
        width: auto;
    }
    & .MuiButton-contained:hover {
        background-color: transparent;
    }
    
    // MuiPaper-elevation8 MuiPaper-rounded MuiPopover-paper
}
.popover {
    pointer-events: none;
    & .MuiPopover-paper {
        display: flex;
        padding: 8px;
        max-width: 250px;
        font-family: "Raleway", sans-serif;
        color: $darkGrey;
        & img {
            min-width: 18px;
            min-height: 18px;
        }
        & p {
            margin: 8px;
        }
    }
    & .MuiPaper-rounded {
        border-radius: 0px;        
    }
}