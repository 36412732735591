@import '../../components/colorPallete.scss';

// CSS para o gráfico de barra horizontal (DR)
.horizontal-bar-graph {
	text-align: left;
	display: flex;
	align-items: center;
	border-bottom: 1.5px solid $lightGrey; 
	height: 56px;
	min-width: 400px;
	width: 100%;
	margin-bottom: 3px;
	padding-top: 8px;
	margin-left: 14px
}