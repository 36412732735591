@import '../../components/colorPallete.scss';

.font-number {
    font-size: 46px;
    font-weight: 700;
    font-family: 'Raleway', sans-serif; 
}
.font-text {
    font-size: 15px;
    font-weight: 500;
    font-family: 'Raleway', sans-serif; 
  }

.font-sector-tools {
    font-size: 21px;
    font-weight: 700;
    font-family: 'Raleway', sans-serif; 
}

.icon-sector {
    margin: auto;
}

.slick-arrow {
    font-size: 30px;
    width: 0px !important;
}

.slick-prev {
    left: -50px;
}

.slick-prev:before, .slick-next:before {
    font-size: 42px;
    line-height: 1;
    color: $darkGrey;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before {
    content: '<';
    color: $darkGrey !important;
}

.slick-next:before {
    content: '>';
    color: $darkGrey !important;
}

.slick-disabled {
    display: none !important;
}

.slick-prev, .slick-next {
    font-size: 42px !important;
}

.card-carousel-fixed {
    padding-right: 24px;

    & .card-titles-div{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: fit-content;
        margin-left: 20px !important;
        margin: auto;
        label{
            margin-bottom: 0px !important;
        }
    }
    & .white-label{
        label{
            color: #FFFFFF !important;
        }
        
    }
    & .bold{
        label{
            font-weight: 700 !important;
        }	
    }
    & .card-dark-gray {
        height: 130px;
        			
    }
    & .card-blue-small {
        padding-top: 10px;
        background-color: #00B1E8;
        width: 100%;
        height: 85px !important;				
    }
    & .card-green {
        height: 85px;				
    }
}