@import "../../components/colorPallete.scss";

#dashboard-isis {
    font-family: 'Raleway', sans-serif; 

    .header-buttons {
        margin-top: 30px;
        .atualizar-carga {
            & img {
                margin-left: 5px;
                width: 18px;
                height: 12px;
            }
        }
    }

    .ultima-atualizacao-div {
        font-family: 'Raleway', sans-serif; 
        display: grid;
        color: $darkGrey;
        padding-right: calc(100% - 220px)!important;
        font-size: 11px;
    }

    .app-bar {
        margin-top: 50px;
    }

    .box-target {
        font-family: 'Raleway', sans-serif; 
        margin-top: 30px;

        .title {
            font-weight: bold;
            color: $darkGrey;
            font-size: 13px;
        }

        .text {
            color: $darkGrey;
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
            font-size: 11px;
        }
    }

    .box-info {
        font-family: 'Raleway', sans-serif; 
        color: $darkBlue;
        display: flex;

        & span {
            margin-top: 6px;
            margin-right: 10px;
        }

        .icon-button img {
            max-width: 24px;
            max-height: 24px;
        }

        & .MuiTooltip-tooltip {
            background-color: transparent;
        }
    }

    .boxes {
        margin-top: 10px;
    }

}