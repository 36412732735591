@import '../../components/colorPallete.scss';

.receita {
    & .apropriar .informacoes button {
        margin: 10px 0px 0px 0px;
        width: 140px;
    }

    & .tabela .formField {
        padding-top: 15px;
    }
    & .tabela {
        td {
            button {
                margin-left: 20px;
            }
        }
    }
}

.modal-historico-receita {
    font-size: 15px;
    font-family: "Raleway", sans-serif;  
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $darkGrey;

    & h2 {
        margin: 0 0 20px 0;
        font-size: 25px;
        font-weight: 400;
        color: $darkBlue;
    }

    & p {
        margin: 4px 0;
    }

    & .lista-p {
        margin-top: 20px;
        font-size: 13px;
    }

    & .ano {
        margin-top: 40px;
    }
    & .font-blue {
        margin-right: 16px;
        color: $darkBlue;
    }
    & .formField {
        margin: 0px;
    }
    & .inputSelectDiv {
        width: 80px;
        margin: 0px;
        & label {
            margin: 0;
        }
    }
    
    & .darkGrey-outline, .darkBlue-outline {
        margin-right: 16px;
    }
}

.payment-checkbox {
    margin-top: 25px;

    .MuiFormControlLabel-label{
        color: $darkGrey !important;
        font-weight: 600;
    }
}


.total {
    text-align: right;
    color: $darkBlue;
    font-size: 15px;
    & b {
        font-weight: 700;
    }
}

.labelReceita {
    font-size: 14px;
    margin-bottom: 10px;
    color: $darkGrey;
    font-family: "Raleway", sans-serif;
}

.receita-list-item {
    margin-top: 15px;
}

.space-receita {
    margin-top: 30px;
}
.input-receita .inputSelectDiv{
    width: 100%;
}

.select-produto {
    width: 100%;
    display: block;
}

.pesquisa {
    .formField {
        flex-grow: 2;
        margin-right: 20px;
        margin-bottom: 0 !important;
    }
}

.total-atendimentos label{
    color: #077CDC !important;
}

.rodape {
    margin-top: 50px !important;
}

.pagination-gerenciar-historico {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 11pt;
    color: $darkGrey;
    margin-bottom: 50px;

    .return-gerenciar-historico {
        display: flex;
        align-items: center;
        width: 106%;
        cursor: pointer;
    }
    & p {
        color: $darkGrey;
        margin-right: 2%;
        padding-right: 10px;
    }
}

.receita-info {
    h4 {
        font-size: 22px;
        color: #707070;
    }

    .Box-label {
        display: flex;
        align-items: center;
        margin-bottom: -32px;
    }

    .MuiGrid-grid-xs-3, .MuiGrid-grid-xs-9 {
        font-size: 14px;
        color: #707070;
        margin-bottom: 10px;
    }
    .MuiGrid-grid-xs-12 {
        &:last-child {
            margin-top: 20px;
        }
        button {
            &:last-child {
                margin-left: 20px;
            }
        }
    }
}

.progressBar {
    padding: 20px;
    width: 100%;
    height: 100%;
    background-color: #F2F1F6;
    p{
        font-size: 14px;
        color: #707070;
        text-align: center;
    }
    .progress-bar {
        label {
            font-size: 13px;
            font-weight: bold;
            color: #707070;
            margin: 0 auto;
            padding-bottom: 20px;
        }
    }
    &:last-child {
        margin-left: 30px;
    }
}

.historic-table {
    margin-top: 50px !important;
    h4 {
        font-size: 16px;
        font-weight: bold;
        color: #707070;
        margin-bottom: 30px;
    }
    thead {
        tr {
            border-bottom: 2px solid #00B1E8;
            th{
                font-size: 14px;
                color: #077CDC;
            }
        }
    }
}

.totalHistorico {
    margin-top: -97.3vh !important;
}

.sidebar {
    height: 100%;
    width: 50%;
    margin-left: 7.4%;

    .boxServicos{
        height: 28%;
    }
    
    .porcentagem {
        height: 100% !important;
        list-style: none;
        margin-top: -29px;
        label {
            font-size: 14px;
            color: #FFFFFF;
            text-align: center;
            padding: 20px;
        }

        .values {
            height: 100%;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            border-bottom: 1px solid #FFF;

            &.dark {
                background-color: #077CDC;
            }
    
            &.light {
                background-color: #00B1E8;
            }
    
            &.others {
                background-color: #707070;
            }
        }

        .MuiGrid-grid-xs-12 {
            margin: 0;
        }
    }
    

    .total {
        margin-top: 10px;
        p {
            font-size: 16px;
            color: #666C77;
            border-top: 1px solid #F2F1F6;
            border-bottom: 1px solid #F2F1F6;
            width: 90%;
            margin-left: 10%;
            text-align: center;
            padding: 10px 0;
        }
    }
}

.sidebar-historico {
    position: relative;
    right: -15px;
    flex-direction: column;
    flex-wrap: nowrap !important;
    align-items: center !important;

    .boxServicos{
        width: 90%;
        display: flex;
        align-items: center;
    }

    .porcentagem {
        width: 100% !important;
    }


    .total {
        margin-top: 10px;
        p {
            font-size: 16px;
            color: #666C77;
            border-top: 1px solid #F2F1F6;
            border-bottom: 1px solid #F2F1F6;
            width: 100%;
            margin-left: 0 !important;
            text-align: center;
            padding: 10px 0;
        }
    }
    
    .MuiGrid-grid-xs-1 {
        max-width: 400px;
    }

    .MuiGrid-grid-xs-10 {
        max-width: 1000px;
    }
}


.modal-appReceita {
    .auto-complete-many {
        label {
            color: #077CDC;
        }
    }
    .data-appReceita {
        margin-top: 20px;
        .head {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #077CDC;
            padding-bottom: 6px;
            border-bottom: 1px solid #00B1E8;
        }
        .line {
            display: flex;
            align-items: center;
            padding: 10px 0;
            font-size: 12px;
            text-transform: uppercase;
            color: #707070;
            border-bottom: 1px solid #E4E5E7;
            .MuiGrid-grid-xs-6 {
                display: flex;
                .formField {
                    margin-bottom: 0;
                    width: 92%;
                }
            }
        }
        .rodape-line {
            display: flex;
            align-items: center;
            padding: 18px 0;
            font-size: 14px;
            font-weight: bold;
            color: #707070;
            border-top: 2px solid #ABB4BE;
            .MuiGrid-grid-xs-6 {
                display: flex;
                .formField {
                    margin-bottom: 0;
                    width: 92%;
                }
            }
        }

        .rodape-line-fonte {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            color: #707070;
            border-top: 1px solid #ABB4BE;
                .formField {
                    margin-bottom: 0;
                    width: 92%;
                }
            p {
                font-weight: '400';
                font-size: 11pt;
            }
        }
    }
    .information {
        margin: 16px 0 8px 0;
        label {
            margin-top: 8px;
            font-size: 14px;
        }
    }

    .block-input {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .input-title {
            color: $darkBlue;
            margin-bottom: 8px;
        }
    }
}

.tabela-entregas {
    font-size: 12px;
    color: #3E4556;

    & .atividades-entregas{
        padding-left: 6%;
        width: 80%;
    }
}


.containerButtonModal {
    display: flex;
    align-items: center;
    width: 110%;
}

//Responsividade do modal
@media screen and (min-width: 768px) {
	.containerButtonModal {
        display: flex;
        margin-top: 10%;
        align-items: center;
        width: 110%;
    }

    .containerProgressBar {
        width: 106%;
    }
}
