@import '../colorPallete.scss';

.collapse {
    .MuiGrid-container {
        justify-content: space-between;
    }
}

    .MuiExpansionPanelSummary-expandIcon.Mui-expanded {
        color: $darkBlue;
        padding: 0px !important;
    }

    .MuiExpansionPanelSummary-expandIcon {
        padding: 0px !important;
    }

.MuiExpansionPanelSummary-root {
    color: $darkGrey !important;

    &.boxChild {
        border-bottom: 1px solid $darkGrey !important;
    }

    &.Mui-expanded {
        height: 40px !important;
        max-height: 40px !important;
        border-bottom: 1px solid $darkBlue;
        color: $darkBlue !important;
    }
}

.title-expanded {
    font-size: 15px;
    font-weight: 500;
    color: $darkBlue;
}

.title-closed {
    font-size: 15px;
    font-weight: 500;
    color: $darkGrey;
}

.MuiExpansionPanelSummary-content {
    margin: 0px !important;
    width: 100% !important;
    align-items: center;

    &.Mui-expanded {
        color: $darkBlue;
    }
}


    .MuiExpansionPanel-rounded:first-child, .MuiExpansionPanel-rounded:last-child {
    border-radius: 0 !important;
    }

    .MuiExpansionPanel-root {
        &.Mui-expanded {
            margin: 0px !important;
        }

        &.Mui-expanded:before {
            opacity: 1 !important;
        }
    }

    .details {
        background-color: white
    }

    .details > div {

        width: 100%;
    }

.div-dados {
    padding: 10px 20px;
    border-bottom: 1px solid #F2F1F6;
}

.check-label {
    color: var(--unnamed-color-707070);
    text-align: left;
    font: normal normal 600 13px/15px Raleway;
    font-weight: 500;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}

.check-container{
    padding: 10px 0px;
}

.icon-ok {
    background: #759F43 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items:center;
}

.icon-no {
    background: #C73842 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cliente-info {
    color: var(--unnamed-color-707070);
    text-align: left;
    font: normal normal normal 17px/20px Raleway;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}

.box {
    background-color: $lighterGrey !important;
    box-shadow: none !important;
    margin: 0px !important;
}

.boxChild {
    height: 40px !important;
    max-height: 40px !important;
    min-height: 40px !important;
    font-size: 13px !important;
    border-bottom: 1px solid $darkGrey;

    &:hover {
        background-color: $lighterGrey2 !important;
    }
}

    #bh-header {
        height: unset !important;
        max-height: unset !important;
        line-height: 1.5 !important;

        & .MuiIconButton-root {
            color: white;
        }
    }

    .root {
        width: 100%;
        font-size: '15px';
    }

    .MuiTypography-body1 {
        font-size: 15px !important;
        line-height: 1.0 !important;
    }
