@import '../../components/colorPallete.scss';

.totals{
    text-align: right;
    font-size: 15px;
    & b {
        font-weight: 700;
    }
    
    display: flex;
    flex-direction: column;
    
    label {
        margin-bottom: 2px !important;
        color: $darkBlue !important;
    }
}