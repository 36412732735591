
.breadcrumb {
    padding-right: 20px;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    z-index: 90;
    color: #707070;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    font: normal normal 600 17px/20px Raleway;
    justify-content: space-between;

    & img {
        max-width: 30px;
        max-height: 30px;
        height: auto;
        text-align: center;
        margin-right: 16.5px;
    }
}

.primary {
    color: #707070;
}

.secondary {
    color: #077CDC;
}

.breadcrumb-grid {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
}


