$darkBlue : #077CDC;
$lightBlue : #00B1E8;
$darkGrey : #707070;
$lightGrey : #B4B8C0;
$lighterGrey : #F2F1F6;
$lighterGrey2 : #E1E3E6;
$lighterGrey3 : #ABB4BE;
$lighterGrey4 : #EBEAF1;

$darkBlue2: #00569D;
$darkBlue3 : #309bff;
$lightBlue2 : #008AB5;
$lightBlue3: #B5CFE5;
$darkGrey2 : #535353;
$darkGrey3 : #666C77; 
$lightGrey2 : #92959D;
$lightGrey3: #B3B3B6;
$hoverGray : #F5F5F5;
$darkGrey3: #B4B8C0;

$lightGreen : #B7EE8E;
$lighterGreen : #9DDB4C;
$darkGreen : #7DAE59;
$darkerGreen : #5B8041;
$orange : #E9784D;
$lightOrange : #FF935B;
$red : #D44E54;
$yellow : #E9B03D;
$lightYellow: #FFE95A;
$cream : #FCF8E3;
$greyBlue: #3E4556;

//background and border
$buttonPriotity1: 'darkBlue' $darkBlue $darkBlue2, 'lightBlue' $lightBlue $lightBlue2, 'darkGrey' $darkGrey $darkGrey2, 'lightGrey' $lightGrey $lightGrey2, 'transparent' transparent transparent;

//color
$buttonPriotity2: 'darkBlue' $darkBlue, 'lightBlue' $lightBlue, 'darkGrey' $darkGrey, 'lightGrey' $lightGrey;

//background and border
$tagList: 'red' $red $red, 'orange' $orange $orange, 'yellow' $yellow $yellow, 'lightGreen' $lightGreen $lightGreen,'darkGreen' $darkGreen $darkGreen, 'cream' $cream  $cream;