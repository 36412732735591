@import '../../components/colorPallete.scss';

.gerenciar-eventos {
	& tbody {
        text-transform: uppercase;
    }
}

.evento-modal.modal-pai {
	& .modal-filho {
		text-align: center;
		& label {
			font-size: 17px;
			color: $darkGrey;

			&.label-bold {
				font-weight: 600;
			}
		}
    }
}