@import "./../../components/colorPallete.scss";

#funil {
  font-family: "Raleway", sans-serif;

  & .formField {
    & span {
      display: none !important;
    }
  }
  & .tabs {
    margin: 32px 0px;
  }
  & .funil-header {
    display: flex;
    margin: 8px 0px 16px 0;
    align-items: center;
    & label {
      font-size: 17px;
      color: $darkGrey;
    }
    & button {
      margin: 0px 8px;
    }
  }
  & .formField {
    margin: 0px;
  }
  & hr {
    margin: 40px 0px;
    border-top: 1px solid $lighterGrey2;
  }
  & .popover-icon {
    & .MuiButton-root {
      margin-left: 8px;
    }
  }
  & .info {
    background-color: $lighterGrey;
    padding: 20px;
    display: flex;
    flex-direction: column;
    max-width: 250px;
    height: fit-content;

    & .info-label {
      font-size: 14px;
      color: $darkGrey;
    }
    & div {
      display: flex;
      align-items: center;
      margin: 5px;
    }
    & .blue-block {
      margin-right: 10px;
      max-width: 50px;
      min-width: 50px;
      min-height: 50px;
    }
  }
  & .funilPai {
    display: flex;
    margin-bottom: 20px;
    & .blue-block,
    & .green-block {
      width: 75px;
      height: 90px;
    }
    & .funilBase {
      display: flex;

      & .tittle {
        color: $darkGrey;
        line-height: normal;
        width: 250px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0px 10px;
        font-size: 14px;
        border-top: 1px solid $lighterGrey2;
        border-bottom: 1px solid $lighterGrey2;
      }
      & .numbers {
        display: flex;
        flex-direction: column;
        padding-right: 10px;
        align-items: flex-start;
        justify-content: center;
        width: 110px;
        border-top: 1px solid $lighterGrey2;
        border-bottom: 1px solid $lighterGrey2;
        & .font-blue,
        & .font-light-blue {
          font-size: 14px;
        }
      }
      & .n1 {
        width: 45px;
      }
      & .n2 {
        width: 125px;
      }
      & .label-small {
        font-size: 8px !important;
        height: 7px;
        line-height: initial;
        margin: 2px 0px;
        color: $darkGrey;
      }
    }
    & .funilBarras {
      display: flex;
      flex-direction: column;
      padding-top: 25px;
      width: 200px;

      & .barraSuperior {
        background-color: $lightBlue3;
        height: 15px;
      }
    }
  }
  & .grid-right-button {
    justify-content: flex-start;
    & .inputSelectDiv {
      width: 100%;
      margin-right: 24px;
    }
  }
}

.blue-block,
.green-block {
  color: white;
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blue-block {
  background-color: $darkBlue;
}
.green-block {
  background-color: $darkGreen;
}

.yellowPercent {
  background-color: $lightYellow;
}
.greenPercent {
  background-color: $lighterGreen;
}
.redPercent {
  background-color: $lightOrange;
}
.yellowPercent,
.greenPercent,
.redPercent {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $darkGrey;
  width: 50px;
  height: 90px;
  margin: 0;
  overflow: hidden;
}

.barraInferior {
  background-color: #067cdd;
  height: 25px;
}

#funil .painel {
  display: flex;
  justify-content: space-between;
}

#funil input[type="number"] {
  height: 30px;
  width: 115px;
  margin: 0px;
}
#sidebar-info #filtro {
  display: -webkit-inline-box;
}
#salvarDados {
  margin-right: 10px;
}
#details {
  margin-left: 10px;
  width: 200px;
}

.conteudoIndicador.no-scroll {
  overflow-x: unset;
  overflow-y: unset;
}

.block {
  pointer-events: none;
}
