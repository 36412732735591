@import "../colorPallete.scss";

.inputSelectDiv {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  font-family: "Raleway", sans-serif;
  font-size: 13px;

  & label {
    font-size: 15px;
    margin-bottom: 10px;
    color: $darkGrey;
  }
  & span.isInvalid {
    color: $red;
  }

  & .selectOptions {
    background: #eeeeee;
    background-position: 218px center;
    height: 30px;
    font-family: "Raleway", sans-serif;
    font-size: 13px;
    text-transform: uppercase; 
    display: flex;
    width: 100%;    

    & ::placeholder {
      /* Firefox, Chrome, Opera */
      color: $lightGrey;
      font-style: italic;
    }
    &.isInvalid {
      & .MuiSelect-root {
        border: 1px solid $red !important;
      }
    }
  }

  .inputSelect-onlyLabel-container {
    background-color: $lighterGrey;
    width: 323px;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 22px 20px;
    border-radius: 2px;
    span  {
      color: $darkBlue;
      font-size: 13px;
      font-weight: bold;
      font-family: 'Raleway';
    }
  }
}

.MuiListItem-button.MuiMenuItem-root.MuiMenuItem-gutters.isGroup {
  color: $darkBlue;
  opacity: 1;
  font-weight: 700;
}

.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.MuiMenuItem-root.MuiMenuItem-gutters {
  min-height: 30px;
} 

.MuiSelect-select.Mui-disabled {
  cursor: not-allowed !important;
}

.input-select-options {
  font-size: 13px;
  color: $darkGrey;
  font-family: "Raleway", sans-serif;
}

.MuiSelect-select.MuiSelect-selectMenu.Mui-disabled.MuiInputBase-input.MuiInput-input.Mui-disabled.Mui-disabled {
  cursor: not-allowed;
}

li.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.MuiMenuItem-root.MuiMenuItem-gutters {
    font-family: 'Raleway', sans-serif; 
}

.MuiInputBase-root.Mui-disabled>div.MuiSelect-root {
  background-color: $lighterGrey2 !important;   
}

.inputSelectDiv.isInvalid {

    & span.isInvalid {
      color: $red;
    }

    .MuiSelect-root {
      border: 1px solid $red !important;
    }
}

.MuiSelect-root, .MuiSelect-select .MuiSelect-selectMenu .MuiInputBase-input .MuiInput-input,
.MuiInputBase-root .MuiInput-root .MuiInput-underline .Mui-focused .Mui-focused .selectOptions  {
  height: 30px!important;
  line-height: 30px!important;
  border: 1px solid $lighterGrey3!important;
  background-color: $lighterGrey!important;
  padding: 5px!important;
  font-size: 13px!important;
  color: $darkGrey!important;

  & .MuiSelect-selectMenu {
    height: 16px !important;
  }
}

