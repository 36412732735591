@import './../../components/colorPallete.scss';

#empty-list {
	font-family: "Raleway", sans-serif;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50px;

	& #div-bigger {
		display: flex;
		flex-direction: column;
		align-items: center;
		& img {
			width: 200px;
		}
		& .div-smaller {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			max-width: 300px;
			& p {
				text-align: center;
			}
			& .title {
				color: $darkBlue;
				font-size: 17px;
				margin-bottom: 8px;
			}
			& .description {
				font-size: 13px;
				color: $darkGrey;
				margin-top: 8px;
			}		
			& b {
				font-weight: 700;
			}	
		}
	}
}

@media screen and (min-width: 1500px) {
    #empty-list {
		margin-top: 60px;	
		& #div-bigger {
			& img {
				width: 260px;
			}
			& .div-smaller {
				max-width: 450px;
				& .title {
					font-size: 25px;
				}
				& .description {
					font-size: 17px;
				}	
			}
		}
	}
}
