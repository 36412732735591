@import '../colorPallete.scss';
.autoCompleteField {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    & input {
        height: 30px;
        border: 1px solid $lighterGrey3;
        background-color: $lighterGrey;
        padding: 0px 5px 0px;
        font-size: 13px;
        color: $darkGrey;
        font-family: "Raleway", sans-serif;
    }

    & input:disabled {
        cursor: not-allowed;
        background-color: $lighterGrey2 !important;
    }

    &  input:hover {
        border: 1px solid $darkGrey;
    }
    &  label {
        font-size: 15px;
        margin-bottom: 10px;
        color: $darkGrey;
    }
    &  span {
        color: $red;
        font-size: 13px;
        margin-top: 5px;
    }
    .isInvalid {
        border: 1px solid $red;    
    }
    & ::placeholder { /* Firefox, Chrome, Opera */ 
        color: $lightGrey; 
    } 
}

.MuiInput-underline::before, .MuiInput-underline:hover::before{
    content: none!important;
}
.MuiInput-underline:before, .MuiInput-underline:after {
    display: none!important;
    border: none!important;
}
#downshift-options-menu {
    position: absolute;
    z-index: 99;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    margin-top: 1px;
    -webkit-box-shadow: 0px 34px 34px -6px rgba(0,0,0,0.23);
    -moz-box-shadow: 0px 34px 34px -6px rgba(0,0,0,0.23);
    box-shadow: 0px 34px 34px -6px rgba(0,0,0,0.23);
}
.div-autocomplete {
    position: relative;
}

#search-icon {
    width: 30px;
    height: 30px;
    padding: 0px 6px;
    display: fixed;
    position: absolute;
    border-left: 0px;
    right: 0;
}