@import '../../components/colorPallete.scss';

.titulo {
    font-weight: 700;
    font-size: 15px;
    color: #fff !important;
}

.item {
    color: #fff !important;
    font-weight: 400;
    font-size: 12px !important;

}

.turma-mentoria-digital {
    font-family: 'Raleway', sans-serif;
    
    & .turma-agrupamento {
        display: flex;
        position: relative;
        float: right;
        align-items: center;
        padding-top: 8px;

        & p {
            color: $darkGrey;
            font-size: 14px;
        }

        & div {
            padding-left: 5px;
            font-size: 13px;

            & label.titulo {
                font-weight: 700;
                font-size: 15px;
            }

            font-family: 'Raleway', sans-serif;
            transform: none !important;

            & .MuiTooltip-popper {
                width: 350px;
                font-size: 13px;
                top: 50px !important;
                left: -160px !important;
            }
        }
    }
    & .total {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        margin-left: 16px;
        label {

            color: $darkBlue;
        }
    }

    & .tabs {
        margin-top: 40px;

    }

    .top-container {
        padding-bottom: 40px;
        padding-top: 10px;
    }

    & .turma-element {

        & div {
            background-color: white;
            display: flex;
            height: 14vh;
            width: 90%;
            transition: 200ms;
            box-shadow: 1px 2px 1px 1px $lighterGrey2;
            cursor: pointer;


            & .trigger {
                margin-right: 1px;
                background-color: white;
                box-shadow: 0px 0px 0px 0px;
                padding: 8px;
                border-radius: 70%;
                margin-top: 1.5%;
                width: 4.5vh;
                height: 4vh;
                transition: 200ms;
            }

            & .trigger:hover {
                background-color: $lighterGrey2;
                border-radius: 70%;
                box-shadow: 0px 0px 0px 0px;
                padding: 8px;
                margin-top: 1.5%;
                width: 4.5vh;
                height: 4vh;
            }
        }

        & div:hover {
            background-color: white;
            display: flex;
            height: 14vh;
            width: 90%;
            transition: 200ms;
            box-shadow: 1px 2px 2px 1px $lighterGrey3;
            cursor: pointer;
        }
    }

    & .circulo-notificacao {
        height: 16px;
        width: 16px;
        border-radius: 60%;
        margin-top: -40px;
        margin-right: 12px;
        float: right;

        & span {
            font-size: 10px;
            font-weight: 600;
            color: white;
            display: flex;
            margin-left: 4px;
            margin-top: -2px;
        }
    }

    & .circulo-notificacao-showcase {
        margin-top: -5px;
    }

    & .circulo-notificacao.yellow {
        background-color: $yellow !important;
    }

    & .circulo-notificacao.green {
        background-color: $darkGreen !important;
    }

    & .circulo-notificacao.orange {
        background-color: $orange !important;
    }
}


.top-container {
    padding-bottom: 40px;
    padding-top: 10px;

    & .circulo-notificacao.orange {
        background-color: $orange !important;
    }

    .emptylist {
        margin-top: 5%;
    }
}

.modal-buscar-turma {
    width: 100% !important;
}

.last-Update {
    position: absolute;
    background-color: #53535394;
    color: white;
    padding-left: 8px;
    width: 140px;
    margin-top: -60px;

    & h6 {
        margin-top: 10px;
        font-size: 9pt;
        margin-bottom: 0;
    }

    & p {
        margin-top: 0;
        font-size: 9pt;
        margin-bottom: 10px;
    }
}

.turma-pen {
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.periodo {
    margin-top: 25px;
    margin-left: 10%;
    width: 75%;
}

.fase {
    margin-left: 3%;
    width: 100%;
}

.checkbox-collumn {
    height: 30%;
}

.sub-titulo-modal {
    margin: 40px 0 0px 0;
    color: $darkBlue;
    font-weight: lighter;
    font-size: 15px;
}

.without-margin {
    margin: 0 !important;
}

.grid-left-button-modal {
    width: 100% !important;
}

.checkbox-fase {

    & div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 10px 5px 10px 20px !important;

        & div {
            flex-direction: column;
            justify-content: flex-start;

            & span.MuiCheckbox-colorSecondary.Mui-checked {
                color: $darkBlue;
            }
        }
    }
}

.turma-atendimento {
    margin-top: 20px;
    flex-direction: row !important;
    align-self: stretch !important;
    width: 75%;
    margin-left: 10%;
}

.rodape-modal-turma {
    margin: 30px 20px 30px 25% !important;
    display: flex;

    & section {
        margin-left: 4%;
    }
}


.modal-pai.turma-modal {
    & .modal-filho {
        font-family: 'Raleway', sans-serif;
        padding: 24px 30px !important;

        & h2 {
            text-align: center;
            font-size: 22px;
            color: $darkBlue;
            margin: 0px;
            font-weight: unset;
            padding-bottom: 20px;
        }

        & #empty-list {
            margin-top: 0px;
            margin-bottom: 0px;
        }

        & .atendimento-busca {
            margin-top: 10px;
        }

        & .grid-tabela {
            margin-top: 20px;
            margin-bottom: 10px;

            & .atendimento-chip {
                width: 45px;
            }
        }

        & span.atendimento-error {
            color: $red;
        }

        & .grid-checkbox {
            margin-left: 20px;

            & span.MuiCheckbox-root, span.MuiCheckbox-colorSecondary.Mui-checked {
                color: $darkBlue;
            }

            & span.MuiCheckbox-colorSecondary.Mui-disabled {
                color: rgba(0, 0, 0, 0.26);
            }
        }

        & .trilha-header {
            display: flex;
            flex-direction: column;
            text-align: center;

            & h2 {
                padding-bottom: 0px;
            }

            & label {
                color: $darkBlue;
            }
        }

        & .content-game {
            & .tabela {
                margin-bottom: 25px;

                & span {
                    font-size: 13px;
                    font-weight: 600;
                    white-space: nowrap;
                }

                & span:hover {
                    background-color: transparent;
                }


                & .Mui-checked {
                    color: $darkBlue;
                }

                & .Mui-checked.Mui-disabled {
                    color: $lightGrey;
                }


                & .Mui-checked:hover {
                    background-color: transparent;
                }

                & label {
                    & span .MuiTypography-root {
                        font-size: 11px;
                        white-space: nowrap;
                    }
                }

                & td {
                    padding: 0px 35px;
                }
            }
        }

        & .collapse {
            & .MuiExpansionPanelDetails-root {
                padding: 0px 0px 0px;
            }
        }

        & .grid-tabela {
            margin-bottom: 30px;

            & tr.MuiTableRow-root.trilha-blue {
                & td.MuiTableCell-root.MuiTableCell-body {
                    color: $darkBlue !important;
                }

                cursor: context-menu;
                border-bottom: 2px solid $darkBlue;
            }

            & tr.MuiTableRow-root.trilha-green {
                & td.MuiTableCell-root.MuiTableCell-body {
                    color: $darkGreen !important;
                }

                cursor: context-menu;
            }

            & tr.MuiTableRow-root.trilha-grey {
                & td.MuiTableCell-root.MuiTableCell-body {
                    background-color: $lighterGrey !important;
                }

                cursor: context-menu;
            }
        }
    }
}

.statusBox {
    margin-top: 30px;
    margin-bottom: 30px;
}

.status-Espera {
    & .em-analise::before {
        color: $yellow;
    }
}

.status-Execucao {
    & .em-analise::before {
        color: $darkGreen;
    }
}

.status-Finalizada {
    & .em-analise::before {
        color: $lightGrey;
    }
}

.status-Cancelada {
    & .em-analise::before {
        color: $red;
    }
}
